import { STATUS } from "consts/media.consts";
import { DateTime } from "luxon";

/**
 * Currently the performance (or CTR) is calculated by dividing the number of impressions by the number of clicks.
 * @param {number} impressions
 * @param {number} clics
 */
const calculateAdPerformance = (impressions, clics) => {
    if (impressions === 0) return 0;

    return ((clics * 100) / impressions).toFixed(2);
};

/**
 * Determines the status of the ad based on the start and end dates and the
 * current ad status.
 * @param {{
 *  START_DATE?: string,
 *  END_DATE?: string,
 *  STATUS?: string
 * }} ad 
 */
const getAdStatus = (ad) => {
    try {
        const today = new Date();
        const startDate = DateTime.fromFormat(ad.START_DATE, 'd/M/y').toJSDate();
        const endDate = DateTime.fromFormat(ad.END_DATE, 'd/M/y').toJSDate();

        if (today < startDate) {
            return STATUS.SCHEDULED.name;
        }
    
        if (today > endDate) {
            return STATUS.FINISHED.name;
        }
    } catch (error) {
        console.error(error);
    }

    return ad.STATUS;
}


export { calculateAdPerformance, getAdStatus };