import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import { useRulePropertiesResolver } from "views/audiences";
import * as AudienceRuleTypes from "models/audience-rule.model";

/**
 * @param {{
 *  rule: AudienceRuleTypes.AudienceRuleModel,
 *  [x: string]: any
 * }} props 
 * @returns 
 */
export default function RuleOperatorSelect({ rule, ...props }) {
    const { compatibleOperators: ruleOperators } = useRulePropertiesResolver(rule);

    const options = ruleOperators.map(operator => ({
        label: operator.LABEL,
        value: operator.ID,
    }));

    return (
        <NeoDropdown 
            placeholder="Selecciona un criterio"
            options={options} 
            {...props}
        />
    );
}