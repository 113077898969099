import backendRequestUtil from "utils/backendRequest.util";
import * as AudienceTypes from "../models/audience-rule.model";
import { CONST } from "../consts/consts";
import { downloadUrl } from "utils/commons.util";

async function getAllAudiences() {
  const { success, body, error } = await backendRequestUtil.get(CONST.API.AUDIENCE.ALL);

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {number} audienceId 
 * @returns {Promise<{ success: boolean, payload: AudienceTypes.AudienceModel, message: string }>}
 */
async function getAudienceById(audienceId) {
  const { success, body, error } = await backendRequestUtil.get(CONST.API.AUDIENCE.GET_AUDIENCE, { params: { audienceId } });

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @returns {Promise<{ success: boolean, payload: AudienceTypes.AudienceRuleOperatorModel[], message: string }>}
 */
async function getAudienceRuleOperators() {
  const { success, body, error } = await backendRequestUtil.get(CONST.API.AUDIENCE.GET_CONTACT_RULE_OPERATORS);

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @returns {Promise<{ success: boolean, payload: AudienceTypes.AudienceRuleCriteriaModel[], message: string }>}
 */
async function getAudienceRuleCriteria() {
  const { success, body, error } = await backendRequestUtil.get(CONST.API.AUDIENCE.GET_CONTACT_RULE_CRITERIA);
  
  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {number|string} criteriaId
 * @param {AudienceTypes.AudienceRuleDataOptionsField|AudienceTypes.AudienceRuleDataOptionsField[]} fields
 */
async function getRulesDataOptions(criteriaId, fields = []) {
  if (! Array.isArray(fields)) {
    fields = [fields];
  }

  const { success, body, error } = await backendRequestUtil.get(
    CONST.API.AUDIENCE.GET_CONTACT_RULE_DATA_OPTIONS, 
    { params: { criteriaId, fields } }
  );
  
  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {AudienceTypes.CreateAudienceModel} audience
 */
async function createNewAudience(audience) {
  const { success, body, error } = await backendRequestUtil.post(
    CONST.API.AUDIENCE.CREATE_NEW_AUDIENCE,
    { audience }
  );

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {AudienceTypes.UpdateAudienceModel} audience
 */
async function updateAudience(audience) {
  const { success, body, error } = await backendRequestUtil.put(
    CONST.API.AUDIENCE.UPDATE_AUDIENCE,
    { audience }
  );

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {number} audienceId 
 * @returns 
 */
async function deleteAudience(audienceId) {
  const { success, body, error } = await backendRequestUtil.delete(
    CONST.API.AUDIENCE.DELETE_AUDIENCE + `/${audienceId}`,
  );

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {number} audienceId 
 * @param {boolean} enabled 
 */
async function updateAudienceStatus(audienceId, enabled) {
  const { success, body, error } = await backendRequestUtil.put(
    CONST.API.AUDIENCE.UPDATE_AUDIENCE_STATUS,
    { audienceId, enabled }
  );

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {AudienceTypes.AudienceRuleModel[]} rules 
 * @returns {Promise<{
 *  success: boolean,
 *  payload: { count: number },
 *  message: string
 * }>}
 */
async function testAudienceRules(rules) {
  const { success, body, error } = await backendRequestUtil.post(
    CONST.API.AUDIENCE.TEST_AUDIENCE,
    { audience: { rules } }
  );

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {number} audienceId
 * @returns {Promise<{
 *  success: boolean,
 *  payload: { count: number },
 *  message: string
 * }>}
 */
async function getAudienceContacts(audienceId) {
  const { success, body, error } = await backendRequestUtil.get(CONST.API.AUDIENCE.GET_AUDIENCE_CONTACTS, { params: { audienceId } });

  return {
    success,
    payload: body,
    message: success ? CONST.MESSAGES.SUCCESS : error,
  };
}

/**
 * @param {number} audienceId 
 * @param {(e: Error) => void} onError 
 */
async function downloadAudienceContacts(audienceId, onError = () => {}) {
  const { success, body, error } = await backendRequestUtil.get(CONST.API.AUDIENCE.DOWNLOAD_CONTACTS, { params: { audienceId } });

  if (! success) return onError(new Error(error));

  downloadUrl(body.url);
}

export { 
  getAudienceRuleOperators, 
  getAudienceRuleCriteria, 
  getRulesDataOptions,
  createNewAudience,
  getAudienceById,
  updateAudience,
  deleteAudience,
  updateAudienceStatus,
  getAllAudiences,
  testAudienceRules,
  getAudienceContacts,
  downloadAudienceContacts
};