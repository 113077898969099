import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import Icono from "../../design/assets/img/wfi/icons/icon-ad.svg";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import * as AudienceRuleTypes from "../../models/audience-rule.model";
import useToastContext from "hooks/useToastContext.hook";
import { CONST } from "../../consts/consts";
import { getAudienceById, updateAudience } from "service/Audience.service";
import ManageAudience from "./components/ManageAudience.component";
import { useEffect, useState, useRef } from "react";
import NeoSpinner from "design/design_components/neo/overlay/NeoSpinner.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import DisableAudienceButton from "./components/DisableAudienceButton.component";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import AudienceContactCounter from "./components/AudienceContactCounter.component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import NeoTooltip_icon from "../../design/design_components/neo/overlay/NeoTooltip_icon.base";

/**
 * @type {AudienceRuleTypes.UpdateAudienceModel}
 */
const defaultAudience = null;


export default function DetailsAudience() {
    const [audience, setAudience] = useState(defaultAudience);
    const { id: audienceId } = useParams();
    const toast = useToastContext();
    const history = useHistory();
    const contactCounterRef = useRef(null);
    //const [rules, setRules] = useState(audience.RULES);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            fetchAudience();
        }

        return () => {
            isMounted = false;
        }
    }, []);


    async function fetchAudience() {
        const response = await getAudienceById(Number(audienceId));
        
        if (!response.success) {
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );
        }
        //console.log(response.payload);
        setAudience(response.payload);
    }

    const handleEdit = (rowData) => {
        history.push(`/audiencias/editar-audiencia/${audienceId}`);
    };

    if (audience === null) {
        return <NeoSpinner />;
    }


    return (
        <>
          <div className="p-d-flex gap-7 mb-16">
                <div>
                    <NeoTitleSecond title="Detalles de audiencia" icon={Icono} goBack={true} extra="mb-0" />
                </div>

                <div className="p-d-flex p-ai-center">
                    <span className={"badge badge-regular fs-14 pl-8 pr-8 pt-4 pb-4 " + (audience.IS_ENABLED ? 'badge-success' : 'badge-gray')}>
                        {audience.IS_ENABLED ? 'Habilitado' : 'Deshabilitado'}
                    </span>
                </div>
                
                <div className="p-d-flex gap-10 ml-auto p-ai-center">
                    <DisableAudienceButton 
                        audienceId={Number(audience.ID)} 
                        isEnabled={audience.IS_ENABLED} 
                        onStatusUpdated={(isEnabled) => setAudience({ ...audience, IS_ENABLED: isEnabled })}
                    />
                     <NeoButtonMain
                        label="Editar audiencia"
                        icon="pi pi-pencil"
                        onClick={handleEdit}
                        />
                </div>
             
            </div>

            <div className="p-d-flex p-flex-column gap-16 w-full">
            <NeoCard custom="custom-padding-card m-0 p-d-flex p-flex-column">
        <div className="p-col p-d-flex p-flex-column gap-32 pb-0">
            <h2 className="fs-22 bold text-primary p-m-0 p-p-0">Información general</h2>
            <div className="p-d-flex gap-16 pb-14">
                {/* Primer Renglón */}
                <div className="p-d-flex" style={{ flex: '0 0 40%', flexDirection: 'column' }}>
                    <div>Nombre</div>
                    <div>{audience.NAME}</div>
                </div>
                <div className="p-d-flex" style={{ flex: '0 0 40%', flexDirection: 'column' }}>
                    <div>Descripción</div>
                    <div>{audience.DESCRIPTION}</div>
                </div>
                <div className="p-d-flex" style={{ flex: '0 0 20%', flexDirection: 'column' }}>
                    <div>Contactos incluidos</div>
                    <div>0</div>
                </div>
            </div>
    
        </div>
    </NeoCard>


                <NeoCard custom="custom-padding-card m-0">
                   
                </NeoCard>
            </div>
         
        </>
      );
}