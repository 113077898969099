/**
 * @typedef {{
 *  ID: number, 
 *  NAME: string,
 *  DESCRIPTION?: string,
 *  IS_ENABLED: boolean,
 *  RULES: AudienceRuleModel[],
 * }} AudienceModel
*/

/**
 * @typedef {{
 *  ID: number,
 *  NAME: string,
 *  DESCRIPTION?: string,
 *  IS_ENABLED: 1|0,
 *  CONTACTS: number,
 *  TOTAL_USE_CAMPAIGNS: number,
 *  TOTAL_USE_ADS: number,
 *  UPDATED_AT: string,
 * }} ListAudienceModel
 */

/**
 * @typedef {{
 *  NAME: string,
 *  DESCRIPTION?: string,
 *  RULES: AudienceRuleModel[],
 * }} CreateAudienceModel
 */

/**
 * @typedef {{
 *  ID: number,
 *  NAME: string,
 *  DESCRIPTION?: string,
 *  IS_ENABLED: boolean,
 *  RULES: AudienceRuleModel[],
 * }} UpdateAudienceModel
 */

/**
 * @typedef {{
 *  ID: string|number,
 *  TYPE: AudienceRuleType,
 *  CRITERIA_ID?: number,
 *  OPERATOR_ID?: number,
 *  VALUE?: any,
 *  SUB_RULES?: Omit<AudienceRuleModel, 'SUB_RULES'>[],
 * }} AudienceRuleModel
 * 
 * @typedef {'single'|'multiple'} AudienceRuleType
 */

/**
 * @typedef {{
 *  ID: string|number,
 *  NAME: string,
 *  TYPE: AudienceRuleCriteriaType,
 *  CATEGORY: string,
 *  DATA_OPTIONS_SOURCE?: AudienceRuleDataOptionsField,
 * }} AudienceRuleCriteriaModel
 * 
 * @typedef {"string"|"number"|"date"|"percentage"} AudienceRuleCriteriaType
 */

/**
 * @typedef {{
 *  ID: string|number,
 *  NAME: string,
 *  LABEL: string,
 * }} AudienceRuleOperatorModel
 */

/**
 * @typedef {'genders'|'states'|'contact_types'|'contact_frequency_statuses'|'stores'|'store_departments'|'contact_os'} AudienceRuleDataOptionsField
 */

export const Types = {};