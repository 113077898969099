import NeoDateRangeFilter from "design/design_components/neo/table/NeoDateRangeFilter.base";
import NeoInputTextFilter from "design/design_components/neo/table/NeoInputTextFilter.base";
import NeoMultiSelectFilter from "design/design_components/neo/table/NeoMultiSelectFilter.base";
import NeoRangeNumberFilter from "design/design_components/neo/table/NeoRangeNumberFilter.base";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "design/design_components/neo/table/NeoTableColumn.base";
import { Menu } from "primereact/menu";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { filterRangeNumber } from "utils/commons.util";
import { filterDate } from "utils/date.utils";
import * as AudienceTypes from "../../../models/audience-rule.model"
import { downloadAudienceContacts } from "service/Audience.service";
import useToastContext from "hooks/useToastContext.hook";
import { CONST } from "consts/consts";
import NeoSpinner from "design/design_components/neo/overlay/NeoSpinner.base";
import DoNotRefreshPageDialog from "views/home/components/DoNotRefreshPageDialog.component";

const AUDIENCE_STATUS_OPTIONS = [
    { label: "Habilitada", value: 1 },
    { label: "Deshabilitada", value: 0 },
]

/**
 * @param {{ 
 *  audiences: AudienceTypes.ListAudienceModel[],
 *  isLoading: boolean,
 *  onChangeAudienceEnabledStatus: (audience: AudienceTypes.ListAudienceModel, newIsEnabled: boolean) => void,
 *  onDeleteAudience: (audience: AudienceTypes.ListAudienceModel) => void,
 * }} param0
 */
export default function AudienceTable({ audiences, isLoading, onChangeAudienceEnabledStatus, onDeleteAudience }) {
    const toast = useToastContext();
    const audienceTableRef = useRef(null);
    const [downloadingReport, setDownloadingReport] = useState(false);

    function nameFilterElement(audienceTableRef) {
        return (
            <NeoInputTextFilter
                ref={audienceTableRef}
                field={"NAME"}
                placeholder={"Buscar por nombre"}
                filterMatch={"contains"}
                className="custom-place"
            />
        );
    }

    function updateAtFilterElement(audienceTableRef) {
        const handleClearButtonClick = (e) => {
            setTimeout(() => {
                if (document.activeElement) {
                    // @ts-ignore
                    document.activeElement.blur();
                }
            }, 0);
        };

        return (
            <NeoDateRangeFilter
                ref={audienceTableRef}
                field={"UPDATED_AT"}
                placeholder={"Buscar por fecha"}
                matchFilter="custom"
                readOnlyInput
                onClearButtonClick={handleClearButtonClick}
            />
        );
    }

    function contactsFilterElement(audienceTableRef) {
        return (
            <NeoRangeNumberFilter
                ref={audienceTableRef}
                field={"CONTACTS"}
                minPlaceholder={"Mín."}
                maxPlaceholder={"Máx."}
                minDigit={0}
                maxDigit={0}
                matchFilter="custom"
            />
        );
    }

    function audienceStatusFilterElement(audienceTableRef) {
        return (
            <NeoMultiSelectFilter
                ref={audienceTableRef}
                options={AUDIENCE_STATUS_OPTIONS}
                field={"IS_ENABLED"}
                placeholder="Todos"
                selectedItemsLabel="{0} tipos de estatus"
            />
        );
    };

    /**
     * @param {AudienceTypes.ListAudienceModel} rowData 
     */
    function audienceStatusBodyTemplate(rowData) {
        const status = rowData.IS_ENABLED ? "Habilitado" : "Deshabilitado";
        const color = rowData.IS_ENABLED ? "dot-success" : "dot-gray";

        return (
            <div className="p-d-flex gap-7 p-ai-center">
                <div className={"dot " + color}></div>
                {status}
            </div>
        )
    }

    /**
     * @param {AudienceTypes.ListAudienceModel} rowData 
     */
    function totalUseBodyTemplate(rowData) {
        const totalCampaigns = rowData.TOTAL_USE_CAMPAIGNS ?? 0;
        const totalAds = rowData.TOTAL_USE_ADS ?? 0;

        return (
            <span>
                {totalCampaigns} campañas | {totalAds} anuncios
            </span>
        );
    }

    /**
     * @param {{ field: string, order: 1|-1 }} event
    */
    function sortTotalUse(event) {
        if (!Array.isArray(audiences)) return [];

        return audiences.sort((a, b) => {
            const [aCampaigns, aAds] = [a.TOTAL_USE_CAMPAIGNS, a.TOTAL_USE_ADS];
            const [bCampaigns, bAds] = [b.TOTAL_USE_CAMPAIGNS, b.TOTAL_USE_ADS];

            if (aCampaigns < bCampaigns) return -event.order;
            if (aCampaigns > bCampaigns) return event.order;
            if (aAds < bAds) return -event.order;
            if (aAds > bAds) return event.order;

            return 0;
        });
    }

    /**
     * @param {AudienceTypes.ListAudienceModel} rowData 
     */
    function updatedAtBodyTemplate(rowData) {
        return new Date(rowData.UPDATED_AT).toLocaleDateString();
    }

    /**
     * @param {AudienceTypes.ListAudienceModel} rowData 
     */
    function contactsBodyTemplate(rowData) {
        const contactsLoaded = rowData.CONTACTS !== null && rowData.CONTACTS !== undefined;
        const loader = <i className="pi pi-spin pi-spinner text-black"></i>;
        return (
            <span>
                {contactsLoaded ? rowData.CONTACTS.toLocaleString() : loader}
            </span>
         );
    }

    const handleDownloadContacts = async (rowData) => {
        setDownloadingReport(true);

        await downloadAudienceContacts(rowData.ID, (error) => {
            toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.FAIL_TO_DOWNLOAD.HEADLINE,
                CONST.TOAST_MESSAGES.FAIL_TO_DOWNLOAD.DETAILS
            );
        });

        setDownloadingReport(false);
    }

    return (
        <>
            <NeoTable
                emptyMessage="No hay ninguna audiencia"
                ref={audienceTableRef}
                value={audiences}
                loading={isLoading}
                removableSort
                dataKey="ID"
                paginator
                rows={8}
                selectionMode={"single"}
            >
                <NeoTableColumn
                    field="NAME"
                    header="Nombre"
                    // @ts-ignore
                    placeholder="Nombre de la audiencia"
                    sortable
                    filter
                    filterElement={nameFilterElement(audienceTableRef)}
                />
                <NeoTableColumn
                    field="TOTAL_USE"
                    header="Uso"
                    sortable
                    body={totalUseBodyTemplate}
                    sortFunction={sortTotalUse}
                />
                <NeoTableColumn
                    field="CONTACTS"
                    header="Contactos incluidos"
                    sortable
                    filter
                    body={contactsBodyTemplate}
                    filterElement={contactsFilterElement(audienceTableRef)}
                    filterFunction={filterRangeNumber}
                    filterMatchMode="custom"
                    style={{ width: "25%" }}
                />
                <NeoTableColumn
                    field="UPDATED_AT"
                    header="Última modificación"
                    body={updatedAtBodyTemplate}
                    sortable
                    filter
                    filterElement={updateAtFilterElement(audienceTableRef)}
                    filterFunction={filterDate}
                    filterMatchMode="custom"
                    style={{ width: "21%" }}
                />
                <NeoTableColumn
                    field="IS_ENABLED"
                    header="Estatus"
                    body={audienceStatusBodyTemplate}
                    sortable
                    filter
                    filterElement={audienceStatusFilterElement(audienceTableRef)}
                    style={{ width: "12%" }}
                />
                <NeoTableColumn
                    field="actions"
                    header=""
                    body={(rowData, index) => (
                        <ActionsColumn 
                            index={index} 
                            rowData={rowData} 
                            onChangeEnabledStatus={onChangeAudienceEnabledStatus}
                            onDelete={onDeleteAudience}
                            onDownloadReport={handleDownloadContacts}
                        />
                    )}
                    style={{ width: "4%" }}
                />
            </NeoTable>

            <DoNotRefreshPageDialog open={downloadingReport} />
        </>
    );
}

const ActionsColumn = ({ rowData, index, onChangeEnabledStatus, onDelete, onDownloadReport }) => {
    const history = useHistory();
    const menu = useRef(null);

    /**
     * @param {AudienceTypes.ListAudienceModel} rowData 
     */
    const handleEdit = (rowData) => {
        history.push(`/audiencias/editar-audiencia/${rowData.ID}`);
    };

    const items = [
        {
            label: "Editar",
            command: () => handleEdit(rowData),
        },
        {
            label: "Descargar contactos",
            command: () => onDownloadReport(rowData),
        },
        {
            label: rowData.IS_ENABLED ? "Deshabilitar" : "Habilitar",
            command: () => { onChangeEnabledStatus(rowData) },
        },
        {
            label: "Eliminar",
            command: () => { onDelete(rowData) },
        },
    ];

    return (
        <div
            className="hover-text-green cursor-pointer"
            onClick={(event) => {
                event.stopPropagation();
                menu.current.toggle(event);
            }}
        >
            <i className="fas fa-ellipsis-v"></i>
            <Menu model={items} popup ref={menu} id={`menu_${index}`} />
        </div>
    );
};