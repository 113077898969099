import React, { useEffect, useState } from "react";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import { getUserStatus } from "../../../service/User.service";
import ModalNewSplashPageGroup from "./ModalNewSplashPageGroup.component";

export default function CreateSplashPageGroupButtonSection() {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    let mount = true;

    if (mount) {
      getStatus();
    }
    return () => {
      mount = false;
    };
  }, []);

  const getStatus = async () => {
    setStatus((await getUserStatus()).result);
  };

  return (
    <>
      {status?.enable_sp ? (
        <NeoButtonSection align="right">
          <ModalNewSplashPageGroup />
        </NeoButtonSection>
      ) : null}
    </>
  );
}
