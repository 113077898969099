/**
 * @param {{
*  error: string?
* }} param0 
*/
export default function ValidationError({ error }) {

   if (!error) return null;

   return (
       <span className="fs-12" style={{ color: '#EB0A0A' }}>
           {error}
       </span>
   )
}