import { useState } from "react";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";

export default function CampaignStoreList({
  stores,
  selectedStores,
  onSelectedStore,
  headerTitle = "Sucursales",
  onEmptyText = "No hay sucursales disponibles",
}) {
  const [searchTerm, setSearchTerm] = useState("");

  if (searchTerm) {
    stores = stores.filter(
      (store) =>
        store.storeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        store.storeAddress?.CITY?.toLowerCase().includes(
          searchTerm.toLowerCase()
        )
    );
  }

  return (
    <div className="banner-store-list-container">
      <div className="banner-store-list-header">
        <span className="fs-14 bold">{headerTitle}</span>
      </div>
      <div className="banner-store-input-search-container">
        <NeoInputText
          className="banner-store-input-search"
          placeholder="Buscar sucursal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <i className="pi pi-search banner-store-input-search-icon"></i>
      </div>
      <div className="banner-store-list-element">
        <span className="fs-14 bold">Sucursal</span>
        <span className="fs-14 bold">Ubicación</span>
      </div>
      <div className="banner-store-list-element-container">
        {stores.length === 0 && (
          <div className="p-col-12 p-d-flex p-jc-center p-ai-center">
            {onEmptyText}
          </div>
        )}
        {stores
          .sort((store1, store2) =>
            store1.storeName.localeCompare(store2.storeName)
          )
          .map((store) => (
            <div
              key={store.id}
              className={
                "banner-store-list-element cursor-pointer " +
                (selectedStores.includes(store.id) ? "active" : "")
              }
              onClick={() => onSelectedStore(store.id)}
            >
              <span>{store.storeName}</span>
              <span>{store.storeAddress?.CITY ?? "N/A"}</span>
            </div>
          ))}
      </div>
    </div>
  );
}
