export default function RuleDivider() {
    const style = {
        backgroundImage: 'linear-gradient(to right, #CCCCCC 40%, rgba(255, 255, 255, 0) 20%)',
        backgroundPosition: 'top',
        backgroundSize: '10px 5px',
        backgroundRepeat: 'repeat-x',
        height: '2px',
    }

    return (
        <div className="w-full" style={style}></div>
    )
}