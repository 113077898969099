import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";

/**
 * @param {{
 *  variant?: 'ghost'|'default',
 *  disabled?: boolean,
 *  [x: string]: any
 * }} param0 
 */
export default function DeleteRuleButton({ variant, disabled, ...props }) {
    const buttonColor = variant === 'ghost' ? 'p-button-text p-button-info' : '';
    const iconColor = variant === 'ghost' ? '#194893' : '#fff';
    const iconStyle = variant === 'ghost' ? { opacity: disabled ? '0.55' : '1' } : {};

    const Icon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" style={iconStyle}>
            <mask id="mask0_5236_4251" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_5236_4251)">
                <path d="M9.9 16.5L12.5 13.9L15.1 16.5L16.5 15.1L13.9 12.5L16.5 9.9L15.1 8.5L12.5 11.1L9.9 8.5L8.5 9.9L11.1 12.5L8.5 15.1L9.9 16.5ZM7.5 21C6.95 21 6.47917 20.8042 6.0875 20.4125C5.69583 20.0208 5.5 19.55 5.5 19V6H4.5V4H9.5V3H15.5V4H20.5V6H19.5V19C19.5 19.55 19.3042 20.0208 18.9125 20.4125C18.5208 20.8042 18.05 21 17.5 21H7.5ZM17.5 6H7.5V19H17.5V6Z" fill={iconColor} />
            </g>
        </svg>
    )

    return (
        <NeoButtonMain extra={"p-button-icon-only p-0 " + buttonColor} disabled={disabled} {...props} >
            <Icon />
        </NeoButtonMain>
    )
}