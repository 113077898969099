import { useRef } from "react";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base";
import { data_get, filterRangeNumber } from "../../../utils/commons.util";

const nameFilterElement = (ref) => {
  return (
    <NeoInputTextFilter
      ref={ref}
      field={"NAME"}
      placeholder={"Buscar por nombre"}
      filterMatch={"contains"}
    />
  );
};

const locationFilterElement = (ref, options) => {
  return (
    <NeoMultiSelectFilter
      ref={ref}
      options={options}
      field={"ADDRESS.CITY"}
      placeholder="Todas"
      selectedItemsLabel="{0} tiendas"
    />
  );
};

const impressionsFilterElement = (ref) => {
  return (
    <NeoRangeNumberFilter
      ref={ref}
      field={"TRACKER.RENDEREDS"}
      minPlaceholder={"Mín"}
      maxPlaceholder={"Máx"}
      minDigit={0}
      maxDigit={0}
      matchFilter="custom"
    />
  );
};

const clicksFilterElement = (ref) => {
  return (
    <NeoRangeNumberFilter
      ref={ref}
      field={"TRACKER.CLICKS"}
      minPlaceholder={"Mín"}
      maxPlaceholder={"Máx"}
      minDigit={0}
      maxDigit={0}
      matchFilter="custom"
    />
  );
};

/**
 * @typedef {{
 * ID: number,
 * NAME: string,
 * TRACKER: {
 *  RENDEREDS: number,
 *  CLICKS: number,
 * },
 * ADDRESS: {
 *  CITY: string,
 * },
 * ASSIGNED: boolean,
 * }} AdStore
 */
export default function AdStoresTable({
  adStores,
  adAssignedStores,
  loading,
  groupByAssigned,
}) {
  const storesTableRef = useRef(null);
  const storeLocationOptions = [];

  if (!adStores) {
    adStores = [];
  }

  adStores.forEach((store) => {
    if (groupByAssigned) {
      store.ASSIGNED = adAssignedStores?.includes(store.ID);
    }

    if (
      !storeLocationOptions.find(
        (option) => option.value === store.ADDRESS.CITY
      )
    ) {
      storeLocationOptions.push({
        label: store.ADDRESS.CITY,
        value: store.ADDRESS.CITY,
      });
    }
  });

  // sorts the stores by ASSIGN status
  if (groupByAssigned) {
    adStores = adStores.sort((a, b) => (a.ASSIGNED ? -1 : 1));
  }

  function headerTemplate(data) {
    return (
      <>
        <p className="store-row-group">
          {data.ASSIGNED ? "Asignadas" : "Sin asignación"}
        </p>
      </>
    );
  }

  /**
   * First sorts by the given field and then by
   * ASSIGNED field.
   * @param {{ field: string, order: -1|1 }} event
   * @param {AdStore[]} data
   * @returns
   */
  function sortStores(event) {
    const _adStores = [...adStores];
    const assignedStores = _adStores.filter((store) => store.ASSIGNED);
    const unassignedStores = _adStores.filter((store) => !store.ASSIGNED);
    const sortedAssignedStores = assignedStores.sort((a, b) =>
      data_get(a, event.field) > data_get(b, event.field)
        ? event.order
        : -event.order
    );
    const sortedUnassignedStores = unassignedStores.sort((a, b) =>
      data_get(a, event.field) > data_get(b, event.field)
        ? event.order
        : -event.order
    );

    return [...sortedAssignedStores, ...sortedUnassignedStores];
  }

  function getSortFunction() {
    if (groupByAssigned) {
      return sortStores;
    }

    return undefined;
  }

  function getGroupingProps() {
    if (!groupByAssigned) {
      return {};
    }

    return {
      rowGroupMode: "subheader",
      groupField: "ASSIGNED",
      rowGroupHeaderTemplate: headerTemplate,
      rowGroupFooterTemplate: () => null,
    };
  }

  return (
    <div className="ad-stores-table">
      <NeoTable
        emptyMessage="No hay ninguna sucursal para mostrar"
        ref={storesTableRef}
        value={adStores}
        loading={loading}
        removableSort
        sortMode="single"
        {...getGroupingProps()}
      >
        <NeoTableColumn
          field="NAME"
          header="Sucursal"
          sortable
          filter
          filterElement={nameFilterElement(storesTableRef)}
          filterMatchMode="contains"
          headerClassName="fs-18 text-left"
          className="pl-24 text-left"
          sortFunction={getSortFunction()}
        />
        <NeoTableColumn
          field="ADDRESS.CITY"
          header="Ubicación"
          sortable
          filter
          filterElement={locationFilterElement(
            storesTableRef,
            storeLocationOptions
          )}
          filterMatchMode="contains"
          headerClassName="fs-18 text-left"
          className="pl-24 text-left"
          sortFunction={getSortFunction()}
        />
        <NeoTableColumn
          field="TRACKER.RENDEREDS"
          header="Impresiones"
          sortable
          filter
          filterElement={impressionsFilterElement(storesTableRef)}
          filterFunction={filterRangeNumber}
          headerClassName="fs-18"
          sortFunction={getSortFunction()}
        />
        <NeoTableColumn
          field="TRACKER.CLICKS"
          header="Clics"
          sortable
          filter
          filterElement={clicksFilterElement(storesTableRef)}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
          headerClassName="fs-18"
          sortFunction={getSortFunction()}
        />
      </NeoTable>
    </div>
  );
}
