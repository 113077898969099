import * as MediaTypes from "../../models/media.model";
import { isValidUrl } from "../../utils/commons.util";

const validFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];

const ERRORS = {
  name: {
    required: "El nombre es requerido",
    invalid: "El nombre es inválido",
    length: "El nombre debe de tener entre 3 y 50 caracteres",
  },
  startDate: {
    required: "La fecha de inicio es requerida",
    invalid: "La fecha de inicio es inválida",
    startDateTooEarly: "La fecha de inicio no puede ser menor al inicio de la campaña",
    startDateTooLate: "La fecha de inicio no puede ser mayor al fin de la campaña",
  },
  endDate: {
    required: "La fecha de fin es requerida",
    invalid: "La fecha de fin es inválida",
    before_start_date:
      "La fecha de fin no puede ser menor a la fecha de inicio",
    endDateTooEarly: "La fecha de fin no puede ser menor al inicio de la campaña",
    endDateTooLate: "La fecha de fin no puede ser mayor al fin de la campaña",
  },
  clickUrl: {
    required: "La URL es requerida",
    string: "La URL debe de ser una cadena de texto",
    invalid: "La URL es inválida",
  },
  file: {
    required: "El archivo es requerido",
    invalid: "El archivo es inválido",
    wrong_file_type:
      "Tipo de archivo incorrecto (" + validFileTypes.join(", ") + ")",
    file_size: "El tamaño del archivo no puede ser mayor a {0}",
    file_resolution: "La resolución del archivo debe ser de {0}",
    file_duration: "La duración del archivo no puede ser mayor a {0}",
  },
  fileAndroid: {
    required: "El archivo es requerido",
    invalid: "El archivo es inválido",
    wrong_file_type:
      "Tipo de archivo incorrecto (" + validFileTypes.join(", ") + ")",
    file_size: "El tamaño del archivo no puede ser mayor a {0}",
    file_resolution: "La resolución del archivo debe ser de {0}",
    file_duration: "La duración del archivo no puede ser mayor a {0}",
  },
  fileIos: {
    required: "El archivo es requerido",
    invalid: "El archivo es inválido",
    wrong_file_type:
      "Tipo de archivo incorrecto (" + validFileTypes.join(", ") + ")",
    file_size: "El tamaño del archivo no puede ser mayor a {0}",
    file_resolution: "La resolución del archivo debe ser de {0}",
    file_duration: "La duración del archivo no puede ser mayor a {0}",
  },
  fileDesktop: {
    required: "El archivo es requerido",
    invalid: "El archivo es inválido",
    wrong_file_type:
      "Tipo de archivo incorrecto (" + validFileTypes.join(", ") + ")",
    file_size: "El tamaño del archivo no puede ser mayor a {0}",
    file_resolution: "La resolución del archivo debe ser de {0}",
    file_duration: "La duración del archivo no puede ser mayor a {0}",
  },
};

/**
 * @param {MediaTypes.MediaModel} media
 */
async function validateCreateMedia(media, campaign) {

  const errors = {};

  errors.name = validateMediaName(media.name);

  if (!campaign.isDefault) {
    errors.startDate = validateStartDate(media.startDate, campaign);
    errors.endDate = validateEndDate(media.endDate, media.startDate, campaign);
  }

  errors.clickUrl = validateClickUrl(media.clickUrl);

  if (media.fileIosUrl) {
    errors.fileIosUrl = validateClickUrl(media.fileIosUrl);
  } else {
    errors.fileIos = await validateMobileFile(media.fileIos);
  }

  if (media.fileAndroidUrl) {
    errors.fileAndroidUrl = validateClickUrl(media.fileAndroidUrl);
  } else {
    errors.fileAndroid = (await validateMobileFile(media.fileAndroid)).filter(
      (error) => error !== "required"
    );
  }

  if (media.fileDesktopUrl) {
    errors.fileDesktopUrl = validateClickUrl(media.fileDesktopUrl);
  } else {
    errors.fileDesktop = await validateDesktopFile(media.fileDesktop);
  }
  const parsedErrors = parseErrors(errors);

  return parsedErrors;
}

function validateMediaName(name) {
  const errors = [];

  if (!name) {
    errors.push("required");

    return errors;
  }

  if (typeof name !== "string") {
    errors.push("invalid");
  }

  if (name.length < 3 || name.length > 50) {
    errors.push("length");
  }

  return errors;
}

function validateStartDate(startDate, campaign) {
  const errors = [];

  if (!startDate) {
    errors.push("required");

    return errors;
  }

  if (startDate && !(startDate instanceof Date)) {
    errors.push("invalid");
  }

  return errors;
}

function validateEndDate(endDate, startDate, campaign) {
  const errors = [];

  if (!endDate) {
    errors.push("required");

    return errors;
  }

  if (endDate && !(endDate instanceof Date)) {
    errors.push("invalid");
  }

  if (endDate && startDate && endDate < startDate) {
    errors.push("before_start_date");
  }

  // Comparar endDate con las fechas de la campaña
  if (campaign) {
    if (endDate < new Date(campaign.startDate)) {
      errors.push("endDateTooEarly"); // Error si está antes del inicio de la campaña
    }
    if (endDate > new Date(campaign.endDate)) {
      errors.push("endDateTooLate"); // Error si está después del final de la campaña
    }
  }

  return errors;
}

function validateClickUrl(clickUrl) {
  const errors = [];

  if (!clickUrl) {
    errors.push("required");

    return errors;
  }

  if (clickUrl && typeof clickUrl !== "string") {
    errors.push("string");
  }

  if (!isValidUrl(clickUrl)) {
    errors.push("invalid");
  }

  return errors;
}

async function validateDesktopFile(file) {
  const errors = [];

  if (!file) {
    errors.push("required");

    return errors;
  }

  if (file && !(file instanceof File)) {
    errors.push("invalid");
  }

  // validates file type
  if (!validFileTypes.includes(file?.type)) {
    errors.push("wrong_file_type");
  }

  // validates 500kb max on file size and 1mb on gif
  if (file.size > 500000 && file.type !== "image/gif") {
    errors.push("file_size:500kb");
  }

  if (file.size > 1000000 && file.type === "image/gif") {
    errors.push("file_size:1mb");
  }

  const isValidResolution = await validateImageResolution(file, 1080, 600);
  if (!isValidResolution) {
    errors.push("file_resolution:1080x600");
  }

  if (file.type === "image/gif") {
    const isValidDuration = await validateGifDuration(file, 4);
    if (!isValidDuration) {
      errors.push("file_duration:4s");
    }
  }

  return errors;
}

async function validateMobileFile(file) {
  const errors = [];

  if (!file) {
    errors.push("required");

    return errors;
  }

  if (file && !(file instanceof File)) {
    errors.push("invalid");
  }

  // validates file type
  if (!validFileTypes.includes(file.type)) {
    errors.push("wrong_file_type");
  }

  // validates 500kb max on file size and 1mb on gif
  if (file.size > 500000 && file.type !== "image/gif") {
    errors.push("file_size:500kb");
  }

  if (file.size > 1000000 && file.type === "image/gif") {
    errors.push("file_size:1mb");
  }

  const isValidResolution = await validateImageResolution(file, 480, 600);
  if (!isValidResolution) {
    errors.push("file_resolution:480x600");
  }

  if (file.type === "image/gif") {
    const isValidDuration = await validateGifDuration(file, 4);
    if (!isValidDuration) {
      errors.push("file_duration:4s");
    }
  }

  return errors;
}

function validateImageResolution(file, width, height) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width !== width || img.height !== height) {
          resolve(false);
        }
        resolve(true);
      };
    } catch (error) {
      reject(error);
    }
  });
}

function getGifFileDuration(file) {
  return new Promise((resolve, reject) => {
    try {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (event) => {
        let arr = new Uint8Array(fileReader.result);
        let duration = 0;
        for (var i = 0; i < arr.length; i++) {
          if (
            arr[i] == 0x21 &&
            arr[i + 1] == 0xf9 &&
            arr[i + 2] == 0x04 &&
            arr[i + 7] == 0x00
          ) {
            const delay = (arr[i + 5] << 8) | (arr[i + 4] & 0xff);
            duration += delay < 2 ? 10 : delay;
          }
        }
        resolve(duration / 100);
      };
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * Validates the a gif file.
 * Returns true if the GIF duration is less than or equal {duration}.
 * @param {File} file
 * @param {number} duration
 * @returns
 */
async function validateGifDuration(file, duration) {
  try {
    const gifDuration = await getGifFileDuration(file);
    return gifDuration <= duration;
  } catch (error) {
    return false;
  }
}

function parseErrors(errors) {
  for (let key in errors) {
    const errorKeys = errors[key];
    let errorMessage = "";

    if (errorKeys.length > 0) {
      for (let i = 0; i < errorKeys.length; i++) {
        let errorKey = errorKeys[i];
        errorKey = errorKey.split(":");

        if (errorKey.length > 1) {
          errorKey = ERRORS[key][errorKey[0]]?.replace("{0}", errorKey[1]);
        } else {
          errorKey = ERRORS[key][errorKeys[i]];
        }

        if (i > 0) {
          errorMessage += ". ";
        }

        errorMessage += errorKey;
      }

      errors[key] = errorMessage;
    } else {
      delete errors[key];
    }
  }

  return errors;
}

/**
 * @param {string[]} errors
 */
const parseFileError = (errors) => {
  const errorValues = {
    required: {
      summary: "Este campo es requerido",
      details: "",
    },
    wrong_file_type: {
      summary: "Formato no permitido",
      details: "Debe ser JPG, JPEG, PNG o GIF",
    },
    "file_size:500kb": {
      summary: "Peso del archivo excedido",
      details: "Inténtalo con: 500kb",
    },
    "file_size:1mb": {
      summary: "Peso del archivo excedido",
      details: "Inténtalo con: 1mb",
    },
    "file_resolution:1080x600": {
      summary: "Resolución no permitida",
      details: "Inténtalo con: 1080px x 600px",
    },
    "file_resolution:480x600": {
      summary: "Resolución no permitida",
      details: "Inténtalo con: 480px x 600px",
    },
    "file_duration:4s": {
      summary: "Duración del GIF excedida",
      details: "Inténtalo con: 4 segundos o menos",
    },
  };

  if (errors.includes("required")) {
    return errorValues.required;
  }

  if (errors.length > 1) {
    // To avoid UI cluttering, we only show the first error
    errors = errors.slice(0, 1);
  }

  return errorValues[errors[0]];
};

export {
  validFileTypes,
  ERRORS,
  parseErrors,
  parseFileError,
  validateCreateMedia,
  validateMediaName,
  validateStartDate,
  validateEndDate,
  validateClickUrl,
  validateDesktopFile,
  validateMobileFile,
  validateImageResolution,
  getGifFileDuration,
  validateGifDuration,
};
