import React, { useEffect, useRef, useState, useMemo } from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import Icono from "../../design/assets/img/wfi/icons/icon-visits.svg";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import moment, { duration } from "moment-timezone";
import NeoRating from "../../design/design_components/neo/form/NeoRating.base";
import { getVisit } from "../../service/Visits.service";
import SentimentIcon from "./components/SentimentIcon.component";
import ContactInfoCamp from "./components/ContactInfoCamp.component";
import { Dialog } from "primereact/dialog";
import FloorNameComponent from "./components/VisitsDetails/FloorName.component";
import NeoTooltip from "../../design/design_components/neo/overlay/NeoTooltip.base";
import ContainedImages from "./components/ContainedImages.component";
import Player from "./components/VisitsDetails/Player.component";
import PreviewOverlay from "./components/VisitsDetails/PreviewOverlay.component";
import useUpdateEffect from "../../hooks/useUpdateEffect.js";
import useIsMounted from "../../hooks/useIsMounted.js";
import avatar from "../../design/assets/img/wfi/avatar.svg"


/**
 * @typedef {Object} Path
 * @property {string} ap_mac
 * @property {number} coord_x
 * @property {number} coord_y
 * @property {Date} created_at
 * @property {number} deparment_id
 * @property {string} deparment_name
 * @property {string} department
 * @property {string} device_mac
 * @property {string} name
 * @property {number} store_floor_id
 * @property {string} store_floor_name
 * @property {string} store_floor_url_plan
 * @property {string} visit_id
 */

/**
 * @typedef {Object} UniquePathSegments
 * @property {string} dPath - the svg d attribute
 * @property {number} length - the distance between this and the next AP
 * @property {number} fillPct - the percentage of the path that has been visited
 * @property {[number, number]} start - 2d vector of the start point
 * @property {[number, number]} end - 2d vector of the end point
 * @property {Path} path - the current path data
 */

/**
 * @typedef {Object} AP
 * @property {number} COORD_X
 * @property {number} COORD_Y
 * @property {number} DEPARMENT_ID
 * @property {string} DEPARMENT_NAME
 * @property {number} ID
 * @property {string} MAC_ADDRESS
 * @property {string} NAME
 * @property {number} STORE_FLOOR_ID
 * @property {string} STORE_FLOOR_NAME
 * @property {string} STORE_FLOOR_URL_PLAN
 */

function calculateCurrPercentageOfPath(uniquePaths, slider) {
  return uniquePaths.map((curr, i, allPaths) => {
    const sliderPct = (slider.current / slider.length) * 100;
    const nextPlaceInTimeline = (100 / (allPaths.length - 1)) * (i + 1);
    let fillPct = 0;
   
    if (sliderPct > nextPlaceInTimeline) {
      fillPct = 100;
    } else {
      const currPlaceInTimeline = (100 / (allPaths.length - 1)) * i;
      const currLengthHere = nextPlaceInTimeline - currPlaceInTimeline;
      const currPctHere = sliderPct - currPlaceInTimeline;

      fillPct = (currPctHere / currLengthHere) * 100;
      if (sliderPct < currPlaceInTimeline) {
        fillPct = 0;
      }
    }

    curr.fillPct = fillPct;
    return curr;
  })
}

/**
 * @param {Array<UniquePathSegments>} uniquePaths
 */
function calculateComputedValues(uniquePaths, currentPath, showPreview) {
  let biggestRangeOfPreviewPaths = [0, 0];
  
  let res =  {
    firstApInCurrFloorIndex: null,
    lastApInCurrFloorIndex: null,
    biggestRangeOfPreviewPath: biggestRangeOfPreviewPaths,
    apsVisibleInCurrentFloor: null,
    previewFloor: null
  };

  if (uniquePaths.length === 0) return res;

  
  function getActiveFloorApRangeIndices() {
    const currentPathIndex = uniquePaths.findIndex(
      (el) => el.path.visit_id === currentPath.visit_id
    );
    const range = [currentPathIndex,currentPathIndex];

    for (let i = currentPathIndex; i <= uniquePaths.length; i++) {
      const next = uniquePaths[i + 1];
      if (!next || next.path.store_floor_id !== currentPath.store_floor_id) {
        range[1] = i;
        break;
      }
    }
    
    for (let i = currentPathIndex; i >= 0; i--) {
      const prev = uniquePaths[i - 1];
      if (!prev || prev.path.store_floor_id !== currentPath.store_floor_id) {
        range[0] = i;
        break;
      }
    }

    return range; 
  }

  const [firstApInCurrFloorIndex, lastApInCurrFloorIndex] = getActiveFloorApRangeIndices();

  function getBiggestRangeOfPreviewPaths() {
    let biggestRange = [0, 0];
    let currentRange = [0, 0];
    let currentFloor = uniquePaths[0].path.store_floor_id;

    uniquePaths.forEach((path, index) => {
      if (path.path.store_floor_id === currentFloor) {
        currentRange[1] = index;
      } else {
        const [currStart, currEnd] = currentRange;
        const [biggestStart, biggestEnd] = biggestRange;
        const thereIsANextPath = index + 1 <= uniquePaths.length;

        if (thereIsANextPath) {
          if (currEnd + 1 - currStart > biggestEnd - biggestStart) {
            biggestRange = [currStart, currEnd + 1];
          }
        } else if (currEnd - currStart > biggestEnd - biggestStart) {
          biggestRange = currentRange;
        }

        currentRange = [index, index];
        currentFloor = path.path.store_floor_id;
      }
    });
    return biggestRange;
  }
  

  /** @type {string[]} */
  let allCurrFloorAPMacs = [];
  /** @type {Path|null} */
  let previewFloor = null;

  if (showPreview) {
    biggestRangeOfPreviewPaths = getBiggestRangeOfPreviewPaths();
    const [startI, endI] = biggestRangeOfPreviewPaths;
    previewFloor = uniquePaths[startI].path;

    allCurrFloorAPMacs = uniquePaths
      .slice(startI, endI)
      .map((el) => el.path.ap_mac);
  } else {
    allCurrFloorAPMacs = uniquePaths
      .slice(firstApInCurrFloorIndex, lastApInCurrFloorIndex + 1)
      .map((el) => el.path.ap_mac);
  }

  let apsVisibleInCurrentFloor = uniquePaths
    .map((el) => el.path.ap_mac)
    .filter((mac) => allCurrFloorAPMacs.includes(mac));

  return {
    firstApInCurrFloorIndex: firstApInCurrFloorIndex,
    lastApInCurrFloorIndex: lastApInCurrFloorIndex,
    biggestRangeOfPreviewPaths: biggestRangeOfPreviewPaths,
    apsVisibleInCurrentFloor: apsVisibleInCurrentFloor,
    previewFloor: previewFloor
  };
}

export default function VisitDetails({ location }) {
  const { state } = location;

  const isMounted = useIsMounted();
  const [modal, setModal] = useState(false);
  const [department, setDepartment] = useState();

  const [visit, setVisit] = useState({
    STORE_FLOOR_ID: null,
    EMAIL: null,
    FULL_NAME: null,
    PHONE: null,
    GENDER: null,
    BIRTHDATE: null,
    OS: null,
    REVIEW_STARS: null,
    COMMENTS: null,
    COMMENT_SCORE: null,
    URL_PLAN: null,
    STORE_FLOOR_NAME: null,
  });
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [aps, setAps] = useState(/** @type {AP[]} */([]));
  const [slider, setSlider] = useState({ length: 0, current: 0 });
  const [paths, setPaths] = useState([]);
  const [img, setImg] = useState({
    clientWidth: 0,
    clientHeight: 0,
    naturalWidth: 0,
    naturalHeight: 0,
    spacingHorizontal: 0,
    spacingVertical: 0,
  });

  const [uniquePathSegments, setUniquePathSegments] = useState(/** @type {Array<UniquePathSegments>} */([]));
  const [currentPath, setCurrentPath] = useState(/** @type {Path} */({}));
  const [showPreview, setShowPreview] = useState(true);
  const [isPlayerPlaying, setIsPlayerPlaying] = useState(false);
  const [floors, setFloors] = useState([]);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  
  const {
    firstApInCurrFloorIndex,
    lastApInCurrFloorIndex,
    biggestRangeOfPreviewPaths,
    apsVisibleInCurrentFloor,
    previewFloor, 
  } = calculateComputedValues(uniquePathSegments, currentPath, showPreview);

  useEffect(() => {
    const sliderPct = (slider.current / slider.length) * 100;
    const step = 100 / (uniquePathSegments.length - 1);


    const currPath = uniquePathSegments.find((_, i) => {
      const nextPointInTimeline = step * (i + 1);
      const currPointInTimeline = step * i;

      return sliderPct >= currPointInTimeline && sliderPct < nextPointInTimeline;
    });

    setCurrentPath((prev) => currPath?.path ?? prev);

  }, [slider, uniquePathSegments]);

  useUpdateEffect(() => {
    if (showPreview) {
      setShowPreview(false);
    }
  },[slider.current]);

  useEffect(() => {
    // Change the fill percentage of the lines when the slider changes
    setUniquePathSegments((prev) => calculateCurrPercentageOfPath(prev, slider));
  }, [slider]);

  useEffect(() => {
    (async () => {
      const resp = await getVisit(state.ID);
      if (!isMounted()) return;
      setIsLoadingData(false);
      
      const visit = resp.payload.visit;
      setVisit(visit);

      const aps = resp.payload.aps.toSorted((a, b) => b.COORD_X - a.COORD_X);
      setAps(aps);


      let paths = resp.payload.paths;
      if(Array.isArray(paths) && paths.length > 0) {
        paths = paths.reduce((acc, path) => {
          const ap = aps.find(ap => ap.MAC_ADDRESS.toUpperCase() === path.ap_mac.toUpperCase());
          if (!ap || isNaN(ap.COORD_Y) || isNaN(ap.COORD_X)) {
            return acc;
          }
          path.name = ap.NAME;
          path.deparment_id = ap.DEPARMENT_ID;
          path.deparment_name = ap.DEPARMENT_NAME;
          path.store_floor_id = ap.STORE_FLOOR_ID;
          path.store_floor_name = ap.STORE_FLOOR_NAME;
          path.store_floor_url_plan = ap.STORE_FLOOR_URL_PLAN;
          path.coord_x = ap.COORD_X;
          path.coord_y = ap.COORD_Y;
          path.created_at = new Date(path.created_at);
          acc.push(path);
          return acc;
        }, []);
        setPaths(paths);
        setCurrentPath(paths[0])

        
        let filteredAps = paths.filter((currentPath, index, paths) => {
          if (index === 0) {
            return true;
          }
          const previousPath = paths[index - 1];
          return currentPath.ap_mac !== previousPath.ap_mac;
        });

        const uniqueFloors = filteredAps.reduce((acc, curr) => {
          const storeFloorId = curr.store_floor_id;
          const storeFloorUrl = curr.store_floor_url_plan;
          if (!acc[storeFloorId]) {
            acc[storeFloorId] = storeFloorUrl;
          }
          return acc;
        }, {});

        setFloors(Object.entries(uniqueFloors).map(([id, url]) => ({ id: +id, url })).sort((a, b) => a.id - b.id));
        const [ firstPath ] = paths;
        const [ lastPath ] = paths.slice(-1);
        const secondsBeetwenStartAndEnd = getTimeDifference(firstPath.created_at, lastPath.created_at);
        setSlider((slider) => ({
          ...slider,
          length: secondsBeetwenStartAndEnd,
        }));
      }
    })();
  }, [state.ID]);

  useEffect(() => {
    let filteredAps = paths.filter((currentPath, index, paths) => {
      if (index === 0) {
        return true;
      }
      const previousPath = paths[index - 1];
      return currentPath.ap_mac !== previousPath.ap_mac;
    });


    const scaleFactorY = (img.clientHeight - img.spacingVertical) / img.naturalHeight;
    const scaleFactorX = (img.clientWidth - img.spacingHorizontal) / img.naturalWidth;
    const unPaths = filteredAps.reduce((acc, path, index) => {
      if (img?.clientHeight !== 0 && img?.naturalHeight !== 0) {

        const coordY = path.coord_y * scaleFactorY;
        const coordX = path.coord_x * scaleFactorX;

        let dPath = `M ${coordX} ${coordY}`;
        let distance = 0;

        const next = filteredAps[index + 1];

        let nextCoordX = coordX;
        let nextCoordY = coordY;

        if (next) {

          nextCoordY =
          next?.coord_y * scaleFactorY;
          
          nextCoordX =
          next?.coord_x * scaleFactorX;
          
          distance = Math.sqrt(
            Math.pow(nextCoordX - coordX, 2) + Math.pow(nextCoordY - coordY, 2)
          );

          dPath += ` L ${nextCoordX} ${nextCoordY}`
        }

        const fillPct = 0;

        /** @type {UniquePathSegments} */
        const uniquePath = {
          dPath: dPath,
          length: distance,
          start: [coordX, coordY],
          end: [nextCoordX, nextCoordY],
          fillPct,
          path: path,
        }

        acc.push(uniquePath);
      }

      return acc;
    }, []);
    setUniquePathSegments(calculateCurrPercentageOfPath(unPaths, slider));
  }, [paths, img.clientHeight, img.clientWidth, img.naturalHeight, img.spacingHorizontal, img.naturalWidth])


  const durations = useMemo(() => {
    return paths.reduce((acc, curr, index, array) => {
      const next = array[index + 1];
      if(next !== undefined) {
        const diff = getTimeDifference(curr.created_at, next.created_at);
        if (!acc[curr.department]) {
          acc[curr.department] = 0;
        }
        acc[curr.department] += diff;
      }
      return acc;
    }, {})
  }, [paths]);

  function calcAvatarPlacement() {
    if (uniquePathSegments.length === 0) return;
    const sliderPct = (slider.current / slider.length) * 100;
      const step = 100 / (uniquePathSegments.length - 1);
      let index = 0;

      const currPath = uniquePathSegments.find((_, i) => {
        const nextPointInTimeline = step * (i + 1);
        const currPointInTimeline = step * i;

        if (sliderPct >= currPointInTimeline && sliderPct < nextPointInTimeline) {
          index = i;
          return true;
        };
        return false;
      });

      if (!currPath) return [0,0];

      
      const currPlaceInTimeline = (100 / (uniquePathSegments.length - 1)) * index;
      const nextPlaceInTimeline = (100 / (uniquePathSegments.length - 1)) * (index + 1);
      const currLengthHere = nextPlaceInTimeline - currPlaceInTimeline;
      const currPctHere = sliderPct - currPlaceInTimeline;

      const fillPct = (currPctHere / currLengthHere) * 100;

      const [x1, y1] = currPath.start;
      const [x2, y2] = currPath.end;

      const x = x1 + ((x2 - x1) * (fillPct / 100));
      const y = y1 + ((y2 - y1) * (fillPct / 100));
      return [x,y]
  }

  const avatarCoords = calcAvatarPlacement();
  

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          title="Detalle de la visita"
          icon={Icono}
          goback={"true"}
          subtitle={`${formatDate(state.CREATED_AT)} | ${state.NAME}`}
        />
      </NeoGridContainer>
      <NeoGridContainer custom="trajectory-map">
        <NeoColumn extra="info-col" md={3}>
          <NeoCard>
            <NeoTitleMain
              style={{ marginBottom: 25 }}
              title="Información del contacto"
            />
            {isLoadingData ? "Cargando..." : <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                width: "100%",
                paddingInline: "8px"
              }}
            >
              <ContactInfoCamp
                style={{ flexBasis: "100%", lineBreak: "anywhere" }}
                name="Email"
                value={visit.EMAIL}
              />
              <ContactInfoCamp
                style={{ flexBasis: "100%" }}
                name="Nombre completo"
                value={visit.FULL_NAME}
              />
              <ContactInfoCamp
                style={{ flexBasis: "100%" }}
                name="Teléfono"
                value={visit.PHONE}
              />
              <ContactInfoCamp
                style={{ flexBasis: "45%" }}
                name="Género"
                value={visit.GENDER}
              />
              <ContactInfoCamp
                style={{ flexBasis: "45%" }}
                name="Edad"
                value={(() => {
                  const edad = moment().diff(moment(visit.BIRTHDATE), "years");
                  if (isNaN(edad)) {
                    return null;
                  }
                  return edad;
                })()}
              />
              <ContactInfoCamp
                style={{ flexBasis: "100%" }}
                name="OS"
                value="Android"
                // value={visit.OS}
              />
              <ContactInfoCamp
                style={{ flexBasis: "100%" }}
                name="Perfil de cliente"
                value="Campeón"
                // value={visit.CONTACT_PROFILE}
              />
            </div>}
          </NeoCard>
        </NeoColumn>
        <NeoColumn extra="main-card" md={9}>
          <NeoCard style={{ height: "100%" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                width: "100%",
                height: "100%"
              }}
            >
              <div style={{display: "flex", flexDirection:"column"}}>
                <NeoTitleMain extra="trajectory-title" title="Trayectoria" />
                <Player
                    slider={slider}
                    setSlider={setSlider}
                    uniquePaths={uniquePathSegments}
                    setModal={setModal}
                    playing={isPlayerPlaying}
                    setPlaying={setIsPlayerPlaying}
                    style={{
                      marginBottom: `15px`,
                    }}
                  />
                <NeoColumn extra="p-relative">

                

                  {/* Plano */}
                  <div className="preview-container" style={{ position: "relative", height: "100%" }}>
                    {/* Preview */}
                    {showPreview && (
                      <PreviewOverlay
                        loading={!allImagesLoaded}
                        onClickPlay={() => {
                          setShowPreview(false);
                          setIsPlayerPlaying(true);
                        }}
                      />
                    )}

                    {/* Moving user in trajectory */}
                    {!showPreview && uniquePathSegments.length > 0 && <div style={{
                      position: "absolute",
                      zIndex: 3,
                      top: avatarCoords[1] + img.spacingVertical / 2,
                      left: avatarCoords[0] + img.spacingHorizontal / 2,
                      transform: "translate(-50%, -70%)",
                    }}>
                    <img alt="avatar" src={avatar}/>
                    </div>}

                    <ContainedImages
                      setAllImagesLoaded={setAllImagesLoaded}
                      floors={floors}
                      currentFloorId={(showPreview && previewFloor) ? previewFloor.store_floor_id : currentPath.store_floor_id}
                      setImg={setImg}
                      modal={modal}
                    />

                    {/* Linea de recorrido */}
                    {currentPath && allImagesLoaded &&  (
                      <svg
                        width={img.clientWidth - (img.spacingHorizontal)}
                        height={img.clientHeight - img.spacingVertical}
                        style={{
                          position: "absolute",
                          top: "0",
                          left: (img.spacingHorizontal / 2),
                        }}
                      >
                        {uniquePathSegments.map(({ dPath, fillPct, length }, i) => {
                          const next = uniquePathSegments[i + 1];
                          let isVisible = false;

                          if (showPreview) {
                            if (biggestRangeOfPreviewPaths) {
                              const [startI, endI] = biggestRangeOfPreviewPaths;
                              isVisible =
                                showPreview && i >= startI && i < endI;
                            }
                          } else {
                            if (i >= firstApInCurrFloorIndex && i <= lastApInCurrFloorIndex) {
                              isVisible = true;
                            }

                            if (fillPct === 0) {
                              isVisible = false;
                            }

                          }

                          const fillAmount = {
                            FULL: 0,
                            NONE: length,
                            PARTIAL: length - (fillPct / 100) * length
                          }

                          let currPathFillPct = fillAmount.NONE;
                          

                          if (showPreview) {
                            if (biggestRangeOfPreviewPaths) {
                              const [startI, endI] = biggestRangeOfPreviewPaths;
                              const isPreviewPath =
                                showPreview && i >= startI && i < endI;
                              currPathFillPct = isPreviewPath
                                ? fillAmount.FULL
                                : fillAmount.NONE;
                            }
                          } else {
                              const prev = uniquePathSegments[i - 1];
                              if (prev && next) {
                                  if (fillPct === 0) {
                                    currPathFillPct = fillAmount.NONE;
                                  } else {
                                    currPathFillPct = fillAmount.PARTIAL;
                                  }
                              } else {
                                if (fillPct === 0) {
                                  currPathFillPct = fillAmount.NONE;
                                } else {
                                  currPathFillPct = fillAmount.PARTIAL;
                                }
                              }
                            
                          }
                        

                          return (
                            <path
                              d={dPath}
                              key={i}
                              style={{
                                fill: "transparent",
                                stroke: "#79AFE3",
                                strokeWidth: 5,
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                animationName: "draw",
                                animationDuration: "1s",
                                visibility: isVisible ? "visible" : "hidden",
                                animationTimingFunction: "linear",
                                animationFillMode: "forwards",
                                animationPlayState: "paused",
                                strokeDasharray: `${length}`,
                                strokeDashoffset: `${currPathFillPct}`
                              }}
                            />
                          );
                        })}
                      </svg>
                    )}

                    {/* Lista de APs */}
                    <div
                      style={{
                        width: `${img.clientWidth}px`,
                        height: `${img.clientHeight - img.spacingVertical}px`,
                        position: "absolute",
                        top: 0,
                        left: img.spacingHorizontal / 2,
                      }}
                    >
                      {currentPath && allImagesLoaded &&
                        aps
                          .filter(
                            (ap) =>
                              (showPreview && previewFloor) ? ap.STORE_FLOOR_ID === previewFloor.store_floor_id : ap.STORE_FLOOR_ID === currentPath.store_floor_id
                          )
                          .filter(
                            (ap) =>
                              Boolean(ap.COORD_X) &&
                              ap.COORD_X > 0 &&
                              Boolean(ap.COORD_Y) &&
                              ap.COORD_Y > 0
                          )
                          .map((ap, i) => {
                            const scaleFactorY = (img.clientHeight - img.spacingVertical) / img.naturalHeight;
                            const scaleFactorX = (img.clientWidth - img.spacingHorizontal) / img.naturalWidth;
                            const wasVisitedOnThisFloorTrajectory = apsVisibleInCurrentFloor.includes(ap.MAC_ADDRESS);
                            const isFirstApInFloor = !showPreview && ap.MAC_ADDRESS === uniquePathSegments[firstApInCurrFloorIndex].path.ap_mac;

                            const coordY = ap.COORD_Y * scaleFactorY ;
                            const coordX = ap.COORD_X * scaleFactorX;
                            const wasVisited =
                              paths.findIndex(
                                (path) =>
                                  path.ap_mac.toUpperCase() ===
                                  ap.MAC_ADDRESS.toUpperCase()
                              ) !== -1;
                            let background = (wasVisited && wasVisitedOnThisFloorTrajectory)
                              ? "#77b9f663"
                              : "#c3c3c38f";
                            let border = (wasVisited && wasVisitedOnThisFloorTrajectory)
                              ? "2px solid #99b1bf"
                              : "2px solid #c3c3c3";
                            if (
                              department !== undefined &&
                              department.ID === ap.DEPARMENT_ID
                            ) {
                              background = "#7de8806b";
                              border = "4px double #05a228c4";
                            }
                            return (
                              <div key={ap.ID} className="ap-container">
                                {isFirstApInFloor && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: `${coordX.toFixed(2)}px`,
                                      top: `${(coordY - 35).toFixed(2)}px`,
                                      zIndex: 2,
                                      transform: "translateX(-50%)",
                                      fontSize: "12px",
                                      background: "white",
                                      padding: "3px 7px 3px 7px",
                                      boxShadow: "0px 4px 4px 0px #00000040"
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        wordWrap: "nowrap",
                                        width: "100%",
                                        padding: 0,
                                        whiteSpace: "nowrap"
                                      }}
                                    >
                                      {ap.STORE_FLOOR_NAME}
                                    </p>
                                  </div>
                                )}
                                {(isFirstApInFloor) && (
                                    <svg
                                      style={{
                                        position: "absolute",
                                        width: "0.4rem",
                                        height: "0.6rem",
                                        bottom: "0px",
                                        top: `${coordY.toFixed(2)}px`,
                                        left: `${coordX.toFixed(2)}px`,
                                        transform: "translate(-50%, -50%)",
                                      }}
                                      width="6"
                                      height="10"
                                      viewBox="0 0 6 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5.15479 0H0.654785V7.5L2.90479 9.5L5.15479 7.5V0Z"
                                        fill="#17A2CD"
                                      />
                                    </svg>
                                )}
                                <NeoTooltip target={`#ap-${i}`} position="top">
                                  <p>{ap.NAME}</p>
                                  <p>{ap.DEPARMENT_NAME}</p>
                                </NeoTooltip>
                                <div
                                  className="ap"
                                  id={`ap-${i}`}
                                  data-pr-at="right+5 top"
                                  style={{
                                    top: `${coordY.toFixed(2)}px`,
                                    left: `${coordX.toFixed(2)}px`,
                                    background,
                                    border,
                                    transition:
                                      "background 300ms ease-in-out, border-color 300ms ease-in-out"
                                  }}
                                />
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </NeoColumn>
              </div>
              <div className="right-col">
                <Review
                  stars={visit.REVIEW_STARS}
                  comment={visit.COMMENTS}
                  score={visit.COMMENT_SCORE}
                  isLoading={isLoadingData}
                />
                <div style={{height: 1, width: "100%", background: "#C2C2C2", margin: "5px 0"}}></div>
                <TableDurations
                  rows={durations}
                  totalDuration={visit.DURATION}
                  setDepartment={setDepartment}
                  isLoading={isLoadingData}
                />
              </div>
            </div>
          </NeoCard>
        </NeoColumn>
      </NeoGridContainer>
    </>
  );
}


const COMMENT_MAX_LENGTH_PREVIEW = 50;

function Review({ stars, comment, score, isLoading }) {
  const [showPreview, setShowPreview] = useState(true);
  const isLongText = comment?.length > COMMENT_MAX_LENGTH_PREVIEW;
  let preview = comment ?? "";

  if (comment && isLongText) {
    preview = comment.slice(0, COMMENT_MAX_LENGTH_PREVIEW).trim() + "...";
  }


  return (
    <div className="rating">
      <NeoTitleMain title="Calificación" />
      <NeoColumn extra="p-text-bold">
        {isLoading ? (<span style={{fontSize: 14, fontWeight: 400}}>Cargando...</span>) : stars === null ? (
          <span style={{fontSize: 14, fontWeight: 400}}>Sin respuesta</span>
        ) : (
          <div>
            <div>
              <NeoRating value={stars} readOnly stars={5} cancel={false} />
              <p style={{fontSize: 14, margin: 0, fontWeight:"normal", marginTop: 3}}>Enviado 3 dias después de la visita</p>
            </div>
            <div style={{marginTop: 8}}>
              "{isLongText ? (showPreview ? preview : comment) : comment}"
              <SentimentIcon score={score} />
              {isLongText && showPreview && <p onClick={() => setShowPreview(prev => !prev)} style={{margin: 0, userSelect:"none", fontSize: "14.5px", color: "#194893", textDecorationLine:"underline", fontWeight: "400", cursor: "pointer"}}>Ver más</p>}
            </div>
          </div>
        )}
      </NeoColumn>
    </div>
  );
}

function TableDurations({ rows, totalDuration, setDepartment, isLoading }) {
  const sortedRows = Object.entries(rows).sort((a, b) => b[1] - a[1]);
  const totalDurations = Object.values(rows).reduce((acc, curr) => acc + curr, 0);
  return (
    <div className="table-duration">
      <NeoTitleMain
        style={{
          width: "100%",
          flexBasis: "100%",
          fontSize: ".25rem",
          margin: 0,
        }}
        title="Top departamentos"
      />
      {isLoading ? <span style={{marginLeft: 8, fontSize: 14, fontWeight: 400}}>Cargando...</span> : <div style={{ display: "grid", gap: "0.5rem", padding: "8px" }}>
        {sortedRows.map((row, index) => (
          <TableRow
            onMouseOver={() => setDepartment(row[0])}
            onMouseOut={() => setDepartment(undefined)}
            key={row[0]}
            department={row[0]}
            duration={row[1]}
            index={index}
            isLast={index === sortedRows.length - 1}
          />
        ))}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "4fr 4fr",
            backgroundColor: "#e2e2e2",
            paddingBlock: "0.25rem",
            paddingInline: "0.5rem",
            fontSize: 14
          }}
        >
          <p style={{ margin: 0 }}>Duración total</p>
          <p style={{ margin: 0, justifySelf: "end" }}>
            {secondsToDuration(totalDurations)} min
          </p>
        </div>
      </div>}
    </div>
  );
}

function TableRow({ department, duration, index, onMouseOver, onMouseOut, isLast }) {
  return (
    <div
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 4fr 3fr",
        paddingInline: "0.5rem",
        borderBottom: isLast ? "" : "1px solid #e2e2e2",
        fontSize: 14
      }}
    >
      <p style={{ margin: 0 }}>{index + 1}.</p>
      <p style={{ margin: 0 }}>{department}</p>
      <p style={{ margin: 0, justifySelf: "end" }}>
        {secondsToDuration(duration)} min
      </p>
    </div>
  );
}

function getTimeDifference(date1, date2) {
  const diffInMilliseconds = Math.abs(date2 - date1);
  const diffInSeconds = diffInMilliseconds / 1000;
  return diffInSeconds;
}

function secondsToDuration(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const minutesString = String(minutes).padStart(2, "0");
  const secondsString = String(remainingSeconds).padStart(2, "0");

  return `${minutesString}:${secondsString}`;
}

function formatDate(date) {
  return new Intl.DateTimeFormat("es-MX", {
    dateStyle: "short",
    timeStyle: "short",
  }).format(moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate());
}
