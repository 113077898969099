import { useState } from 'react';
import * as AudienceTypes from '../models/audience-rule.model'
import { getAudienceContacts, testAudienceRules } from 'service/Audience.service';

/**
 * Fetches the contacts that match the provided audience rules
 * or the audience with the provided id.
 * 
 * @param {{
 *  audienceId?: number,
 *  rules?: AudienceTypes.AudienceRuleModel[],
 *  immediate?: boolean
 * }} param0
 */
function useAudienceContacts({ audienceId = null, rules = null, immediate = true }) {
    /** @type {number} */
    const initialContacts = 0;
    const [contacts, setContacts] = useState(initialContacts);

    /**
     * @param {AudienceTypes.AudienceRuleModel[]|null} newRules
     */
    async function fetch(newRules = null) {
        try {
            const testRules = newRules || rules;
    
            if (audienceId) {
                const contacts = await getAudienceContacts(audienceId);
    
                setContacts(contacts.payload.count);
            } else if (testRules) {
                const contacts = await testAudienceRules(rules);
    
                setContacts(contacts.payload.count ?? 0);
            }
        } catch (error) {
            console.error(error);
            setContacts(0);
        }
    }

    if (immediate) {
        fetch(rules);
    }

    return {
        contacts,
        fetch
    };
}

export default useAudienceContacts;