
import { useState } from "react";
import { updateAdStatus } from "../../../service/Media.service";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";

/**
 *
 * @param {{
 *  adId: number,
 *  currentStatus: string,
 *  onUpdateStatusError: () => void,
 *  onUpdateStatusSuccess: (status: { status: string }) => void
 * }} param0
 * @returns
 */
export default function PauseCampaignButton({
  adId,
  currentStatus,
  onUpdateStatusError,
  onUpdateStatusSuccess,
}) {
  const [isPausingAd, setIsPausingAd] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const handlePauseAd = async () => {
    const status = currentStatus === "Pausado" ? "Activo" : "Pausado";
    setIsPausingAd(true);
    const response = await updateAdStatus(adId, status);

    if (!response.success) {
      onUpdateStatusError();
      setDialogVisible(false);
    } else {
      onUpdateStatusSuccess({ status });
      setDialogVisible(false);
    }

    setIsPausingAd(false);
  };

  const handleConfirm = async () => {
    setDialogVisible(true);
  };

  const handleCancel = async () => {
    setDialogVisible(false);
  };

  return (

    <>
    <NeoButtonMain
      alternative="outlined"
      extra="action-button"
      label= {currentStatus === "Pausado" ? "Activar" : "Pausar"}
      icon={currentStatus === "Pausado"  ? "pi pi-play custom-play-icon" : "pi pi-pause custom-pause-icon"}
      onClick={handleConfirm}
    >
    {isPausingAd ? <i className="pi pi-spin pi-spinner mr-5"></i> : ""}
        </NeoButtonMain>

        <NeoDialog
         header={currentStatus === "Pausado" ? "Activar anuncio" : "Pausar anuncio"}
        custom="custom-modal-header"
        visible={dialogVisible}
        closable={false}
        dismissableMask={true}
        onHide={handleCancel} // Cierra el diálogo al hacer clic fuera de él
        resizable={false}
        >
        <div className="p-d-flex p-flex-column gap-17">
          <p className="m-0 fs-14 text-black">
          {currentStatus === "Pausado"
              ? "Si reactivas este anuncio, se volverá a mostrar a tus clientes. ¿Estás seguro de que deseas reactivarlo?"
              : "Si pausas este anuncio, dejará de mostrarse, pero puedes reactivarlo cuando desees. ¿Estás seguro de que deseas pausarlo?"}
         
          </p>

          

          <div className="p-d-flex p-jc-end gap-8">
            <NeoButtonMain alternative="outlined" onClick={handleCancel}>
              Cancelar
            </NeoButtonMain>
            <NeoButtonMain onClick={handlePauseAd}>
              Continuar
            </NeoButtonMain>
          </div>
        </div>
        </NeoDialog>
  
</>     
      
  );
}

