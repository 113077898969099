import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ContactRulesContext } from "./contexts/ContactRules.context";
import * as AudienceRuleTypes from "../../models/audience-rule.model";
import { getRulesDataOptions } from "service/Audience.service";

/**
 * @typedef {'equals' | 'not-equals' | 'gt' | 'gte' | 'lt' | 'lte' | 'between' | 'contains' | 'not-contains'} RuleOperator
 * 
 * @type {RuleOperator[]}
 */
const RULE_OPERATORS = [
    'equals',
    'not-equals',
    'gt',
    'gte',
    'lt',
    'lte',
    'between',
    'contains',
    'not-contains',
];

/**
 * @type {Record<string, RuleOperator[]>}
 */
const RULE_OPERATORS_BY_CRITERIA = {
    string: ['equals', 'not-equals', 'contains', 'not-contains'],
    number: ['equals', 'not-equals', 'gt', 'gte', 'lt', 'lte', 'between'],
    date: ['equals', 'not-equals'],
    percentage: ['equals', 'not-equals', 'gt', 'gte', 'lt', 'lte', 'between'],
}

/**
 * @param {AudienceRuleTypes.AudienceRuleModel} rule 
 */
function useRulePropertiesResolver(rule) {
    const { ruleCriteria, ruleOperators } = useContext(ContactRulesContext);
    const criteria = ruleCriteria.find(c => c.ID === rule.CRITERIA_ID);
    const operator = ruleOperators.find(o => o.ID === rule.OPERATOR_ID);

    return {
        criteria: criteria,
        dataType: criteria?.TYPE,
        isRange: ruleOperatorIsRange(operator?.NAME),
        isSelect: criteria?.DATA_OPTIONS_SOURCE !== undefined && criteria?.DATA_OPTIONS_SOURCE !== null,
        isMultiple: ruleOperatorIsMultiple(operator?.NAME),
        inputSuffix: getRuleInputSuffix(criteria),
        inputProperties: getRuleInputProperties(criteria),
        compatibleOperators: getRuleOperatorsByCriteria(ruleCriteria, rule).map(operatorId => ruleOperators.find(o => o.NAME === operatorId)).filter(Boolean),
    };
}

/**
 * When a criteria has a DATA_OPTIONS_SOURCE, the options are fetched from the server.
 * This function is used to get the options for the select input.
 * 
 * @param {AudienceRuleTypes.AudienceRuleCriteriaModel} criteria
 */
function useCriteriaOptions(criteria) {
    /**
     * @type {{
     *  id: string,
     *  name: string,
     *  groupField?: string,
    * }[]}
     */
    const initialOptions = [];

    const [options, setOptions] = useState(initialOptions);

    useEffect(() => {
        async function fetchOptions() {
            const { payload } = await getRulesDataOptions(criteria.ID, criteria.DATA_OPTIONS_SOURCE);
            setOptions(payload[criteria.DATA_OPTIONS_SOURCE]);
        }

        let fetched = false;

        if (! fetched) {
            fetchOptions();
        }

        return () => {
            fetched = true;
        };
    }, [criteria.DATA_OPTIONS_SOURCE]);

    return { options };
}

function useRuleValidator() {
    /**
     * @type {{
     *  current: Record<string, (options: { silent: boolean }) => boolean>
     * }}
     */
    const ruleValidators = useRef({});

    /**
     * @type {(rule: AudienceRuleTypes.AudienceRuleModel, validatorFn: (config: { silent: boolean }) => boolean) => void} 
     */
    const registerValidator = useCallback((rule, validatorFn) => ruleValidators.current[rule.ID] = validatorFn, []);

    /**
     * @type {(rule: AudienceRuleTypes.AudienceRuleModel) => void} 
     */
    const removeValidator = useCallback((rule) => delete ruleValidators.current[rule.ID], []);

    /**
     * @param {{
     *  silent: boolean
     * }} param0
     * @returns {boolean}
     */
    function triggerValidation({ silent = false }) {
        let isValid = true;

        Object.values(ruleValidators.current).forEach((validatorFn) => {
            const valid = validatorFn({ silent });
            if (!valid) isValid = false;
        });

        return isValid;
    };

    return { registerValidator, removeValidator, triggerValidation };
}

/**
 * @param {string} operator 
 * @returns {boolean}
 */
function ruleOperatorIsRange(operator) {
    return operator === 'between';
}

/**
 * @param {string} operator
 * @returns {boolean}
 */
function ruleOperatorIsMultiple(operator) {
    return operator === 'contains' || operator === 'not-contains';
}

/**
* @param {AudienceRuleTypes.AudienceRuleCriteriaModel} criteria 
 */
function getRuleInputSuffix(criteria) {
    if (criteria?.TYPE === 'percentage') {
        return '%';
    }

    return null;
}

/**
* @param {AudienceRuleTypes.AudienceRuleCriteriaModel} criteria 
 */
function getRuleInputProperties(criteria) {
    if (criteria?.TYPE === 'percentage') {
        return {
            min: 0,
            max: 100,
        };
    }

    if (criteria?.TYPE === 'number') {
        return {
            keyfilter: 'pint',
        };
    }

    return {};
}

/**
 * @param {AudienceRuleTypes.AudienceRuleCriteriaModel[]} ruleCriteria 
 * @param {AudienceRuleTypes.AudienceRuleModel} rule
 */
function getRuleOperatorsByCriteria(ruleCriteria, rule) {
    const criteria = ruleCriteria.find(c => c.ID === rule.CRITERIA_ID);

    return RULE_OPERATORS_BY_CRITERIA[criteria?.TYPE] ?? RULE_OPERATORS;
}

export { useRulePropertiesResolver, useCriteriaOptions, useRuleValidator };