import { auth } from "../config/firebase.config";
import { CONST } from "../consts/consts";
import backendRequestUtil from "../utils/backendRequest.util";

const getMediaInfoList = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(`${CONST.API_MEDIA_ROUTE}/getMedia`, requestOptions);
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getAdDataByCampaign = async (campaignId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({
      campaignId: campaignId,
    }),
  };

  try {
    result = await fetch(`${CONST.API_MEDIA_ROUTE}/getAdsCampaign`, requestOptions);
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    console.log("error",error);
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const postUploadNewImageMedia = async (body) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/newImageMedia`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const postUploadNewVideoMedia = async (body) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/newVideoMedia`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getMediaStore = async (mediaId, fileTypeId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({ mediaId: mediaId, fileTypeId: fileTypeId }),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/getMediaStore`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getStatistcMedia = async (mediaId, fileTypeId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({ mediaId: mediaId, fileTypeId: fileTypeId }),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/getMediaStatistics`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

export const getStatisticsStore = async (mediaId, fileTypeId, storeId) => {
  const params = { mediaId, fileTypeId, storeId };
  const { success, body, error } = await backendRequestUtil.get(
    "/api/v2/media/store",
    { params }
  );
  return success ? { success, payload: body } : { success, error };
};

const getMediaDataInfo = async (mediaId, fileTypeId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({ mediaId: mediaId }),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/getMediaData`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const updateOnlyMediaData = async (body) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/updateMetaMedia`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const updateAdStatus = async (mediaId, status) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({
      mediaId: mediaId,
      status: status,
    }),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/updateAdStatus`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const updateToDefaultStatus = async (mediaId, defaultstatus) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify({
      mediaId: mediaId,
      defaultstatus: defaultstatus,
    }),
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/updateDefaultStatus`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getDefaultAd = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(
      `${CONST.API_MEDIA_ROUTE}/getDefaultAd`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

export {
  getMediaInfoList,
  postUploadNewImageMedia,
  postUploadNewVideoMedia,
  getMediaStore,
  getStatistcMedia,
  getMediaDataInfo,
  updateOnlyMediaData,
  updateAdStatus,
  updateToDefaultStatus,
  getDefaultAd,
  getAdDataByCampaign,
};
