import { DateTime } from "luxon";

const dateTemplate = (fecha) => {
  if (!fecha) {
    return null;
  }
  let date = new Date(fecha);
  date.setHours(date.getHours() - 6);
  return (
    <span>{`${date.getDate()} ${
      monthsSelection()[date.getMonth()].nombre
    } ${date.getFullYear()}`}</span>
  );
};

const dateTimeTemplate = (fecha) => {
  if (!fecha) {
    return null;
  }
  let date = new Date(fecha);
  date.setHours(date.getHours());
  return (
    <span>{`${date.getDate()} ${
      monthsSelection()[date.getMonth()].nombre
    } ${date.getFullYear()} ${addZero(
      date
        .getHours()
        .toLocaleString("en-US", { timeZone: "America/Mexico_City" })
    )}:${addZero(
      date
        .getMinutes()
        .toLocaleString("en-US", { timeZone: "America/Mexico_City" })
    )}`}</span>
  );
};

const addZero = (time) => {
  return /^\d$/.test(time) ? `0${time}` : time
};


const filterDate = (value, filter) => {
  let date = new Date(value)
  
  if (filter[0] && !filter[1]) {
    return date.getTime() >= filter[0].getTime();
  }

  if (!(filter[0] && filter[1])) {
    return false;
  }

  const dateToFilter = date.getTime();
  const firstDate = filter[0].getTime();
  let secondDate = new Date(filter[1].getTime());

  secondDate.setHours(23);
  secondDate.setMinutes(59);
  secondDate.setSeconds(59);
  secondDate = secondDate.getTime();

  return dateToFilter >= firstDate && dateToFilter <= secondDate;
}


const parseDate = (dateStr) => {
  const [day, month, year] = dateStr.split("/").map(Number);
  return new Date(year, month - 1, day); // Mes en JavaScript empieza en 0 (enero)
};

const filterRangeDate = (value, filter) => {
  //console.log(value, filter);
  if (!value) {
   // console.log("No hay periodo (value), retornando false.");
    return false;
  }

  // El periodo tiene dos fechas, dividimos en start y end del periodo
  const [periodStartStr, periodEndStr] = value.split(' - ');
  
  // Convertimos las fechas del periodo al formato correcto
  const periodStart = parseDate(periodStartStr);
  const periodEnd = parseDate(periodEndStr);

  // Mostramos las fechas del periodo que estamos intentando comparar
  //console.log("Fechas del periodo:", { periodStart, periodEnd });

  // Verificamos si las fechas del periodo son válidas
  if (!(periodStart instanceof Date && !isNaN(periodStart)) || 
      !(periodEnd instanceof Date && !isNaN(periodEnd))) {
    //console.log("Las fechas del periodo no son válidas, retornando false.");
    return false;
  }

  // Verificamos si hay una sola fecha o un rango de fechas en el filtro
  const [filterStart, filterEnd] = filter;

  // Mostramos el valor del filtro
  //console.log("Filtro recibido:", { filterStart, filterEnd });

  // Caso 1: Si solo se selecciona una fecha (filterEnd es null), verificamos si esa fecha está dentro del periodo
  if (filterStart && !filterEnd) {
    const selectedDate = filterStart.getTime();
    /*console.log("Seleccionando una sola fecha:", selectedDate);
    console.log("Comparando con el rango del periodo:", {
      periodStart: periodStart.getTime(),
      periodEnd: periodEnd.getTime(),
    });*/

    // Verificamos si la fecha seleccionada está dentro del rango del periodo
    const isWithinRange = selectedDate >= periodStart.getTime() && selectedDate <= periodEnd.getTime();
    //console.log("¿La fecha seleccionada está dentro del rango?", isWithinRange);
    return isWithinRange;
  }

  // Caso 2: Si se seleccionan dos fechas (rango de fechas), verificamos si ambas están dentro del periodo
  if (filterStart && filterEnd) {
    const rangeStart = filterStart.getTime();
    const rangeEnd = filterEnd.getTime();

    // Verificamos si alguna de las fechas del rango de fechas del filtro está dentro del periodo
    const isStartWithinPeriod = rangeStart >= periodStart.getTime() && rangeStart <= periodEnd.getTime();
    const isEndWithinPeriod = rangeEnd >= periodStart.getTime() && rangeEnd <= periodEnd.getTime();

    // También es necesario verificar si el rango del filtro se cruza con el rango del periodo
    const isRangeOverlap = (rangeStart <= periodEnd.getTime() && rangeEnd >= periodStart.getTime());

    return isStartWithinPeriod || isEndWithinPeriod || isRangeOverlap;
  }

  //console.log("No cumple con ninguna condición, retornando false.");
  return false;
};

const monthsSelection = () => {
  return [
    {"nombre": "Enero", "index": 0},
    {"nombre": "Febrero", "index": 1},
    {"nombre": "Marzo", "index": 2},
    {"nombre": "Abril", "index": 3},
    {"nombre": "Mayo", "index": 4},
    {"nombre": "Junio", "index": 5},
    {"nombre": "Julio", "index": 6},
    {"nombre": "Agosto", "index": 7},
    {"nombre": "Septiembre", "index": 8},
    {"nombre": "Octubre", "index": 9},
    {"nombre": "Noviembre", "index": 10},
    {"nombre": "Diciembre", "index": 11},
  ]
}

const dateFormat = (fecha) => {
  if(!fecha){
    return null
  }
  let date = new Date(fecha)
  return `${date.getDate()} ${monthsSelection()[date.getMonth()].nombre} ${date.getFullYear()}`
}


/**
   * Receives a date string from the server and returns a Date object.
   * Since the server returns a date in UTC because of javascript's Date object
   * parsing. As we only store the date in the database, if we have the value '2024-08-24'
   * it will return '2024-08-24T00:00:00.000Z' which is the same date but with the timezone
   * set to UTC. So if we parse this to UTC-6 to a JS Date it will show '2024-08-23T18:00:00.000Z'
   * and thats the date the Calendar component will show, which is incorrect.
   * @param {string} date
   * @returns
   */
const parseDateFromServer = (date) => {
  return DateTime.fromSQL(
    DateTime.fromISO(date).setZone("UTC").toSQLDate()
  ).toJSDate();
};


export {dateTemplate, filterDate, monthsSelection, dateFormat, dateTimeTemplate, filterRangeDate, parseDateFromServer}
