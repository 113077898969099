import backendRequestUtil from "../utils/backendRequest.util";

export async function getVisits(bodyToSend) {
  const { success, body, error } = await backendRequestUtil.post(`/api/v2/visit`, bodyToSend)
  return success ? { success, payload: body } : { success, error }
};

export async function getVisit(visitId) {
  const { success, body, error } = await backendRequestUtil.get(`/api/v2/visit/${visitId}`)
  return success ? { success, payload: body } : { success, error }
}
