import { isEmpty } from "utils/commons.util";
import * as AudienceRuleTypes from "../../models/audience-rule.model";

const errorMessages = {
    'required': 'Este campo es requerido',
};

/**
 * @typedef {{
 *  isValid: boolean,
 *  message: string | null
 * }} ErrorValidation
 */

/**
 * @param {AudienceRuleTypes.AudienceRuleModel} rule 
 * @returns {{
 *  isValid: boolean,
 *  errors: {
 *      criteria: string | null
 *      operator: string | null
 *      value: string | null
 *  }
 * }}
 */
function validateRule(rule) {
    const errors = {};

    let criteria = validateCriteria(rule.CRITERIA_ID);
    errors.criteria = criteria.isValid ? null : criteria.message;

    let operator = validateOperator(rule.OPERATOR_ID);
    errors.operator = operator.isValid ? null : operator.message;

    let value = validateValue(rule.VALUE);
    errors.value = value.isValid ? null : value.message;

    const isValid = Object.values(errors).every((error) => error === null);
    
    return { isValid,  errors };
};

/**
 * Validates the rule criteria.
 * @param {any} value - the criteria value
 * @returns {ErrorValidation}
 */
function validateCriteria(value) {
    let isValid = true;
    if (! value) {
        isValid = false;
    }

    return {
        isValid,
        message: errorMessages.required,
    };
}

/**
 * Validates the rule operator
 * @param {any} value - the operator value
 * @returns {ErrorValidation}
 */
function validateOperator(value) {
    let isValid = true;
    if (! value) {
        isValid = false;
    }

    return {
        isValid,
        message: errorMessages.required,
    };
}

/**
 * Validates the value of a rule
 * @param {any} value - the value of the rule
 * @returns {ErrorValidation}
 */
function validateValue(value) {
    let isValid = true;
    if (isEmpty(value, { validateObjectKeys: true })) {
        isValid = false;
    }

    return {
        isValid,
        message: errorMessages.required,
    };
}

export { validateRule, validateCriteria, validateOperator, validateValue };