import { createContext } from "react";
import * as AudienceRuleTypes from "models/audience-rule.model";

const context = {
    /** @type {(rule: AudienceRuleTypes.AudienceRuleModel, validatorFn: (config: { silent: boolean }) => boolean) => void} */
    registerValidator: () => {},
    /** @type {(config: { silent: boolean }) => boolean} */
    triggerValidation: () => undefined,
    /** @type {(rule: AudienceRuleTypes.AudienceRuleModel) => void} */
    removeValidator: () => {},
}

const RuleValidationContext = createContext(context);

export { RuleValidationContext };