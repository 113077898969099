import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";

/**
 * @param {{
 *  onNewMultipleRule: () => void
 * }} param0 
 */
export default function NewMultipleRuleButton({ onNewMultipleRule }) {
    return (
        <NeoButtonMain alternative="outlined" onClick={onNewMultipleRule}> 
            <i className="pi pi-plus mr-6"></i>
            Crear criterio múltiple 
        </NeoButtonMain>
    )
}