import { useHistory } from "react-router-dom";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";

export default function NewAudienceButton() {
    const history = useHistory();

    const handleCreate = () => {
        history.push('/audiencias/nueva-audiencia');
    };

    return (
        <NeoButtonMain onClick={handleCreate}>
            <i className="pi pi-plus mr-5"></i>
            Crear audiencia
        </NeoButtonMain>
    );
}