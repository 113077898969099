const { createContext } = require("react");
const AudienceRuleTypes = require("../../../models/audience-rule.model");

const context = {
    /** @type {AudienceRuleTypes.AudienceRuleOperatorModel[]} */
    ruleOperators: [],
    /** @type {AudienceRuleTypes.AudienceRuleCriteriaModel[]} */
    ruleCriteria: [],
}

const ContactRulesContext = createContext(context);

/**
 * @typedef {typeof context} ContactRulesContextType
 */
export { ContactRulesContext };
