const isEmptyTheState = (data) => {
  const newData = Object.entries(data);
  let isEmpty = false;
  newData.forEach((prop) => {
    if (!prop[1]) {
      isEmpty = true;
    }
  });
  return isEmpty;
};

const validateMail = (value) => {
  const formato =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let itIsValid = true;
  let message = null;

  if (!formato.test(value)) {
    message = "El correo no tiene formato válido";
    itIsValid = false;
  }

  return { itIsValid: itIsValid, message: message };
};

const validateExistence = (value) => {
  let itIsValid = true;
  let message = null;

  if (
    value === undefined ||
    value === "" ||
    value === false ||
    value === null
  ) {
    message = "Este campo es necesario";
    itIsValid = false;
  }

  return { itIsValid: itIsValid, message: message };
};

const mxCurrencyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const addressDataFormatting = ({
  country = "México",
  postalCode = "",
  state = "",
  city = "",
  street = "",
  internalNumber = "",
  colony = "",
}) => {
  if (!country) return;
  if (!postalCode) return;
  if (!state) return;
  if (!city) return;
  if (!street) return;
  if (!colony) return;
  return `${street} ${internalNumber ? internalNumber : ""
    }, ${colony}, ${postalCode} ${city}, ${state}, ${country}`;
};

const filterRangeNumber = (value, filter) => {
  console.log(value, filter);
  if (!filter[0] && !filter[1]) {
    return true;
  }

  if (filter[0] && !filter[1]) {
    return value >= filter[0];
  }

  if (!(filter[0] && filter[1])) {
    return value <= filter[1];
  }

  return filter[0] <= value && filter[1] >= value;
};

const filterRangeNumberComa = (value, filter) => {
  // Eliminar comas del valor antes de convertirlo a número
  const numericValue = parseFloat(value.replace(/,/g, ''));

  if (!filter[0] && !filter[1]) {
    return true;
  }

  if (filter[0] && !filter[1]) {
    return numericValue >= filter[0];
  }

  if (!(filter[0] && filter[1])) {
    return numericValue <= filter[1];
  }

  return filter[0] <= numericValue && filter[1] >= numericValue;
};

const filterRangeNumberPorcent = (value, filter) => {
  // Eliminar el símbolo de porcentaje y convertir a número
  const numericValue = parseFloat(value.replace('%', ''));

  if (!filter[0] && !filter[1]) {
    return true;
  }

  if (filter[0] && !filter[1]) {
    return numericValue >= filter[0];
  }

  if (!(filter[0] && filter[1])) {
    return numericValue <= filter[1];
  }

  return filter[0] <= numericValue && filter[1] >= numericValue;
};


function isValidUrl(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

/**
 * This functions retrieves the data from an
 * object using dot notation.
 *
 * e.g.
 * INPUT: data_get({a: {b: {c: 1}}}, 'a.b.c')
 * OUTPUT 1
 *
 * @template T
 * @param {Object} obj The object to retrieve the data from
 * @param {string} path The path to the data using dot notation
 * @param {T} defaultValue The default value to return if the path is not found
 *
 * @returns {T|undefined}
 */
function data_get(obj, path, defaultValue = undefined) {
  return path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), obj);
}

/**
 * @template TValue, TKey
 * @param {TValue[]} array
 * @param {(e: TValue) => TKey} f
 * @returns {{[key: string]: TValue[] }}
 */
function groupBy(array, f) {
  return array.reduce(
    (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  );
};

/**
 * Checks that the value is not null, undefined, 
 * an empty string, an empty array or an empty object. 
 * @param {any} value 
 * @param {{
 *  allowEmptyArray?: boolean,
 *  allowEmptyObject?: boolean,
 *  allowEmptyString?: boolean,
 *  allowZero?: boolean,
 *  allowFalse?: boolean,
 *  validateObjectKeys?: boolean,
 * }} config
 * 
 * @returns {boolean}
 */
function isEmpty(value, config = {}) {
  const {
    allowEmptyArray = false,
    allowEmptyObject = false,
    allowEmptyString = false,
    allowZero = false,
    allowFalse = false,
    validateObjectKeys = false,
  } = config;

  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" && !allowEmptyString) {
    return isEmptyString(value);
  }

  if (Array.isArray(value) && !allowEmptyArray) {
    return isEmptyArray(value);
  }

  if (typeof value === "object" && !allowEmptyObject) {
    if (isEmptyObject(value)) return true;

    if (!validateObjectKeys) return false;

    return Object.keys(value).findIndex((key) => isEmpty(value[key], config)) !== -1;
  }

  if (typeof value === "number" && !allowZero && value === 0) {
    return true;
  }

  if (typeof value === "boolean" && !allowFalse && value === false) {
    return true;
  }

  return false;
}

/**
 * @param {string} string 
 * @returns {boolean}
 */
function isEmptyString(string) {
  return string === "";
}

/**
 * @param {any[]} array 
 * @returns {boolean}
 */
function isEmptyArray(array) {
  return array.length === 0;
}

/**
 * @param {Object} object 
 * @returns {boolean}
 */
function isEmptyObject(object) {
  return Object.keys(object).length === 0;
}

function downloadUrl(url) {
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.click();
}

export {
  isEmptyTheState,
  validateMail,
  validateExistence,
  mxCurrencyFormatter,
  addressDataFormatting,
  filterRangeNumber,
  filterRangeNumberComa,
  filterRangeNumberPorcent,
  isValidUrl,
  data_get,
  groupBy,
  isEmpty,
  isEmptyString,
  isEmptyArray,
  isEmptyObject,
  downloadUrl,
};
