import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base.js";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base.js";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base.js";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base.js";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base.js";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base.js";
import NeoTooltip_icon from "../../../design/design_components/neo/overlay/NeoTooltip_icon.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import {
  getCampaignInfoList,
  updateCampaignStatus,
} from "../../../service/Campaign.service.js";
import { filterRangeNumber, filterRangeNumberComa, filterRangeNumberPorcent  } from "../../../utils/commons.util.js";
import {
  dateFormat,
  filterDate,
  filterRangeDate,
} from "../../../utils/date.utils.js";

import * as MEDIA_CONSTS from "../../../consts/media.consts.js";
import { CONST } from "../../../consts/consts.js";
import useToastContext from "../../../hooks/useToastContext.hook.js";
// Componentes nuevos
import { Menu } from "primereact/menu";
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';

import SetAdAsDefaultModal from "./SetAdAsDefaultModal.component";

import '../style.scss';

export default function CampaniaTable({ mediaCollection, setMediaCollection }) {
  const mediaTableRef = useRef();
  const [loading, setLoading] = useState(false);
  //const [mediaCollection,setMediaCollection] = useState([]); // Inicializado arreglo de datos de medios
  const [isFiltered, setIsFiltered] = useState(false);
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);
  const [images, setImages] = useState([]); // Almacena las imágenes de la galería
  const toast = useToastContext(); // Manejo del mensaje del default
  const [defaultAdModalOpen, setDefaultAdModalOpen] = useState(null);
  const ROWS_COUNT = 10;
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: ROWS_COUNT,
    page: 1,
  });
  const [rowData, setRowData] = useState(null); 
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogVisibleR, setDialogVisibleR] = useState(false);
  // Arreglo para mostrar en el filtro
  const mediaTypeCollection = [
    /* { label: "Video", value: "Video" },
    { label: "Imagen", value: "Imagen" },
    { label: "HTML", value: "HTML" },*/
    { label: "Imagen dinámica", value: "Imagen dinámica" },
    { label: "Imagen estática", value: "Imagen estática" },
  ];

    // Arreglo para mostrar en el filtro
    const estatusCollection = [
       { label: "Activa", value: "Activa" },
      { label: "Pausada", value: "Pausada" },
      { label: "Finalizada", value: "Finalizada" },
      { label: "Programada", value: "Programada" },
      { label: "Incompleta", value: "Incompleta" },
    ];
  

  // Obtener la información de los medios
  const gettingMediaData = async () => {
    setLoading(true);
    const rawMedia = await getCampaignInfoList();
    
    setMediaCollection(rawMedia.result);
   
    setLoading(false);
  };

  useEffect(() => {
    let mount = true;

    if (mount) {
      gettingMediaData();
    }
    return () => {
      mount = false;
    };
  }, []);

  // Manejo del clic para mostrar la galería de imágenes
  const handleImageClick = (data) => {
    const galleryImages = [];

    if (data.URL) {
      galleryImages.push({
        source: data.URL, // Imagen de Escritorio
        thumbnail: data.URL,
        title: "Computadora de escritorio",
      });
    }
    if (data.URL_ANDROID) {
      galleryImages.push({
        source: data.URL_ANDROID, // Imagen Android
        thumbnail: data.URL_ANDROID,
        title: data.URL_IOS ? "Android" : "Android / iOS",
      });
    }
    if (data.URL_IOS) {
      galleryImages.push({
        source: data.URL_IOS, // Imagen iOS
        thumbnail: data.URL_IOS,
        title: data.URL_ANDROID ? "iOS" : "Android / iOS",
      });
    }
    setImages(galleryImages);
    setActiveIndex(0);
  };

  // Efecto para abrir la galería de imágenes
  useEffect(() => {
    if (
      images.length > 0 &&
      galleria.current &&
      typeof galleria.current.show === "function"
    ) {
      setTimeout(() => {
        galleria.current.show();
      }, 100);
    }
  }, [images, galleria]);

  // Perder el foco del control de fecha al limpiar para que vuelva a mostrar el calendario
  const handleClearButtonClick = (e) => {
    setTimeout(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, 0);
  };

  /*// Convertir en default
  const handleDefault = async (rowData) => {
    try {
      const response = await updateToDefaultStatus(rowData.ID_MEDIA, 1);
      if (response.success) {
        // Actualiza la tabla después de que la solicitud sea exitosa
        await gettingMediaData();
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
          "Cambio a default",
          "Cambio a default exitoso."
        );
      } else {
        // Fallo en la solicitud
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          "Cambio a default",
          "Error en el cambio de estado a default."
        );
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };*/

  const idFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"ID"}
        placeholder={"Buscar ID"}
        filterMatch={"contains"}
        className="custom-place"
      />
    );
  };

  const nameFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"NAME"}
        placeholder={"Buscar por nombre"}
        filterMatch={"contains"}
        className="custom-place"
      />
    );
  };

  const mediaTypeFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={mediaTableRef}
        options={mediaTypeCollection}
        field={"FILE_TYPE"}
        placeholder="Todos"
        selectedItemsLabel="{0} tipos de media"
        display="chip"
        className="custom-multi-select-filter"
      />
    );
  };

  const campaniaEstatusFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={mediaTableRef}
        options={estatusCollection}
        field={"STATUS"}
        placeholder="Todos"
        selectedItemsLabel="{0} estatus"
        display="chip"
        className="custom-multi-select-filter"
      />
    );
  };

  const createdAtFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={mediaTableRef}
        field={"PERIOD"}
        placeholder={"Buscar por fecha"}
        matchFilter="custom"
        readOnlyInput
        onClearButtonClick={handleClearButtonClick}
      />
    );
  };

  const renderedFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"RENDERED"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const clicksFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"CLICKS"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

    
 
  const branchesActiveFilterElement = () => {

    
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"CTR"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={100}
        matchFilter="custom"

      />
    );
  };

  // Template para la vista previa de la imagen
  const previewBodyTemplate = (data) => {
    if (data.URL) {
      return (
        <div
          className="image-container"
          onClick={(e) => {
            e.stopPropagation(); // Detener propagación para que no se dispare onRowSelect
            handleImageClick(data);
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={data.URL}
            alt={data.NAME}
            style={{
              cursor: "pointer",
              height: "60px",
              width: "60px",
              objectFit: "cover",
              borderRadius: "3px",
              transition: "all 0.3s ease-in-out",
            }}
            onClick={() => handleImageClick(data)} // Solo se activa si hay URL
          />
          <div className="image-overlay">
            <i className="pi pi-eye eye-icon"></i>
          </div>
        </div>
      );
    } else {
      // Si no hay URL, simplemente devuelve vacío
      return <span></span>;
    }
  };

  const clicBodyTemplate = (data) => {
    return (
      <>
        <span className="p-column-title">Clics</span>
        {data.FILE_TYPE_ID === MEDIA_CONSTS.TYPE.VIDEO.ID ? "-" : data.CLICKS}
      </>
    );
  };

  // Template para los items de la galería de ocuparse
  const itemTemplate = (item) => {
    return (
      <div style={{ position: "relative", textAlign: "center" }}>
        <img src={item.source} alt={item.title} style={{ width: "100%" }} />
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          {item.title}
        </div>
      </div>
    );
  };

  // Template para las miniaturas de ocuparse
  const thumbnailTemplate = (item) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{ display: "block" }} />
    );
  };

 // Función para obtener el color del círculo según el estatus
const getStatusColor = (status) => {
  switch (status) {
    case "Activa":
      return "#44A143";
    case "Pausada":
      return "#FFC700";
    case "Finalizada":
      return "#6C757D";
    case "Programada":
      return "#F56B1D";
    case "Incompleta":
      return "#E04460";
    default:
      return "transparent"; // Por si acaso hay un estatus no reconocido
  }
};

// Función de plantilla para mostrar el círculo y el texto de estatus
const statusTemplate = (rowData) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {}
      <span
        style={{
          display: "inline-block",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(rowData.STATUS),
          marginRight: "8px"
        }}
      ></span>
      {}
      <span>{rowData.STATUS}</span>
    </div>
  );
};

/*// Función para obtener el color personalizado según el estatus
const getStatusTagStyle = (status) => {
  switch (status) {
    case "Activo":
      return { background: "#44A143", color: "white" };
    case "Pausado":
      return { background: "#FFC700", color: "black" };
    case "Finalizado":
      return { background: "#6C757D", color: "white" };
    case "Programado":
      return { background: "#F56B1D", color: "white" };
    case "Incompleta":
      return { background: "#E04460", color: "white" };
    default:
      return { background: "transparent", color: "black" };
  }
};

// Función de plantilla para mostrar el Tag con el estatus
const statusTemplate = (rowData) => {
  return (
    <Tag
      style={getStatusTagStyle(rowData.STATUS)} // Aplicar estilo personalizado según el estatus
      value={rowData.STATUS} 
      rounded// Mostrar el estatus dentro del Tag
    />
  );
};*/


  // Construcción del menú de acciones de cada registro
  const ActionsColumn = ({ rowData, index }) => {
    const menu = useRef(null);

    // Función para redirigir a la página de edición
    const handleEdit = (rowData) => {

      history.push(
        `/campanias/edit-campania/${rowData.ID_CAMPAIGN}/${rowData.ID}`
      );
    
    };

    const handleConsult = (rowData) => {
      history.push(
        `/campanias/view/${rowData.ID_CAMPAIGN}/${rowData.ID}`
      );
    };
  
     // Pausar campaña
  const handlePause = async (rowData) => {
    //setDefaultAdModalOpen(true);
    setRowData(rowData); // Almacena los datos de la fila seleccionada
    setDialogVisible(true); // Abre el diálogo
    /*try {
      const response = await updateCampaignStatus(rowData.ID_CAMPAIGN, 2);
      if (response.success) {
        // Actualiza la tabla después de que la solicitud sea exitosa
        await gettingMediaData();
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
         "Estatus",
         "Se pausó la campaña."
        );
      } else {
        // Fallo en la solicitud
        toast.setMessage(
          CONST.SEVERITY.ERROR,
           "Estatus",
          "Error en el cambio de estado"
        );
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }*/
  };

  
  
  const handleReactivate = async (rowData) => {
    setRowData(rowData); // Almacena los datos de la fila seleccionada
    setDialogVisibleR(true);
    /*try {
      const response = await updateCampaignStatus(rowData.ID_CAMPAIGN, 1);
      if (response.success) {
        // Actualiza la tabla después de que la solicitud sea exitosa
        await gettingMediaData();
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
          "Estatus",
          "Se reactivó la campaña."
        );
      } else {
        // Fallo en la solicitud
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          "Estatus",
          "Error en el cambio de estado"
        );
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }*/
  };


    // Función que genera los items del menú según el status
    const getMenuItems = () => {
      let items = [];
    
      // Primero, evalúa el estado
      switch (rowData.STATUS) {
        case "Activa":
          items = [
            { label: "Consultar campaña", command: () => handleConsult(rowData) },
            { label: "Editar campaña", command: () => handleEdit(rowData) },
            { label: "Pausar campaña", command: () => handlePause(rowData) },
          ];
          break;
    
        case "Pausada":
          items = [
            { label: "Consultar campaña", command: () => handleConsult(rowData) },
            { label: "Editar campaña", command: () => handleEdit(rowData) },
            { label: "Reactivar campaña", command: () => handleReactivate(rowData) },
          ];
          break;
    
        case "Finalizada":
          items = [
            { label: "Consultar campaña", command: () => handleConsult(rowData) },
          ];
          break;
    
        case "Programada":
            items = [
              { label: "Consultar campaña", command: () => handleConsult(rowData) },
              { label: "Editar campaña", command: () => handleEdit(rowData) },
            ];
            break;

        case "Incompleta":
          items = [
            { label: "Consultar campaña", command: () => handleConsult(rowData) },
            { label: "Editar campaña", command: () => handleEdit(rowData) },
          ];
          break;

        default:
          break;
      }
    
      // Luego, evalúa IS_DEFAULT
      if (rowData.IS_DEFAULT === 1) {
        items = [
          { label: "Consultar campaña", command: () => handleConsult(rowData) },
          { label: "Editar campaña", command: () => handleEdit(rowData) },
        ];
      }
    
      return items;
    };

  const items = getMenuItems();

    return (
      <div
        className="actions-column"
        onClick={(event) => {
          event.stopPropagation(); // Esto evita que se dispare onRowSelect
          menu.current.toggle(event);
        }}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
        <Menu model={items} popup ref={menu} id={`menu_${index}`} />
      </div>
    );
  };

  // Acción para el renglón seleccionado en todas las columnas a excepción de las acciones o las imagenes
  const onSelectRow = (data) => {
    //history.push(`/administracion-media/estadisticas/${data.ID_CAMPAIGN}`);
    history.push(
      `/campanias/view/${data.ID_CAMPAIGN}/${data.ID}`
    );
  };


  /*<!--<Badge
            value="Default"
            style={{ fontSize: "11px", padding: "0 4px", marginRight: "5px" }}
          />
          <NeoTooltip_icon
             content="Campaña default: Los anuncios de esta campaña se mostrarán cuando no haya otros disponibles. No tiene vigencia ni sucursales."
             extra="inline-tooltip"
             position="right"
             data-pr-html="true"
          />*/
   const handleCreateCampaign = () => {
            history.push('/campanias/nueva-campania');
          };   


 const emptyContent = () => {
            if (isFiltered) {
                return <span>No hay ninguna campaña.</span>;
            }
            return (
              <div style={{ textAlign: 'center' }}>
                <p style={{ fontSize:'20px', fontWeight: 'bold', margin: '0' }}>Sin campañas</p>
                <p style={{ fontSize: '16px', margin: '5px 0 25px', display: 'inline-flex', alignItems: 'center' }}>
    Para empezar, configura tu campaña default 
    <NeoTooltip_icon
      content="Campaña default: Los anuncios de esta campaña se mostrarán cuando no haya otros disponibles. No tiene vigencia ni sucursales."
      extra="inline-tooltip-create-campanias"
      position="right"
      style={{ marginLeft: '5px' }} // Opcional para espaciar un poco el ícono
    />
  </p><div style={{ marginTop: '0px' }}>
                <NeoButtonMain
                  label="Configurar campaña default"
                  icon="pi pi-plus"
                  onClick={handleCreateCampaign}
                />
                </div>
              </div>
            );
          };

    const onFilter = (event) => {
      let _lazyParams = { ...lazyParams, ...event };
      _lazyParams["first"] = 0;
      setLazyParams(_lazyParams);
      setIsFiltered(Object.keys(event.filters).length > 0);
    };


    const onConfirm = async () => {
      // Lógica para pausar la campaña
      try {
        const response = await updateCampaignStatus(rowData.ID_CAMPAIGN, 2);
        if (response.success) {
          await gettingMediaData(); // Refresca la tabla después de la pausa
          toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            "Estatus",
            "Se pausó la campaña."
          );
        } else {
          toast.setMessage(
            CONST.SEVERITY.ERROR,
            "Estatus",
            "Error en el cambio de estado"
          );
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          "Estatus",
          "Error en la solicitud"
        );
      } finally {
        setDialogVisible(false); // Cierra el diálogo al finalizar
      }
    };

    const onConfirmR = async () => {
      // Lógica para pausar la campaña
      try {
        const response = await updateCampaignStatus(rowData.ID_CAMPAIGN, 1);
        if (response.success) {
          await gettingMediaData(); // Refresca la tabla después de la pausa
          toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            "Estatus",
            "Se reactivó la campaña."
          );
        } else {
          toast.setMessage(
            CONST.SEVERITY.ERROR,
            "Estatus",
            "Error en el cambio de estado"
          );
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          "Estatus",
          "Error en la solicitud"
        );
      } finally {
        setDialogVisibleR(false); // Cierra el diálogo al finalizar
      }
    };
  
    const onCancel = () => {
      setDialogVisible(false); // Solo cierra el diálogo sin hacer nada
      setDialogVisibleR(false)
    };

    const TooltipContent = () => (
      <div>
          <p><b>Default:</b> campaña default</p>
          <p>la misma campaña.</p>
      </div>
  );

  return (
    <>
    <div className="custom-table-page">
      <NeoTable
       
        emptyMessage={emptyContent()}
        ref={mediaTableRef}
        value={mediaCollection}
        loading={loading}
        removableSort
        dataKey="ID_CAMPAIGN"
        paginator
        rows={7}
        onRowSelect={(e) => onSelectRow(e.data)}
        selectionMode={"single"}
        tableStyle={{ minWidth: '50rem' }}
        scrollable
        onFilter={onFilter}
        filters={lazyParams.filters}
       >
       <NeoTableColumn
          field="ID"
          header="ID"
          placeholder="Identificador de campaña"
          sortable
          filter
          filterElement={idFilterElement()}
          style={{ width: "10%", textAlign: "center" }}
        />
       <NeoTableColumn
          field="NAME"
          header="Nombre"
          placeholder="Nombre de multimedia"
          sortable
          filter
          filterElement={nameFilterElement()}
          body={(rowData) => (
            <>
              <div>{rowData.NAME}</div>
          
            </>
          )}
          style={{ width: "15%", textAlign: "left" }}
        />

        <NeoTableColumn
          field="PERIOD"
          header="Periodo de vigencia"
          sortable
          filter
          filterElement={createdAtFilterElement()}
          filterFunction={filterRangeDate}
          filterMatchMode="custom"
          body={(rowData) => (
            <>
            <div style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}>
                          <div>{rowData.PERIOD 
                            ? rowData.PERIOD 
                            : (rowData.IS_DEFAULT ? "Permanente" : "Sin vigencia definida")
                          }</div>
              {rowData.IS_DEFAULT === 1 && (
                <div style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}>
                  <Tag style={{ background: '#2196F3', borderRadius: '8px' }}>
                    <span className="text-default">Default</span>
                    <NeoTooltip_icon
                      content="Campaña default: Los anuncios de esta campaña se mostrarán cuando no haya otros disponibles. No tiene vigencia ni sucursales."
                      extra="inline-tooltip-campanias"
                      position="right"
                    />
                  </Tag>
                </div>
              )}
              </div>
            </>
          )}
          className="centered-column"
          style={{ width: "17%", textAlign: "left", verticalAlign: "middle"  }}
      />
        <NeoTableColumn
          field="RENDERED"
          header="Impresiones"
          sortable
          filter
          filterElement={renderedFilterElement()}
          filterFunction={filterRangeNumberComa}
          filterMatchMode="custom"
          style={{ width: "14%" }}
        />
        <NeoTableColumn
          field="CLICKS"
          header="Clics"
          sortable
          filter
          filterElement={clicksFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
  
          style={{ width: "14%" }}
        />
        <NeoTableColumn
          field="CTR"
          header="CTR"
          sortable
          filter
          filterElement={branchesActiveFilterElement()}
          filterFunction={filterRangeNumberPorcent}
          filterMatchMode="custom"
    
          style={{ width: "12%" }}
        />
        <NeoTableColumn
          field="STATUS"
          header="Estatus"
          sortable
          filter
          filterElement={campaniaEstatusFilterElement()}
          style={{ width: "10%", textAlign: "left" }} // Alinear a la izquierda
          body={statusTemplate} 
        />


        <NeoTableColumn
          field="actions"
          header=""
          body={(rowData, index) => (
            <ActionsColumn rowData={rowData} index={index} />
          )}
          style={{ width: "2%" }}
        />
      </NeoTable>
      </div>
      
      <NeoDialog
        header="Pausar campaña"
        custom="custom-modal-header"
        visible={dialogVisible}
        closable={false}
        dismissableMask={true}
        onHide={onCancel} // Cierra el diálogo al hacer clic fuera de él
        resizable={false}
      >
        <div className="p-d-flex p-flex-column gap-17">
          <p className="m-0 fs-14 text-black">
          Si realizas esta acción, la campaña y sus anuncios dejarán de mostrarse. ¿Deseas pausarla de todos modos?
          </p>

          

          <div className="p-d-flex p-jc-end gap-8">
            <NeoButtonMain alternative="outlined" onClick={onCancel}>
              Cancelar
            </NeoButtonMain>
            <NeoButtonMain onClick={onConfirm}>
              Continuar
            </NeoButtonMain>
          </div>
        </div>
      </NeoDialog>

      <NeoDialog
        header="Reactivar campaña"
        custom="custom-modal-header"
        visible={dialogVisibleR}
        closable={false}
        dismissableMask={true}
        onHide={onCancel} // Cierra el diálogo al hacer clic fuera de él
        resizable={false}
      >
        <div className="p-d-flex p-flex-column gap-17">
          <p className="m-0 fs-14 text-black">
          Si reactivas esta campaña, se volverán a mostrar sus anuncios a tus clientes. ¿Estás seguro de que deseas reactivarla?
          </p>

          

          <div className="p-d-flex p-jc-end gap-8">
            <NeoButtonMain alternative="outlined" onClick={onCancel}>
              Cancelar
            </NeoButtonMain>
            <NeoButtonMain onClick={onConfirmR}>
              Continuar
            </NeoButtonMain>
          </div>
        </div>
      </NeoDialog>

     
    </>
  );
}
