import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base.js";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base.js";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base.js";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base.js";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base.js";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base.js";
import {
  getMediaInfoList,
  updateToDefaultStatus,
  getAdDataByCampaign,
} from "../../../service/Media.service.js";
import {getStatsCampaignByStore} from "../../../service/Campaign.service.js";
import { filterRangeNumber, filterRangeNumberComa, filterRangeNumberPorcent  } from "../../../utils/commons.util.js";
import {
  dateFormat,
  filterDate,
  filterRangeDate,
} from "../../../utils/date.utils.js";
import * as MEDIA_CONSTS from "../../../consts/media.consts.js";
import { CONST } from "../../../consts/consts.js";
import useToastContext from "../../../hooks/useToastContext.hook.js";
// Componentes nuevos
import { Galleria } from "primereact/galleria";
import { Badge } from "primereact/badge";
import { Menu } from "primereact/menu";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base.js";
//import './styles.css';

export default function CampaignResultsTable({ campaignId, handleCreateAd  }) {
  const mediaTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [mediaCollection, setMediaCollection] = useState([]); // Inicializado arreglo de datos de medios
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);
  const [images, setImages] = useState([]); // Almacena las imágenes de la galería
  const toast = useToastContext(); // Manejo del mensaje del default
  const [selectedRow, setSelectedRow] = useState(null); 
  const [isFiltered, setIsFiltered] = useState(false);
  const [filters, setFilters] = useState({});
  const ROWS_COUNT = 10;
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: ROWS_COUNT,
    page: 1,
  });

  // Arreglo para mostrar en el filtro
  const mediaTypeCollection = [
    /* { label: "Video", value: "Video" },
    { label: "Imagen", value: "Imagen" },
    { label: "HTML", value: "HTML" },*/
    { label: "Imagen dinámica", value: "Imagen dinámica" },
    { label: "Imagen estática", value: "Imagen estática" },
  ];

  // Obtener la información de los medios
  const gettingAdDataByCampaign = async () => {
    setLoading(true);
    const rawMedia = await getStatsCampaignByStore(campaignId);
    setMediaCollection(rawMedia.result);
   
  
    setLoading(false);
  };


  

  useEffect(() => {
    let mount = true;

    if (mount) {
      gettingAdDataByCampaign();
    }
    return () => {
      mount = false;
    };
  }, []);

  // Manejo del clic para mostrar la galería de imágenes
  const handleImageClick = (data) => {
    const galleryImages = [];

    if (data.URL) {
      galleryImages.push({
        source: data.URL, // Imagen de Escritorio
        thumbnail: data.URL,
        title: "Archivo para computadora de escritorio",
      });
    }
    if (data.URL_ANDROID) {
      galleryImages.push({
        source: data.URL_ANDROID, // Imagen Android
        thumbnail: data.URL_ANDROID,
        title: data.URL_IOS ? " Archivo para Android" : "Archivo para Android / iOS",
      });
    }
    if (data.URL_IOS) {
      galleryImages.push({
        source: data.URL_IOS, // Imagen iOS
        thumbnail: data.URL_IOS,
        title: data.URL_ANDROID ? "Archivo para iOS" : "Archivo para Android / iOS",
      });
    }
    setImages(galleryImages);
    setActiveIndex(0);
  };

  // Efecto para abrir la galería de imágenes
  useEffect(() => {
    if (
      images.length > 0 &&
      galleria.current &&
      typeof galleria.current.show === "function"
    ) {
      setTimeout(() => {
        galleria.current.show();
      }, 100);
    }
  }, [images, galleria]);

  // Perder el foco del control de fecha al limpiar para que vuelva a mostrar el calendario
  const handleClearButtonClick = (e) => {
    setTimeout(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, 0);
  };

  // Convertir en default
  const handleDefault = async (rowData) => {
    try {
      const response = await updateToDefaultStatus(rowData.ID_MEDIA, 1);
      if (response.success) {
        // Actualiza la tabla después de que la solicitud sea exitosa
        await gettingAdDataByCampaign();
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
          "Cambio a default",
          "Cambio a default exitoso."
        );
      } else {
        // Fallo en la solicitud
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          "Cambio a default",
          "Error en el cambio de estado a default."
        );
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };


  useEffect(() => {
    setIsFiltered(Object.keys(filters).length > 0);
  }, [filters]);


  const nameFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"STORE_NAME"}
        placeholder={"Buscar por nombre"}
        filterMatch={"contains"}
        className="custom-place"

      />
    );
  };

  const mediaTypeFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={mediaTableRef}
        options={mediaTypeCollection}
        field={"FILE_TYPE"}
        placeholder="Todos"
        selectedItemsLabel="{0} tipos de media"
        display="chip"
        className="custom-multi-select-filter"
      />
    );
  };

  const createdAtFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={mediaTableRef}
        field={"PERIOD"}
        placeholder={"Buscar por fecha"}
        matchFilter="custom"
        readOnlyInput
        onClearButtonClick={handleClearButtonClick}
      />
    );
  };

  const renderedFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"RENDERED"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const clicksFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"CLICKS"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={100}
        matchFilter="custom"
      />
    );
  };

  const branchesActiveFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"CTR"}
        minPlaceholder={"Mín."}
        maxPlaceholder={"Máx."}
        minDigit={0}
        maxDigit={100}
        matchFilter="custom"
      />
    );
  };

  const locationCollection = [
    { label: "CDMX", value: "CDMX" },
  
 ];

 const locationFilterElement = () => {
  return (
    <NeoMultiSelectFilter
      ref={mediaTableRef}
      options={locationCollection}
      field={"STORE_LOCATION"}
      placeholder="Todas"
      selectedItemsLabel="{0} ubicaciones"
      display="chip"
      className="custom-multi-select-filter"
    />
  );
};
  // Template para la vista previa de la imagen
  const previewBodyTemplate = (data) => {
    if (data.URL) {
      return (
        <div
          className="image-container"
          onClick={(e) => {
            e.stopPropagation(); // Detener propagación para que no se dispare onRowSelect
            handleImageClick(data);
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={data.URL}
            alt={data.NAME}
            style={{
              cursor: "pointer",
              height: "60px",
              width: "60px",
              objectFit: "cover",
              borderRadius: "3px",
              transition: "all 0.3s ease-in-out",
            }}
            onClick={() => handleImageClick(data)} // Solo se activa si hay URL
          />
          <div className="image-overlay">
            <i className="pi pi-eye eye-icon"></i>
          </div>
        </div>
      );
    } else {
      // Si no hay URL, simplemente devuelve vacío
      return <span></span>;
    }
  };

  const clicBodyTemplate = (data) => {
    return (
      <>
        <span className="p-column-title">Clics</span>
        {data.FILE_TYPE_ID === MEDIA_CONSTS.TYPE.VIDEO.ID ? "-" : data.CLICKS}
      </>
    );
  };

  // Template para los items de la galería de ocuparse
  const itemTemplate = (item) => {
    return (
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img src={item.source} alt={item.title} style={{ width: "100%" }} />
        <div
          style={{
           
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            marginTop: "10px", 
            display: "inline-block",
          }}
        >
          {item.title}
        </div>
      </div>
    );
  };


  // Función de plantilla para mostrar el círculo y el texto de estatus
const statusTemplate = (rowData) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {}
      <span
        style={{
          display: "inline-block",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(rowData.STATUS),
          marginRight: "8px"
        }}
      ></span>
      {}
      <span>{rowData.STATUS}</span>
    </div>
  );
};


// Función para obtener el color del círculo según el estatus
const getStatusColor = (status) => {
  switch (status) {
    case "Activo":
      return "#44A143";
    case "Pausado":
      return "#FFC700";
    case "Finalizado":
      return "#6C757D";
    case "Programado":
      return "#F56B1D";
    case "Incompleta":
      return "#E04460";
    default:
      return "transparent"; // Por si acaso hay un estatus no reconocido
  }
};
  // Template para las miniaturas de ocuparse
  const thumbnailTemplate = (item) => {
    return (
      <img src={item.thumbnail} alt={item.title} style={{ display: "block" }} />
    );
  };

  // Construcción del menú de acciones de cada registro
  const ActionsColumn = ({ rowData, index }) => {
    const menu = useRef(null);

    // Función para redirigir a la página de edición
    const handleEdit = (rowData) => {
      history.push(
        `/administracion-media/modificacion/${rowData.ID_MEDIA}`
      );
    };

    const handlePause = (rowData) => {
      history.push(
        `/administracion-media/modificacion/${rowData.ID_MEDIA}`
      );
    };

    const handleReactivate = (rowData) => {
      history.push(
        `/administracion-media/modificacion/${rowData.ID_MEDIA}`
      );
    };
    
    let items = [];
    switch (rowData.STATUS) {
      case "Activo":
        items = [
        
          { label: "Editar anuncio", command: () => handleEdit(rowData) },
          { label: "Pausar anuncio", command: () => handlePause(rowData) },
        ];
        break;
  
      case "Pausado":
        items = [
          { label: "Editar anuncio", command: () => handleEdit(rowData) },
          { label: "Reactivar anuncio", command: () => handleReactivate(rowData) },
        ];
        break;
  

      default:
        break;
    }
    // Elementos del menú
    /*const items = [
      {
        label: "Convertir en default",
        disabled: rowData.IS_DEFAULT == 1,
        command: () => handleDefault(rowData),
      },
      {
        label: "Editar anuncio",
        command: () => handleEdit(rowData),
      },
    ];*/

    return (
      <div
        className="actions-column"
        onClick={(event) => {
          event.stopPropagation(); // Esto evita que se dispare onRowSelect
          menu.current.toggle(event);
        }}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
        <Menu model={items} popup ref={menu} id={`menu_${index}`} />
      </div>
    );
  };

  // Acción para el renglón seleccionado en todas las columnas a excepción de las acciones o las imagenes
  const onSelectRow = (data) => {
    //history.push(`/administracion-media/estadisticas/${data.ID_MEDIA}`);
  };

  const rowClassName = (rowData) => {
    return rowData.ID_MEDIA == selectedRow ? "selected-row" : ""; // Aplica clase si es la fila seleccionada
  };

  const rowClass = (data) => {
    return {
        'bg-primary': data.category === 'Fitness'
    };
};

const emptyContent = () => {
  if (isFiltered) {
      return <span>No existen sucursales</span>;
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <p style={{ fontSize:'20px', fontWeight: 'bold', margin: '0' }}>Sin sucursales asignadas</p>
    </div>
  );
};


const onFilter = (event) => {
  let _lazyParams = { ...lazyParams, ...event };
  _lazyParams["first"] = 0;
  setLazyParams(_lazyParams);
  setIsFiltered(Object.keys(event.filters).length > 0);
};

function headerTemplate(data) {
  return (
    <>
      <p className="store-row-group" style={{ fontWeight: 'bold', color: '#194893' }}>
        Asignaciones actuales
      </p>
    </>
  );
}

const footerTemplate = (group) => {
  // Aquí puedes incluir información adicional si es necesario
  //return <div>Total: {group.items.length} items</div>;
};

function getGroupingProps() {
  
  return {
    rowGroupMode: "subheader",
    groupField: "LOCATION",
    rowGroupHeaderTemplate: headerTemplate,
    rowGroupFooterTemplate: () => null,
  };
}


const ctrBodyTemplate = (data) => {
  // Si CTR ya viene como cadena con %, la devuelve; si no, agrega '%' al número
  return typeof data.CTR === "string" ? data.CTR : `${data.CTR}%`;
};
  return (
    <>
   
      <NeoTable
        emptyMessage={emptyContent()}
        ref={mediaTableRef}
        value={mediaCollection}
        loading={loading}
        removableSort
        dataKey="STORE_ID"
        paginator
        rows={5}
        onRowSelect={(e) => onSelectRow(e.data)}
        selectionMode={"single"}
        rowClassName={rowClassName} 
        onFilter={onFilter}
        filters={lazyParams.filters}
        {...getGroupingProps()}
      >
    

        <NeoTableColumn
          field="STORE_NAME"
          header="Sucursal"
          placeholder="Nombre de sucursal"
          sortable
          filter
          filterElement={nameFilterElement()}
         
          style={{ width: "15%", textAlign: "left" }}
        />

        <NeoTableColumn
          field="STORE_LOCATION"
          header="Ubicación"
          placeholder="Ubicación"
          sortable
          filter
          filterElement={locationFilterElement()}
          
          style={{ width: "15%", textAlign: "left" }}
        />
    
       
        <NeoTableColumn
          field="RENDERED"
          header="Impresiones"
          sortable
          filter
          filterElement={renderedFilterElement()}
          filterFunction={filterRangeNumberComa}
          filterMatchMode="custom"
          style={{ width: "14%" }}
        />
        <NeoTableColumn
          field="CLICKS"
          header="Clics"
          sortable
          filter
          filterElement={clicksFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
          body={(e) => clicBodyTemplate(e)}
          style={{ width: "14%" }}
        />
     <NeoTableColumn
          field="CTR"
          header="CTR"
          sortable
          filter
          filterElement={branchesActiveFilterElement()}
          filterFunction={filterRangeNumberPorcent}
          filterMatchMode="custom"
          body={(rowData) => rowData.CTR || 0}
          style={{ width: "14%" }}
        />
     
      
      </NeoTable>
    </>
  );
}
