import NeoGridContainer from "../../../design/design_components/neo/layout/NeoGridContainer.base";
import Icono from "design/assets/img/wfi/icons/icon-ad.svg";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import AudienceRuleManager from "../components/rules/AudienceRuleManager.component";
import NeoTooltip_icon from "design/design_components/neo/overlay/NeoTooltip_icon.base";
import { useRef, useState } from "react";
import { RulesContext } from "../contexts/RulesContext.context";
import * as AudienceRuleTypes from "../../../models/audience-rule.model";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import AudienceContactCounter from "../components/AudienceContactCounter.component";
import useNextTick from "hooks/useNextTick";
import ValidationError from "../components/ValidationError.component";
import useToastContext from "hooks/useToastContext.hook";

/** 
 * @type {{
 *  NAME?: string,
 *  RULES?: string,
 *  DESCRIPTION?: string,
 * }} 
*/
const defaultErrors = {};

/**
 * 
 * @param {{
 *  audienceInfo: {
 *      NAME: string,
 *      DESCRIPTION?: string,
 *      RULES: AudienceRuleTypes.AudienceRuleModel[]
 *  },
 *  onSaveAudience: (audience: AudienceRuleTypes.CreateAudienceModel|AudienceRuleTypes.UpdateAudienceModel) => Promise<any>,
 *  saveButtonText: string
 * }} param0 
 * @returns 
 */
export default function ManageAudience({ audienceInfo, onSaveAudience, saveButtonText }) {
    /** @type {React.MutableRefObject<import("../components/rules/AudienceRuleManager.component").AudienceRuleManagerHandle>} */
    const ruleManagerRef = useRef(null);
    /** @type {React.MutableRefObject<import("../components/AudienceContactCounter.component").AudienceContactCounterHandle>} */
    const contactCounterRef = useRef(null);
    const toast = useToastContext();
    const { nextTick } = useNextTick();    

    const [testingAudience, setTestingAudience] = useState(false);
    const [status, setStatus] = useState('');
    const [audience, setAudience] = useState({ NAME: audienceInfo.NAME, DESCRIPTION: audienceInfo.DESCRIPTION });
    const [rules, setRules] = useState(audienceInfo.RULES);
    const [errors, setErrors] = useState(defaultErrors);
    const [audienceRulesAreValid, setAudienceRulesAreValid] = useState(audienceInfo.RULES?.length > 0);

    const isSaving = status === 'saving';

    /**
     * @param {{ silent: boolean }} param0
     * @returns 
     */
    function validateRules({ silent = false }) {
        if (! silent) return ruleManagerRef.current.validate();

        return ruleManagerRef.current.silentValidate();
    }

    /**
     * @param {AudienceRuleTypes.AudienceRuleType} type
     * @returns {AudienceRuleTypes.AudienceRuleModel}
     */
    function createRule(type) {
        return type === 'single' ? {
            ID: crypto.randomUUID(),
            TYPE: type,
            OPERATOR_ID: 1,
            VALUE: null,
        } : {
            ID: crypto.randomUUID(),
            TYPE: type,
            VALUE: null,
            SUB_RULES: [
                createRule('single'),
                createRule('single'),
            ],
        }
    }

    /**
     * @param {AudienceRuleTypes.AudienceRuleModel} rule 
     */
    function addRule(rule) {
        const newRules = [...rules, rule];
        setRules(newRules);
        setAudienceRulesAreValid(false);
        validateAudience(audience, newRules, { only: 'RULES' });
    }

    /**
     * @param {AudienceRuleTypes.AudienceRuleModel} rule 
     */
    function removeRule(rule) {
        const newRules = rules.filter(r => r.ID !== rule.ID);
        setRules(newRules);
        nextTick(() => setAudienceRulesAreValid(validateRules({ silent: true }) && newRules.length > 0));
    }

    /**
     * @param {AudienceRuleTypes.AudienceRuleModel} oldRule 
     * @param {AudienceRuleTypes.AudienceRuleModel} newRule 
     */
    function updateRule(oldRule, newRule) {
        setRules(rules.map(r => r.ID === oldRule.ID ? newRule : r));
        nextTick(() => setAudienceRulesAreValid(validateRules({ silent: true })));
    }

    function validateAudience(audience, rules, config) {
        const { only } = config || {};
        const newErrors = {};
        const shouldValidateField = (field) => (!only || only === field); 

        newErrors.NAME = shouldValidateField('NAME')  
            ? ( !audience.NAME ? 'Este campo es obligatorio' : null)
            : errors.NAME;

        newErrors.RULES = shouldValidateField('RULES') 
            ? ( rules.length === 0 ? 'La audiencia debe tener al menos una regla' : null)
            : errors.RULES;

        setErrors({ ...errors, ...newErrors });

        return Object.values(newErrors).every(error => !error);
    }

    async function handleTestAudience() {
        setTestingAudience(true);
        await contactCounterRef.current.updateContacts();
        setTestingAudience(false);
    }

    /**
     * @param {React.FormEvent<HTMLInputElement>} event 
     */
    function handleUpdateName(event) {
        const target = /** @type {HTMLInputElement} */ (event.target);
        const newAudience = { ...audience, NAME: target.value };
        setAudience(newAudience);
        validateAudience(newAudience, rules, { only: 'NAME' });
    }

    async function handleSaveAudience() {
        const audienceIsValid = validateAudience(audience, rules);
        const rulesAreValid = validateRules({ silent: false });

        console.log({
            audienceIsValid,
            rulesAreValid,
        })

        if (! audienceIsValid || ! rulesAreValid) return;

        setStatus('saving');

        await onSaveAudience({ ...audience, RULES: rules });

        setStatus('');
    }

    function reset() {
        setStatus('');
        setAudience({ NAME: '', DESCRIPTION: '' });
        setRules([]);
        setAudienceRulesAreValid(false);
    }

    return (
        <>
            <div className="p-d-flex p-flex-column gap-16 w-full">
                <NeoCard custom="custom-padding-card m-0 p-d-flex p-flex-column">
                    <div className="p-col p-d-flex p-flex-column gap-32 pb-0">
                        <h2 className="fs-22 bold text-primary p-m-0 p-p-0">Información general</h2>
                        <div className="p-d-flex gap-16 pb-14">
                            <NeoInputText
                                label="Nombre"
                                extra="p-0 m-0" 
                                value={audience.NAME}
                                onChange={handleUpdateName}
                                error={errors.NAME}
                            />
                            <NeoInputText
                                label="Descripción (opcional)"
                                extra="p-0 m-0" 
                                value={audience.DESCRIPTION}
                                onChange={e => setAudience({ ...audience, DESCRIPTION: e.target.value })}
                            />
                            <div style={{ marginTop: '-20px' }}>
                                <AudienceContactCounter rules={rules} ref={contactCounterRef} />
                            </div>
                        </div>
                    </div>
                </NeoCard>


                <NeoCard custom="custom-padding-card m-0">
                    <div className="p-col p-d-flex p-flex-column gap-21 pb-0">
                        <h2 className="fs-22 bold text-primary p-m-0 p-p-0">
                            Reglas y criterios <NeoTooltip_icon position="bottom" extra="inline-tooltip" text="Lógica condicional: Todos los criterios agregados son de tipo “AND”, solo las opciones dentro del criterio múltiple funcionan como tipo “OR”. Tipos de criterios: Único: El contacto debe cumplir con todos los valores específicos definidos en el criterio. Múltiple: Este criterio habilita un bloque de criterios opcionales, si el contacto coincide con al menos uno, se incluye." />
                        </h2>
                    
                        <RulesContext.Provider value={{ rules, addRule, createRule, removeRule, updateRule }} >
                            <AudienceRuleManager ref={ruleManagerRef} emptyRulesMessage={emptyRulesMessage()} />
                        </RulesContext.Provider>

                        <ValidationError error={errors.RULES} />
                    </div>
                </NeoCard>
            </div>

            <div className="p-d-flex p-jc-end gap-16 mt-16">
                <NeoButtonMain alternative="outlined" onClick={handleTestAudience} disabled={!audienceRulesAreValid || testingAudience}>
                    Probar
                    {testingAudience && <i className="pi pi-spin pi-spinner ml-5"></i>}
                </NeoButtonMain>
                <NeoButtonMain onClick={handleSaveAudience} disabled={isSaving}>
                    {saveButtonText}
                    {isSaving && <i className="pi pi-spin pi-spinner ml-5"></i>}
                </NeoButtonMain>
            </div>
        </>
    );
}

function emptyRulesMessage () {
    return (
        <div className="p-d-flex p-flex-column p-ai-center p-jc-center gap-8">
            <h2 className="fs-20 fw-600 text-black">Define tu audiencia</h2>
            <span className="text-black fs-14 p-text-center" style={{ maxWidth: '402px' }}>Especifica los criterios y valores necesarios para que los contactos sean incluidos en tu audiencia.</span>
        </div>
    );
}