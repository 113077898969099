import { useHistory } from "react-router-dom";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import Icono from "../../design/assets/img/wfi/icons/icon-audience.svg";
import NewAudienceButton from "./components/NewAudienceButton.component";
import AudienceTable from "./components/AudienceTable.component";
import { useEffect, useState } from "react";
import * as AudienceTypes from "../../models/audience-rule.model"
import { getAllAudiences, getAudienceContacts } from "service/Audience.service";
import DisableAudienceModal from "./components/DisableAudienceModal.component";
import DeleteAudienceModal from "./components/DeleteAudienceModal.component";

/** @type {AudienceTypes.ListAudienceModel[]} */
const initialAudiences = [];

/** @type {AudienceTypes.ListAudienceModel} */ // @ts-ignore
const initialSelectedAudience = {};

export default function AudienceList() {
    const [audiences, setAudiences] = useState(initialAudiences);
    const [isLoading, setIsLoading] = useState(false);

    const [isDisableAudienceModalOpen, setIsDisableAudienceModalOpen] = useState(false);
    const [isDeleteAudienceModalOpen, setIsDeleteAudienceModalOpen] = useState(false);
    const [selectedAudience, setSelectedAudience] = useState(initialSelectedAudience);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            fetchAudiences();
        }

        return () => {
            isMounted = false;
        };
    }, []);

    async function fetchAudiences() {
        setIsLoading(true);

        const audiences = await getAllAudiences();

        setAudiences(audiences.payload);

        setAudienceContacts(audiences.payload);

        setIsLoading(false);
    }

    /**
     * @param {AudienceTypes.ListAudienceModel} rowData 
     */
    function handleChangeAudienceStatus(rowData) {
        setSelectedAudience(rowData);
        setIsDisableAudienceModalOpen(true);
    }

    function handleAudienceStatusChanged() {
        changeAudienceStatus(selectedAudience.ID, !selectedAudience.IS_ENABLED);
        setIsDisableAudienceModalOpen(false);
        setSelectedAudience(initialSelectedAudience);
    }

    /**
     * @param {AudienceTypes.ListAudienceModel} rowData 
     */
    function handleDeleteAudience(rowData) {
        setSelectedAudience(rowData);
        setIsDeleteAudienceModalOpen(true);
    }

    function handleDeletedAudience() {
        removeAudience(selectedAudience.ID);
        setIsDeleteAudienceModalOpen(false);
        setSelectedAudience(initialSelectedAudience);
    }

    /**
     * This is done because as of now, October 14th, 2024, the 
     * computation of the audience contacts is done in the backend 
     * and its very expensive. So, we avoid to fetch all the audiences
     * every time the status of an audience is changed.
     * @param {number} audienceId 
     * @param {boolean} isEnabled 
     */
    function changeAudienceStatus(audienceId, isEnabled) {
        /** @type {AudienceTypes.ListAudienceModel[]} */
        const newAudiences = audiences.map(audience => {
            if (audience.ID === audienceId) {
                return { ...audience, IS_ENABLED: isEnabled ? 1 : 0 };
            }

            return audience;
        });

        setAudiences(newAudiences);
    }

    /**
     * This is done because as of now, October 14th, 2024, the 
     * computation of the audience contacts is done in the backend 
     * and its very expensive. So, we avoid to fetch all the audiences
     * every time an audience is deleted.
     * @param {number} audienceId 
     */
    function removeAudience(audienceId) {
        const newAudiences = audiences.filter(audience => audience.ID !== audienceId);

        setAudiences(newAudiences);
    }

    /**
     * @param {AudienceTypes.ListAudienceModel[]} audiences
     */
    function setAudienceContacts(audiences) {
        audiences.forEach(audience => {
            try {
                getAudienceContacts(audience.ID).then(contacts => {
                    updateAudienceContacts(audience.ID, contacts.payload.count);
                }).catch(error => {
                    console.log(error);
                    updateAudienceContacts(audience.ID, 0);
                });
            } catch (error) {
                console.log(error);
                updateAudienceContacts(audience.ID, 0);
            }
        });
    }


    /**
     * @param {number} audienceId 
     * @param {string|number} contacts 
     */
    function updateAudienceContacts(audienceId, contacts) {
        // @ts-expect-error
        setAudiences((prev) => {
            const newAudiences = prev.map(audience => {
                if (audience.ID === audienceId) {
                    return { ...audience, CONTACTS: contacts };
                }

                return audience;
            });

            return newAudiences;
        });
    }

    return (
        <>
            <NeoGridContainer>
                <NeoTitleMain title="Audiencias" col="12" icon={Icono} />

                <NeoButtonSection
                    align="right"
                    col="12"
                    md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column"
                >
                    <NewAudienceButton />
                </NeoButtonSection>
            </NeoGridContainer>

            <AudienceTable
                audiences={audiences}
                isLoading={isLoading}
                onChangeAudienceEnabledStatus={handleChangeAudienceStatus}
                onDeleteAudience={handleDeleteAudience}
            />

            <DisableAudienceModal
                open={isDisableAudienceModalOpen}
                onClose={() => { setIsDisableAudienceModalOpen(false) }}
                audienceId={selectedAudience.ID}
                isEnabled={selectedAudience.IS_ENABLED === 1}
                onStatusUpdated={handleAudienceStatusChanged}
            />

            <DeleteAudienceModal
                open={isDeleteAudienceModalOpen}
                onClose={() => { setIsDisableAudienceModalOpen(false) }}
                audienceId={selectedAudience.ID}
                onDeletedAudience={handleDeletedAudience}
            />
        </>
    );
}
