import useToastContext from "hooks/useToastContext.hook";
import { useState } from "react";
import { updateAudienceStatus } from "service/Audience.service";
import { CONST } from "../../../consts/consts";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";

/**
 * @param {{
 *  open: boolean,
 *  onClose: () => void,
 *  audienceId: number,
 *  isEnabled: boolean,
 *  onStatusUpdated?: (isEnabled: boolean) => void,
 * }} param0 
 * @returns 
*/
export default function DisableAudienceModal({ open, onClose, audienceId, isEnabled, onStatusUpdated = () => { } }) {
    const toast = useToastContext();
    const [isLoading, setLoading] = useState(false);
    const enabledToDisabledCopy = 'Esto afectará todos los anuncios que usan esta audiencia y no podrás asignarla a futuros anuncios mientras esté deshabilitada. ¿Deseas continuar?';
    const disabledToEnabledCopy = 'Esto afectará todos los anuncios que usan esta audiencia. ¿Deseas continuar?';


    async function handleDisableAudience() {
        setLoading(true);

        const newStatus = !isEnabled;
        const response = await updateAudienceStatus(audienceId, newStatus);

        if (!response.success) {
            setLoading(false);
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
                CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
            );
        }

        onStatusUpdated(newStatus);
        setLoading(false);

        toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );
    }

    return (
        <NeoDialog
            header={isEnabled ? "Deshabilitación de audiencia" : "Habilitación de audiencia"}
            custom="custom-modal-header"
            visible={open}
            closable={true}
            onHide={onClose}
            resizable={false}
        >
            <div className="p-d-flex p-flex-column gap-17">
                <p className="m-0 fs-14 text-black">
                    {isEnabled ? enabledToDisabledCopy : disabledToEnabledCopy}
                </p>

                <div className="p-d-flex p-jc-end gap-8">
                    <NeoButtonMain alternative="outlined" onClick={onClose}>
                        Cancelar
                    </NeoButtonMain>
                    <NeoButtonMain onClick={handleDisableAudience} disabled={isLoading} >
                        Aceptar
                        {isLoading && <i className="pi pi-spin pi-spinner ml-5"></i>}
                    </NeoButtonMain>
                </div>
            </div>
        </NeoDialog>
    )
}