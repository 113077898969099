import { Fragment, useContext } from "react";
import DeleteRuleButton from "./DeleteRuleButton.component";
import SingleRule from "./SingleRule.component";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import { RulesContext } from "views/audiences/contexts/RulesContext.context";
import * as AudienceRuleTypes from "models/audience-rule.model";

/**
 * @param {{
 *  rule: AudienceRuleTypes.AudienceRuleModel,
 *  onUpdateRule: (rule: AudienceRuleTypes.AudienceRuleModel) => void
 * }} param0
 */
export default function MultipleRule({ rule, onUpdateRule }) {
  const { createRule } = useContext(RulesContext);
  const subRules = rule.SUB_RULES || [];

  function addNewSubRule() {
    const newSubRule = createRule("single");
    onUpdateRule({
      ...rule,
      SUB_RULES: [...subRules, newSubRule],
    });
  }

  /**
   * @param {AudienceRuleTypes.AudienceRuleModel} subRule
   */
  function deleteSubRule(subRule) {
    onUpdateRule({
      ...rule,
      SUB_RULES: subRules.filter((r) => r.ID !== subRule.ID),
    });
  }

  /**
   * @param {AudienceRuleTypes.AudienceRuleModel} subRule
   */
  function updateSubRule(subRule) {
    const updatedRule = {
      ...rule,
      SUB_RULES: subRules.map((r) => (r.ID === subRule.ID ? subRule : r)),
    };

    onUpdateRule(updatedRule);
  }

  return (
    <div
      style={{
        borderRadius: "8px",
        backgroundColor: "#E8ECF2",
        borderColor: "#DCE1E8",
        padding: "14px 22.5px",
      }}
    >
      <div className="pl-7 pr-7 p-d-flex p-flex-column gap-7">
        {subRules.map((subRule, index) => (
          <Fragment key={subRule.ID}>
            {index > 0 && <div className="p-d-flex p-jc-center">O</div>}
            <div className="p-d-flex gap-14">
              <div style={{ flex: "1 1 0%" }}>
                <SingleRule rule={subRule} onUpdateRule={updateSubRule} />
              </div>
              <DeleteRuleButton
                variant="ghost"
                onClick={() => deleteSubRule(subRule)}
                disabled={subRules.length < 3}
              />
            </div>
          </Fragment>
        ))}

        <div className="p-d-flex p-jc-center mt-7">
          <NeoButtonMain onClick={addNewSubRule}>
            <i className="pi pi-plus mr-6" />
            Agregar opción
          </NeoButtonMain>
        </div>
      </div>
    </div>
  );
}
