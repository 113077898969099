import { InputText } from "primereact/inputtext";
import * as AudienceRuleTypes from "../../../../models/audience-rule.model";
import * as PrimeReactTypes from "../../../../models/prime-react.model";

/**
 * @param {{
 *  rule: AudienceRuleTypes.AudienceRuleModel,
 *  componentFn: import("./RuleValueInputResolver.component").RuleComponentFn,
 *  onValueChange: (value: any[]) => void,
 *  dataType: 'string' | 'date' | 'number' | 'percentage',
 * }} param0 
 */
export default function InputRange({ rule, componentFn, onValueChange, dataType, ...props }) {

    /**
     * @param {PrimeReactTypes.OnChangeEvent} event 
     * @param {0|1} index 
     */
    function handleInputChange(event, index) {
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        if (event.originalEvent) {
            event.originalEvent.stopPropagation();
        }

        const value = event.value === undefined ? event.target?.value : event.value;
        
        const newValue = Array.isArray(rule.VALUE) ? [...rule.VALUE] : [null, null];
        newValue[index] = value;

        onValueChange(newValue);
    }

    /**
     * @param {number} index 
     * @returns 
     */
    function getRuleValue(index) {
        const value = rule.VALUE?.[index] ?? null;

        return dataType === 'string' && value === null ? '' : value;
    }

    return (
        <div className="p-d-flex gap-14">
            <div style={{ flex: 1 }}>
                {componentFn({ onChange: (e) => handleInputChange(e, 0), value: getRuleValue(0) })}
            </div>
            <div style={{ flex: 1 }}>
                {componentFn({ onChange: (e) => handleInputChange(e, 1), value: getRuleValue(1) })}
            </div>
        </div>
    )
}