import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = JSON.parse(JSON.stringify({
  "apiKey": "AIzaSyAUMancQ8YkqijVVFt6Lzu7isHKnOZaKVM",
  "authDomain": "neo-mnv-staging.firebaseapp.com",
  "projectId": "neo-mnv-staging",
  "storageBucket": "neo-mnv-staging.appspot.com",
  "messagingSenderId": "446017407976",
  "appId": "1:446017407976:web:9ff8808dabcdef55ed725d",
  "measurementId": "G-EBCPLKSQ3H"
}).replace(/[\r\n]/g, ""));
const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);

export { storage, auth };
