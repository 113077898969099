import { useRef, useState, useEffect } from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoInnerSubtitle from "../../../design/design_components/neo/title/NeoInnerSubtitle.base";
import NeoInputFile from "../../../design/design_components/neo/form/NeoInputFile.base";
import * as MediaTypes from "../../../models/media.model";
import AdFileErrorMessage from "./AdFileErrorMessage.component";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoTooltip_icon from "../../../design/design_components/neo/overlay/NeoTooltip_icon.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoCheckbox from "../../../design/design_components/neo/form/NeoCheckbox.base";
import {
  parseErrors,
  parseFileError,
  validateClickUrl,
  validateDesktopFile,
  validateMobileFile,
} from "../../../validation/media/create-media.validation";
import ImageIcon from "../../../design/assets/img/wfi/icons/icon-image.svg";
import SlideShowIcon from "../../../design/assets/img/wfi/icons/icon-slideshow.svg";
import CropIcon from "../../../design/assets/img/wfi/icons/icon-crop.svg";
import JSZip from "jszip";
import DesktopBannerMockup from "../../../design/assets/img/wfi/desktop-banner-mockup.png";
import MobileBannerMockup from "../../../design/assets/img/wfi/mobile-banner-mockup.png";
import MobileBannerTemplate from "../../../design/assets/img/wfi/mobile-banner-template.png";
import DesktopBannerTemplate from "../../../design/assets/img/wfi/desktop-banner-template.png";
import QuestionMarkIcon from "../../../design/assets/img/wfi/icons/icon-question-mark.svg";

/**
 *
 * @param {{
 *  media: MediaTypes.MediaModel,
 *  errors: Array<Object>,
 *  viewOnly: boolean,
 *  onUpdateFileIos: (file: File?) => void,
 *  onUpdateFileAndroid: (file: File?) => void,
 *  onUpdateFileDesktop: (file: File?) => void,
 *  onUpdateClickUrl: (clickUrl: string) => void,
 * }}
 * @returns
 */
export default function AdContentManagerc({
  media,
  errors = {},
  viewOnly = false,
  onUpdateErrors = () => {},
  onUpdateFileIos = () => {},
  onUpdateFileAndroid = () => {},
  onUpdateFileDesktop = () => {},
  onUpdateClickUrl = () => {},
  selectedMediaMobileDevice,
  checked,
  setChecked,
  iosImageUploaded,
  iosImageRef,
  iosImageLoading,
  handleIosImageUpload,
}) {
  const androidImageRef = useRef();
//  const iosImageRef = useRef();
  const desktopImageRef = useRef();

  //const [checked, setChecked] = useState(true);

  const [androidImageLoading, setAndroidImageLoading] = useState(false);
  //const [iosImageLoading, setIosImageLoading] = useState(false);
  const [desktopImageLoading, setDesktopImageLoading] = useState(false);
  //const [selectedMediaMobileDevice, setSelectedMediaMobileDevice] =useState("ios");

  //const iosImageUploaded = media.fileIos || media.fileIosUrl;

  const androidImageUploaded =
    media.fileAndroid || media.fileAndroidUrl


    useEffect(() => {
      const isValidImageUploaded = media.fileAndroid || media.fileAndroidUrl;
         
      // Desmarcar 
      if (isValidImageUploaded) {
        setChecked(false); // Activa el checkbox si hay una imagen cargada de Android o su URL
      }
    }, [media.fileAndroid, media.fileAndroidUrl, media.fileIosUrl]); 

    
  const desktopImageUploaded = media.fileDesktop || media.fileDesktopUrl;

  if (media?.fileIosUrl && !media.fileIos && iosImageRef.current) {
    iosImageRef.current.src = media.fileIosUrl;
  }

  function downloadTemplates() {
    const zip = new JSZip();
    //setIsDownloading(true);

    const mobileBanner = fetch(MobileBannerTemplate)
      .then((res) => res.arrayBuffer())
      .then((buffer) => zip.file("mobile-banner-template.png", buffer));

    const desktopBanner = fetch(DesktopBannerTemplate)
      .then((res) => res.arrayBuffer())
      .then((buffer) => zip.file("desktop-banner-template.png", buffer));

    Promise.all([mobileBanner, desktopBanner]).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        // downloads the zip file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "plantillas-de-banners.zip";
        link.click();
        //setIsDownloading(false);
      });
    });
  }

  /**
   * If no android url is provided, but an ios url is provided, then use the ios url as the android url
   * only to the preview image, not to the actual file. That way we can let the user know that the image
   * is the same for both devices but they can upload a different one if they want to.
   */
  if (
    (media?.fileAndroidUrl || media?.fileIosUrl) &&
    !media.fileAndroid &&
    androidImageRef.current
  ) {
    androidImageRef.current.src = media.fileAndroidUrl ?? media.fileIosUrl;
  }

  if (media?.fileDesktopUrl && !media.fileDesktop && desktopImageRef.current) {
    desktopImageRef.current.src = media.fileDesktopUrl;
  }

  const resolveImageEmptyTemplate = (
    file,
    fileImageRef,
    fileUrl = "",
    fileImageLoading = false
  ) => {
    return file || fileUrl ? (
      <div
        className="banner-image-preview-container"
        style={{ zIndex: fileImageLoading ? "99" : "auto" }}
      >
        <span>
          {fileImageLoading ? <i className="pi pi-spin pi-spinner"></i> : null}
        </span>
        <img ref={fileImageRef} alt="" />
      </div>
    ) : (
      <p>O arrástralo aquí</p>
    );
  };


  const handleAndroidImageUpload = (event) => {
    const file = event.files[0];

    if (file) {
      setAndroidImageLoading(true);

      validateMobileFile(file)
        .then(async (error) => {
          if (error.length) {
            const newError = parseFileError(error);
            console.log(newError);

            setAndroidImageLoading(false);

            onUpdateErrors({
              ...errors,
              fileAndroid: newError,
            });

            return;
          }

          onUpdateErrors({
            ...errors,
            fileAndroid: undefined,
          });

          try {
            onUpdateFileAndroid(file);
            await updatePreview(file, androidImageRef);
          } catch (error) {
            console.log(error, "error on update preview");
          } finally {
            setAndroidImageLoading(false);
          }
        })
        .catch((error) => {
          setAndroidImageLoading(false);
          console.log(error, "error on validate file");
        });
    }

    event.options.clear();
  };

  const handleDesktopImageUpload = (event) => {
    const file = event.files[0];

    if (file) {
      setDesktopImageLoading(true);

      validateDesktopFile(file)
        .then(async (error) => {
          if (error.length) {
            const newError = parseFileError(error);
            console.log(newError);

            setDesktopImageLoading(false);

            onUpdateErrors({
              ...errors,
              fileDesktop: newError,
            });

            return;
          }

          onUpdateErrors({
            ...errors,
            fileDesktop: undefined,
          });

          try {
            onUpdateFileDesktop(file);
            await updatePreview(file, desktopImageRef);
          } catch (error) {
            console.log(error, "error on update preview");
          } finally {
            setDesktopImageLoading(false);
          }
        })
        .catch((error) => {
          setDesktopImageLoading(false);
          console.log(error, "error on validate file");
        });
    }

    event.options.clear();
  };

  async function updatePreview(file, ref) {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function () {
          ref.current.src = reader.result;
          resolve();
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleClickUrlBlur = (event) => {
    const error = parseErrors({
      clickUrl: validateClickUrl(event.target.value),
    });

    onUpdateErrors({
      ...errors,
      clickUrl: error.clickUrl,
    });
  };

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked); 
    
  };

  

  return (
    <>
    <div  style={{ marginTop: '20px' }}> 
          <label className="input-label p-mr-1">Diferenciación por SO</label>
              <NeoTooltip_icon
                text="Tu anuncio puede tener contenido diferente para cada sistema o usar el mismo para ambos.

                      Toma en cuenta que iOS permite clic en el anuncio, pero Android no."
                extra="inline-tooltip"
                position="right"
              />
    </div>
    <div className={viewOnly ? "view-only-mode w-full" : "w-full"}>
      <div className="ad-content-manager pb-0 p-d-flex p-flex-column">
        <div>
            <span className="p-field-checkbox">
            <NeoCheckbox
              id="2"
              value={checked}
              onChange={onCheckboxChange} 
              label="Usar un mismo contenido para iOS & Android"
            />
            </span>
         
          <div className="p-grid">
          <div className="p-col" style={{width: '33%'}}>
          <NeoInnerSubtitle
            extra={"p-p-0 p-m-0 text-black " + (viewOnly ? "fs-16" : "")}
          >
             {checked ? "iOS & Android" : "iOS"}
          </NeoInnerSubtitle>
          <div className="p-d-flex p-ai-center">
          
          <div
            className={
              "screen-viewer mobile-screen banner-container banner-container-mobile " +
              (selectedMediaMobileDevice !== "ios" ? "hide" : "")
            }
            
          >
            <h3 className="text-primary fs-16 bold p-m-0">LOGO</h3>
            <div className="p-d-flex p-flex-column banner-card banner-card-mobile">
              <div>
                <p className="banner-welcome-text">
                  Hola{" "}
                  <span className="banner-template-text">{"{Nombre}"}</span>
                </p>
                <p className="banner-online-text">Ya estás en línea</p>
              </div>

        

              <NeoInputFile
                disabled={viewOnly}
                extra={
                  "banner-input-file mobile" +
                  (iosImageUploaded ? " file-uploaded" : "") +
                  (viewOnly ? "" : " mask-on-hover")
                }
                auto
                customUpload
                accept=".png,.jpg,.jpeg,.gif"
                chooseLabel={
                  iosImageUploaded ? "Cambiar imagen" : "Cargar archivo"
                }
                emptyTemplate={resolveImageEmptyTemplate(
                  media.fileIos,
                  iosImageRef,
                  media.fileIosUrl,
                  iosImageLoading
                )}
                uploadHandler={handleIosImageUpload}
              />
            </div>

            <div
              className={
                "mobile-banner-error-container " +
                (selectedMediaMobileDevice !== "ios" || viewOnly
                  ? "p-d-none"
                  : "")
              }
            >
              <AdFileErrorMessage
                error={errors.fileIos}
                closable
                onClose={() =>
                  onUpdateErrors({ ...errors, fileIos: undefined })
                }
              />
            </div>
          </div>
          </div>
          </div>
          {!checked && (
             <>
          <div className="p-col">
         
          <NeoInnerSubtitle
            extra={"p-p-0 p-m-0 text-black " + (viewOnly ? "fs-16" : "")}
          >
           Android
          </NeoInnerSubtitle>
          
          <div className="p-d-flex p-ai-center">
          <div
            className={
              "screen-viewer mobile-screen banner-container banner-container-mobile " +
              (!checked ? "" : "hide")
            }
            style={{
              marginLeft: "0px", // Ajusta el valor según el espacio que necesites
            }}
          >
            <h3 className="text-primary fs-16 bold p-m-0">LOGO</h3>
            <div className="p-d-flex p-flex-column banner-card banner-card-mobile">
              <div>
                <p className="banner-welcome-text">
                  Hola{" "}
                  <span className="banner-template-text">{"{Nombre}"}</span>
                </p>
                <p className="banner-online-text">Ya estás en línea</p>
              </div>

              <NeoInputFile
                disabled={viewOnly}
                extra={
                  "banner-input-file mobile" +
                  (androidImageUploaded ? " file-uploaded" : "") +
                  (viewOnly ? "" : " mask-on-hover")
                }
                auto
                customUpload
                accept=".png,.jpg,.jpeg,.gif"
                chooseLabel={
                  androidImageUploaded ? "Cambiar imagen" : "Cargar archivo"
                }
                emptyTemplate={resolveImageEmptyTemplate(
                  media.fileAndroid,
                  androidImageRef,
                  media.fileAndroidUrl
                    ? media.fileAndroidUrl
                    : media.fileIosUrl
                    ? media.fileIosUrl
                    : "",
                  androidImageLoading
                )}
                uploadHandler={handleAndroidImageUpload}
              />
            </div>

            <div
              className={
                "mobile-banner-error-container " +
                (selectedMediaMobileDevice !== "android" || viewOnly
                  ? "p-d-none"
                  : "")
              }
            >
              <AdFileErrorMessage
                error={errors.fileAndroid}
                closable
                onClose={() =>
                  onUpdateErrors({ ...errors, fileAndroid: undefined })
                }
              />
            </div>
          </div>

          
          

  </div>
  </div>
  </>
          )}
          <div className="p-col">
  <div
            className="custom-center-div" // Nuevo div
            style={{
              height: '100%', // O el alto deseado
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 10px', 
                // Ajusta el tamaño del div según sea necesario
            }}
          >
                    {/* Aquí puedes agregar el contenido que desees para el nuevo div */}
             
                    <div className="p-d-flex p-flex-column gap-7 p-pl-4">
                    <h3 className="subtitle-text text-primary p-m-0" style={{ paddingBottom: "15px", paddingLeft:"0px" }}>
                        Requisitos técnicos
                        <span style={{ 
                            backgroundColor: "#194893", 
                            borderRadius: "50%", // Hace que el fondo sea circular
                            width: "30px", // Ancho del círculo
                            height: "30px", // Alto del círculo
                            display: "inline-flex", // Permite que el span se comporte como un elemento en línea
                            alignItems: "center", // Centra verticalmente la imagen
                            justifyContent: "center", // Centra horizontalmente la imagen
                            marginLeft: "10px", // Margen a la izquierda para separar el texto del círculo
                            verticalAlign: "middle" // Alinea el círculo con el texto
                        }}>
                            <img 
                                src={QuestionMarkIcon} 
                                alt="question-mark" 
                                style={{ 
                                    width: "20px", 
                                    height: "20px", 
                                    opacity: 1 
                                }} 
                            />
                        </span>
                      </h3>
                        <div className="p-grid gap-7 text-black">
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                            <img src={ImageIcon} alt="image-icon" />
                            <span className="fs-14">JPG, JPEG, PNG - Máx 500KB</span>
                          </div>
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                            <img src={SlideShowIcon} alt="image-icon" />
                            <span className="fs-14">GIF - Máx. 1MB y 4 segundos</span>
                          </div>
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                            <img src={CropIcon} alt="image-icon" />
                            <span className="fs-14">Dimensiones 480px x 600px</span>
                          </div>
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                              <a href="#"  onClick={(e) => {
                                      e.preventDefault(); // Previene que el enlace navegue o recargue la página
                                      downloadTemplates(); // Llama a la función de descarga
                                    }} style={{ textDecoration: "underline" }}>
                                Descargar plantillas
                              </a>
                          </div>
                        </div>
                    </div>

                     
  </div>
  </div>

  <div className="p-col">
  </div>
  {checked && (
             <>
  <div className="p-col">
  </div>
  </>
)}
         
          </div>
          {/* 
          <span
            className={
              "p-buttonset p-jc-center " +
              (viewOnly && !media.fileAndroidUrl ? "p-d-none" : "p-d-flex")
            }
          >
            <NeoButtonMain
              label="iOS"
              onClick={() => setSelectedMediaMobileDevice("ios")}
              extra="p-button-primary android-ios-switch-button"
              alternative={
                selectedMediaMobileDevice !== "ios" ? "outlined" : undefined
              }
            />
            <NeoButtonMain
              label="Android"
              onClick={() => setSelectedMediaMobileDevice("android")}
              extra="android-ios-switch-button"
              alternative={
                selectedMediaMobileDevice !== "android" ? "outlined" : undefined
              }
            />
          </span>
          */}

          
        </div>

        

        <div className="horizontal-divider"></div>

        <div>

          <NeoInnerSubtitle
            extra={"p-p-0 p-m-0 text-black " + (viewOnly ? "fs-16" : "")}
          >
            Computadora
          </NeoInnerSubtitle>
          <div className="p-d-flex p-ai-center">
          <div className="screen-viewer desktop-screen banner-container banner-container-desktop">
            <h3 className="text-primary fs-16 bold p-m-0 banner-logo">LOGO</h3>
            <div className="p-d-flex p-flex-column banner-card banner-card-desktop">
              <div>
                <p className="banner-welcome-text">
                  Hola{" "}
                  <span className="banner-template-text">{"{Nombre}"}</span>
                </p>
                <p className="banner-online-text">Ya estás en línea</p>
              </div>

              <NeoInputFile
                disabled={viewOnly}
                extra={
                  "banner-input-file desktop" +
                  (desktopImageUploaded ? " file-uploaded" : "") +
                  (viewOnly ? "" : " mask-on-hover")
                }
                auto
                customUpload
                accept=".png,.jpg,.jpeg,.gif"
                chooseLabel={
                  desktopImageUploaded ? "Cambiar imagen" : "Cargar archivo"
                }
                emptyTemplate={resolveImageEmptyTemplate(
                  media.fileDesktop,
                  desktopImageRef,
                  media.fileDesktopUrl,
                  desktopImageLoading
                )}
                uploadHandler={handleDesktopImageUpload}
              />
            </div>

            <div
              className={
                "desktop-banner-error-container " + (viewOnly ? "p-d-none" : "")
              }
            >
              <AdFileErrorMessage
                error={errors.fileDesktop}
                closable
                onClose={() =>
                  onUpdateErrors({ ...errors, fileDesktop: undefined })
                }
              />
            </div>
          </div>

          <div className="p-d-flex  p-col-4 p-ai-center p-jc-center new-column">
                    <div className="p-d-flex p-flex-column gap-7 p-pl-4">
                      <h3 className="subtitle-text text-primary p-m-0 p-pl-4" style={{ paddingBottom: "15px" }}>
                        Requisitos técnicos
                        <span style={{ 
                            backgroundColor: "#194893", 
                            borderRadius: "50%", // Hace que el fondo sea circular
                            width: "30px", // Ancho del círculo
                            height: "30px", // Alto del círculo
                            display: "inline-flex", // Permite que el span se comporte como un elemento en línea
                            alignItems: "center", // Centra verticalmente la imagen
                            justifyContent: "center", // Centra horizontalmente la imagen
                            marginLeft: "10px", // Margen a la izquierda para separar el texto del círculo
                            verticalAlign: "middle" // Alinea el círculo con el texto
                        }}>
                            <img 
                                src={QuestionMarkIcon} 
                                alt="question-mark" 
                                style={{ 
                                    width: "20px", 
                                    height: "20px", 
                                    opacity: 1 
                                }} 
                            />
                        </span>
                      </h3>
                        <div className="p-grid gap-7 p-pl-4 text-black">
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                            <img src={ImageIcon} alt="image-icon" />
                            <span className="fs-14">JPG, JPEG, PNG - Máx 500KB</span>
                          </div>
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                            <img src={SlideShowIcon} alt="image-icon" />
                            <span className="fs-14">GIF - Máx. 1MB y 4 segundos</span>
                          </div>
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                            <img src={CropIcon} alt="image-icon" />
                            <span className="fs-14">Dimensiones 1080 x 600px</span>
                          </div>
                          <div className="p-d-flex p-ai-center gap-7 p-col-12 pb-0 pr-0">
                          <a href="#"  onClick={(e) => {
                                      e.preventDefault(); // Previene que el enlace navegue o recargue la página
                                      downloadTemplates(); // Llama a la función de descarga
                                    }} style={{ textDecoration: "underline" }}>
                                Descargar plantillas
                              </a>
                          </div>
                          
                          
                        </div>

                      </div>
          </div>
      </div>



        </div>
      </div>
{/*
      <div className="divider mt-16 mb-16"></div>

      <NeoColumn
        col="12"
        extra={
          "p-d-flex p-flex-column pl-0 pr-0 pb-0 " +
          (viewOnly && media.fileAndroidUrl ? "pt-16" : "pt-0")
        }
      >
        <div className="p-d-flex p-ai-center">
          <label className="input-label p-mr-1">Enlace del anuncio</label>
          <NeoTooltip_icon
            text="Destino al que se dirigirá el cliente al hacer clic en el anuncio (no aplica para Android)."
            extra="inline-tooltip"
            position="bottom"
          />
        </div>
        <NeoInputText
          extra="p-p-0"
          value={media.clickUrl}
          placeholder="http://www.ejemplo.com/"
          onChange={(event) =>
            onUpdateClickUrl({ clickUrl: event.target.value })
          }
          onBlur={handleClickUrlBlur}
          maxLength={255}
          error={errors.clickUrl}
          disabled={viewOnly}
        />
      </NeoColumn>
      */}
    </div>
    </>
  );
}
