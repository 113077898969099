import { forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import NewSingleRuleButton from "./NewSingleRuleButton.component";
import NewMultipleRuleButton from "./NewMultipleRuleButton.component";
import RuleResolver from "./RuleResolver.component";
import RuleDivider from "./RuleDivider.component";
import { RulesContext } from "views/audiences/contexts/RulesContext.context";
import * as AudienceRuleTypes from "../../../../models/audience-rule.model";
import { getAudienceRuleCriteria, getAudienceRuleOperators } from "service/Audience.service";
import { ContactRulesContext } from "views/audiences/contexts/ContactRules.context";
import { RuleValidationContext } from "views/audiences/contexts/RuleValidatorContext.context";
import { useRuleValidator } from "views/audiences";

/**
 * @typedef {{
 *  emptyRulesMessage: React.JSX.Element
 * }} AudienceRuleManagerProps
 * 
 * @typedef {{
 *  validate: () => boolean,
 *  silentValidate: () => boolean
 * }} AudienceRuleManagerHandle
*/ 

/**
 * @param {AudienceRuleManagerProps} param0 
 * @param {React.Ref<AudienceRuleManagerHandle>} ref 
 */
function AudienceRuleManager({ emptyRulesMessage }, ref) {
    const { rules, addRule, createRule } = useContext(RulesContext);
    const { registerValidator, removeValidator, triggerValidation } = useRuleValidator();
    const [ruleOperators, setRuleOperators] = useState([]);
    const [ruleCriteria, setRuleCriteria] = useState([]);

    const emptyRules = rules.length === 0;
    
    function validate() {
        return triggerValidation({ silent: false });
    }

    function silentValidate() {
        return triggerValidation({ silent: true });
    }

    useImperativeHandle(ref, () => ({ validate, silentValidate }));

    function getEmptyRulesMessage() {
        if (emptyRulesMessage) {
            return emptyRulesMessage;
        }

        return "No hay reglas creadas";
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            fetchRuleOperators();
            fetchRuleCriteria();
        }
    }, []);

    async function fetchRuleOperators() {
        const operators = await getAudienceRuleOperators();
        setRuleOperators(operators.payload);
    }

    async function fetchRuleCriteria() {
        const criteria = await getAudienceRuleCriteria();
        setRuleCriteria(criteria.payload);
    }

    if (emptyRules) {
        return (
            <>
                {getEmptyRulesMessage()}

                <div className="p-d-flex gap-14 p-jc-center w-full">
                    <NewSingleRuleButton onNewSingleRule={() => addRule(createRule('single'))} />
                    <NewMultipleRuleButton onNewMultipleRule={() => addRule(createRule('multiple'))} />
                </div>
            </>
        )
    }
    
    return (
        <>
            <div className="p-d-flex p-flex-column gap-14">
                <RuleValidationContext.Provider value={{ triggerValidation, registerValidator, removeValidator }}>
                    <ContactRulesContext.Provider value={{ ruleOperators: ruleOperators, ruleCriteria: ruleCriteria }}>
                        {rules.map(rule => {
                            return (
                                <Fragment key={rule.ID}>
                                    <RuleResolver rule={rule} />
                                    <RuleDivider/>
                                </Fragment>
                            )
                        })}
                    </ContactRulesContext.Provider>
                </RuleValidationContext.Provider>
            </div>

            <div className="p-d-flex gap-14 p-jc-end w-full">
                <NewSingleRuleButton onNewSingleRule={() => addRule(createRule('single'))} />
                <NewMultipleRuleButton onNewMultipleRule={() => addRule(createRule('multiple'))} />
            </div>
        </>
    )
}; 

export default forwardRef(AudienceRuleManager);