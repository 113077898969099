import { useContext } from "react";
import DeleteRuleButton from "./DeleteRuleButton.component";
import MultipleRule from "./MultipleRule.component";
import SingleRule from "./SingleRule.component";
import { RulesContext } from "views/audiences/contexts/RulesContext.context";
import * as AudienceRuleTypes from "../../../../models/audience-rule.model";

/**
 * @param {{
 *  rule: AudienceRuleTypes.AudienceRuleModel
 * }} param0
 */
export default function RuleResolver({ rule }) {
    const { removeRule, updateRule } = useContext(RulesContext);

    return (
        <div className="audience-rule-resolver p-d-flex gap-14 p-ai-center pl-7 pr-7">
            <div style={{ flex: '1 1 0%' }} >
                {rule.TYPE === 'single' 
                    ? <SingleRule rule={rule} onUpdateRule={(newRule) => updateRule(rule, newRule) } /> 
                    : <MultipleRule rule={rule} onUpdateRule={(newRule) => updateRule(rule, newRule) } />
                }
            </div>
            <div style={{ flex: 'none' }} >
                <DeleteRuleButton 
                    onClick={() => removeRule(rule)} 
                />
            </div>
        </div>
    )
}