import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NeoGridContainer from "../../../design/design_components/neo/layout/NeoGridContainer.base";
import Icono from "../../../design/assets/img/wfi/icons/icon-ad.svg";
import QuestionMarkIcon from "../../../design/assets/img/wfi/icons/icon-question-mark.svg";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import { CONST } from "../../../consts/consts";
import NeoTitleSecond from "../../../design/design_components/neo/title/NeoTitleSecond.base";
import WarningIcon from "../../../design/assets/img/wfi/icons/icon-warning.svg";
import NeoInputSwitch from "../../../design/design_components/neo/form/NeoInputSwitch.base";

import NeoCalendar from "../../../design/design_components/neo/form/NeoCalendar.base";
import MediaStoreSwapper from "../../media/components/MediaStoreSwapper.component";
import AdContentHelpModal from "../../media/components/AdContentHelpModal.component";
import Routes from "../../../routers/Routes.router.json";
import * as MediaTypes from "../../../models/media.model";
import {
  parseFileError,
  validateCreateCampaign,
  validateDesktopFile,
  validateMobileFile,
} from "../../../validation/campaign/create-campaign.validation";
import AdContentManager from "../../media/components/AdContentManager.component";
import useUploadMedia from "../../../hooks/useUploadMedia.hook";
import {
  getDefaultAd,
  getMediaDataInfo,
  postUploadNewImageMedia,
} from "../../../service/Media.service";
import {
  getDefaultCampaign,
  postNewCampaign
} from "../../../service/Campaign.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateTime } from "luxon";
import UpdateAdButton from "../../media/components/UpdateAdButton.component";
import NeoTooltip from "../../../design/design_components/neo/overlay/NeoTooltip.base";
import NeoTooltip_icon_target from "../../../design/design_components/neo/overlay/NeoTooltip_icon_target.base";
import NeoTooltip_icon from "../../../design/design_components/neo/overlay/NeoTooltip_icon.base";

import NeoSelectButton from "../../../design/design_components/neo/button/NeoSelectButton.base";
import PauseAdButton from "../../media/components/PauseAdButton.component";
//import SetAdAsDefaultModal from "./SetAdAsDefaultModal.component";
import "../style.scss";
import { Tag } from 'primereact/tag';


/**
 * @type {MediaTypes.MediaModel}
 */
const mediaDefaults = {
  id: undefined,
  name: "",
  isDefault: false,
  startDate: "",
  endDate: "",
  status:4,
  stores: [],
  isDistributed: false,
};

export default function CreateCampaign() {
  const toast = useToastContext();
  const history = useHistory();

  const [selectedValue, setSelectedValue] = useState('Regular');
  const [media, setMedia] = useState(mediaDefaults);
  const [mediaErrors, setMediaErrors] = useState({});
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [defaultAdId, setDefaultAdId] = useState(null);
  const [defaultAdModalOpen, setDefaultAdModalOpen] = useState(null);

  const [selectedMediaMobileDevice, setSelectedMediaMobileDevice] =
  useState("regular");

  const [selectedDistributed, setSelectedDistributed] =
  useState("regular");

  const { mediaId } = useParams();
  const isUpdateMode = !!mediaId;
  const [isLoading, setIsLoading] = useState(false);

  const { uploadImage, updateImage } = useUploadMedia();

  const showPauseButton = isUpdateMode && !media.isDefault;

  useEffect(() => {
    let mounted = true;

    if (isUpdateMode && mounted) {
      fetchAd();
    }

    if (mounted) {
      fetchDefaultAd();
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (media.startDate) {
      validateOnly("startDate");
    }
  }, [media.startDate]);

  useEffect(() => {
    if (media.endDate) {
      validateOnly("endDate");
    }
  }, [media.endDate]);

  const titleChoose = () => {
    if (!isUpdateMode) {
      return "Creación de campaña";
    } else {
      return "Edición de anuncio";
    }
  };

  /**
   * Updates the media object with the new properties.
   * Just a shorthand for the setMedia function.
   * @param {Partial<MediaTypes.MediaModel>} newMediaProperties
   */
  const updateMedia = async (newMediaProperties) => {
    setMedia({ ...media, ...newMediaProperties });
    //setMedia((prevMedia) => ({ ...prevMedia, ...newMediaProperties }));
    
  };

  const handleFetchMediaStoresError = (error) => {
    toast.setMessage(
      CONST.SEVERITY.ERROR,
      CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
      CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
    );
  };

  async function fetchAd() {
    let response = await getMediaDataInfo(mediaId);

    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      const media = response.result;

      updateMedia({
        id: mediaId,
        name: media.NAME,
        isDefault: Boolean(media.IS_DEFAULT),
        startDate: parseDateFromServer(media.START_DATE),
        endDate: parseDateFromServer(media.END_DATE),
        clickUrl: media.CLICK_URL,
        fileDesktopUrl: media.URL,
        fileAndroidUrl: media.URL_ANDROID,
        fileIosUrl: media.URL_IOS,
        fileType: media.FILE_TYPE,
        status: media.STATUS,
      });
    }
  }

  async function fetchDefaultAd() {
    let response = await getDefaultCampaign();

    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      const defaultAd = response.result;
      
      if (defaultAd?.ID) {
        setDefaultAdId(defaultAd.ID);
        if (defaultAdId) { 
      
          await updateMedia({ isDefault: false });
          }
    
      }
      else
      {
        await updateMedia({ isDefault: true });
      }
    }
  }

  /**
   * @param {keyof MediaTypes.MediaModel} field
   */
  async function validateOnly(field) {
    const errors = await validateCreateCampaign(media);

    setMediaErrors({
      ...mediaErrors,
      [field]: errors[field],
    });
  }

  const submitAd = async () => {
    setIsLoading(true);
    const errors = await validateCreateCampaign(media);

    setMediaErrors({
      ...errors,
      fileIos: !media.fileIosUrl
        ? parseFileError(await validateMobileFile(media.fileIos))
        : undefined,
      fileAndroid: !media.fileAndroidUrl
        ? parseFileError(
            (await validateMobileFile(media.fileAndroid)).filter(
              (err) => err !== "required"
            )
          )
        : undefined,
      fileDesktop: !media.fileDesktopUrl
        ? parseFileError(await validateDesktopFile(media.fileDesktop))
        : undefined,
    });

    if (Object.keys(errors).length > 0) {
      setIsLoading(false);
      return;
    }

    try {
      const mediaToUpload = await prepareMediaToUpload(media);

      const response = await postUploadNewImageMedia({ media: mediaToUpload });

      if (response.success) {
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
          CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
          CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );
        history.push(Routes.media.home);
      } else {
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
        );
      }
    } catch (error) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.FAIL_TO_CREATE.HEADLINE,
        CONST.TOAST_MESSAGES.FAIL_TO_CREATE.DETAILS
      );

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  const submitCampaign = async () => {
    
    setIsLoading(true);
    const errors = await validateCreateCampaign(media);
  
    setMediaErrors({
      ...errors,
      // Sin incluir fileIos, fileAndroid ni fileDesktop
    });

    if (Object.keys(errors).length > 0) {
      setIsLoading(false);
      return;
    }
   

    try {
      
      const response = await postNewCampaign({
        ...media,
        startDate: media.startDate ? DateTime.fromJSDate(media.startDate).toSQLDate() : null,
        endDate: media.endDate ? DateTime.fromJSDate(media.endDate).toSQLDate() : null,
      });

      if (response.success) {
    
        const campaignId = response.result.ID;          // Asumiendo que result es un objeto que tiene ID
        const campaignIdCons = response.result.ID_CONS;
        history.push(`/campanias/view/${campaignId}/${campaignIdCons}`);
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
          "Campaña creada",
          "La campaña fue creada correctamente."
        );
        //history.push(`/administracion-media/${ID_CAMPAIGN}/${ID}`);
        //history.push(Routes.campanias.home);

      } else {
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
        );
      }
    } catch (error) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.FAIL_TO_CREATE.HEADLINE,
        CONST.TOAST_MESSAGES.FAIL_TO_CREATE.DETAILS
      );

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Receives a date string from the server and returns a Date object.
   * Since the server returns a date in UTC because of javascript's Date object
   * parsing. As we only store the date in the database, if we have the value '2024-08-24'
   * it will return '2024-08-24T00:00:00.000Z' which is the same date but with the timezone
   * set to UTC. So if we parse this to UTC-6 to a JS Date it will show '2024-08-23T18:00:00.000Z'
   * and thats the date the Calendar component will show, which is incorrect.
   * @param {string} date
   * @returns
   */
  const parseDateFromServer = (date) => {
    return DateTime.fromSQL(
      DateTime.fromISO(date).setZone("UTC").toSQLDate()
    ).toJSDate();
  };

  const prepareMediaToUpload = async (media) => {
    const { desktopUrl, androidUrl, iosUrl } = await getMediaFileUrls(media);

    if (!desktopUrl || !iosUrl) {
      throw new Error("Error getting images urls");
    }

    const defaultMediaFileType = media.fileIos?.type?.includes("gif")
      ? "GIF"
      : "Imagen";

    const mediaFileType = media.fileType
      ? media.fileType
      : defaultMediaFileType;

    return {
      ...media,
      startDate: media.isDefault
        ? null
        : DateTime.fromJSDate(media.startDate).toSQLDate(),
      endDate: media.isDefault
        ? null
        : DateTime.fromJSDate(media.endDate).toSQLDate(),
      fileTypeId: mediaFileType,
      url: desktopUrl,
      urlAndroid: androidUrl,
      urlIos: iosUrl,
      stores: media.stores.map((store) => store.id),
    };
  };

  const getMediaFileUploadNeeded = (media) => {
    const promises = [];

    if (media.fileDesktop) {
      if (media.fileDesktopUrl) {
        promises.push(updateImage(media.fileDesktop, media.fileDesktopUrl));
      } else {
        promises.push(uploadImage(media.fileDesktop));
      }
    } else {
      promises.push(false);
    }

    if (media.fileAndroid) {
      if (media.fileAndroidUrl) {
        promises.push(updateImage(media.fileAndroid, media.fileAndroidUrl));
      } else {
        promises.push(uploadImage(media.fileAndroid));
      }
    } else {
      promises.push(false);
    }

    if (media.fileIos) {
      if (media.fileIosUrl) {
        promises.push(updateImage(media.fileIos, media.fileIosUrl));
      } else {
        promises.push(uploadImage(media.fileIos));
      }
    } else {
      promises.push(false);
    }

    return promises;
  };

  const getMediaFileUrls = async (media) => {
    const imagePromises = await Promise.allSettled(
      getMediaFileUploadNeeded(media)
    );

    let [fileDesktop, fileAndroid, fileIos] = imagePromises.map((promise) =>
      promise.status === "fulfilled" ? promise.value : null
    );

    if (!fileDesktop || !fileAndroid || !fileIos) {
      console.log("Error uploading images", imagePromises);
    }

    return {
      desktopUrl: fileDesktop ? fileDesktop.url : media.fileDesktopUrl,
      androidUrl: fileAndroid ? fileAndroid.url : media.fileAndroidUrl,
      iosUrl: fileIos ? fileIos.url : media.fileIosUrl,
    };
  };

  /**
   * @param {{ status: string}} param0
   */
  const handleUpdatedAd = async ({ status }) => {
    updateMedia({ status });

    toast.setMessage(
      CONST.SEVERITY.SUCCESS,
      CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
      CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
    );
  };

  const handleErrorUpdateAd = () => {
    toast.setMessage(
      CONST.SEVERITY.ERROR,
      CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
      CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
    );
  };


  const options = [
    {
      label: (
        <div style={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <span style={{ marginRight: '0.5rem' }}>Regular</span>
          <NeoTooltip_icon
            content="La rotación regular permite que tus anuncios sean visibles según configuraciones de audiencia. "
            extra="inline-tooltip-campanias"
            position="left"
          />
        </div>
      ),
      value: 'Regular'
    },
    {
      label: (
        <div style={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <span style={{ marginRight: '0.5rem' }}>Distribuida</span>
          <NeoTooltip_icon
            content="Esta configuración garantiza que los anuncios se muestren de manera equitativa, maximizando el alcance y asegurando una exposición uniforme."
            extra="inline-tooltip-campanias"
            position="right"
          />
        </div>
      ),
      value: 'Distribuida'
    }
  ];


  return (
    <>
      <NeoGridContainer className="custom-grid-container">
      <div className="p-grid" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

    <NeoTitleSecond
      col="auto"
      title={titleChoose()}
      subtitle={isUpdateMode ? media.name : ""}
      icon={Icono}
      goBack={true}
    />
    {!defaultAdId && (
    <Tag  style={{
      background: '#2196F3',
      //height: '35px',
      marginLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '-15px',
       borderRadius: '8px'
    }}>
      <span className="text-default">Default</span>
     
      <NeoTooltip_icon_target
        target="default_tag"
        content="Campaña default: Los anuncios de esta campaña se mostrarán cuando no haya otros disponibles. No tiene vigencia ni sucursales."
         extra="inline-tooltip-campanias"
        position="right"
      />
     
    </Tag>
      )}
  </div>
        {showPauseButton && (
          <NeoColumn col="4" extra="p-d-flex p-jc-end p-ai-center">
            <PauseAdButton
              adId={media.id}
              currentStatus={media.status}
              onUpdateStatusError={handleErrorUpdateAd}
              onUpdateStatusSuccess={handleUpdatedAd}
            />
          </NeoColumn>
        )}
      </NeoGridContainer>

      <NeoCard custom="custom-padding-card">
        <div className="p-col-6 p-d-flex p-jc-between pb-16">
          <h2 className="p-col fs-22 bold text-primary p-m-0 p-p-0">
            Configuración general
          </h2>
        </div>
        <div className="p-col-6 p-d-flex p-jc-between pb-16">
          <h2 className="p-col fs-22 bold text-primary p-m-0 p-p-0">
            Periodo de vigencia
          </h2>
        </div>

        <div className="p-d-flex p-col p-p-0">
          <NeoColumn col="6" extra="p-p-0">
            <div className="p-d-flex p-ai-center pl-8 mb-4">
              <label className="input-label p-mr-1">Nombre de la campaña</label>
            </div>
            <NeoInputText
              extra="p-pt-0 p-pb-0 p-mt-0 p-mb-0"
              value={media.name}
              onChange={(event) => updateMedia({ name: event.target.value })}
              onBlur={() => validateOnly("name")}
              maxLength={120}
              error={mediaErrors.name}
            />
          </NeoColumn>
          {defaultAdId && (
            <>
          <NeoColumn col="3" extra="p-p-0">
            <div className="p-d-flex p-ai-center pl-8 mb-4">
              <label className="input-label mr-4">Fecha de inicio</label>
              
            </div>
            <NeoCalendar
              extra="p-pt-0 p-mt-0 p-pb-0 p-mb-0"
              value={media.startDate}
              placeholder="dd/mm/yyyy"
              dateFormat="dd/mm/yy"
              onChange={(event) => updateMedia({ startDate: event.value })}
              error={mediaErrors.startDate}
           
            />
          </NeoColumn>
          <NeoColumn col="3" extra="p-p-0">
            <div className="p-d-flex p-ai-center pl-8 mb-4">
              <label className="input-label mr-4">Fecha de finalización</label>
             
            </div>
            <NeoCalendar
              extra="p-pt-0 p-mt-0 p-pb-0 p-mb-0"
              value={media.endDate}
              placeholder="dd/mm/yyyy"
              dateFormat="dd/mm/yy"
              onChange={(event) => updateMedia({ endDate: event.value })}
              error={mediaErrors.endDate}
          
            />
          </NeoColumn>
          </>
          )}

          <NeoColumn col="7" extra="p-p-0">
            <div className="p-d-flex p-ai-center pl-8 mb-4" style={{paddingTop: '30px', fontSize: '17px'}}>
              
            {!defaultAdId && (
                <>
                  Permanente
                  <NeoTooltip_icon_target
                    target="permanente"
                    content="La campaña default es permanente para asegurar que siempre cuentes con anuncios en tus splash pages."
                    extra="inline-tooltip"
                    position="right"
                  />
       
                </>
              )}

              

          </div>
          </NeoColumn>
           
        
        </div>
    


        {defaultAdId && (
            <>
        <div className="p-col-12 pb-0" style={{marginTop: '35px'}}>
          <div className="p-d-flex gap-12 p-ai-center">
            <h2 className="fs-22 bold text-primary p-m-0 p-p-0">
              Tipo de campaña
            </h2>
            </div>
          </div>
            


              <div style={{  paddingTop: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
  <span style={{ fontSize: '12px', color: '#6c757d', display: 'flex', alignItems: 'center' }}>
    Selecciona el tipo de campaña
    <NeoTooltip_icon
      content="Campaña regular: se muestran los anuncios en orden cronológico según su fecha de creación. Campaña distribuida: Esta configuración garantiza que los anuncios se muestren de manera equitativa."
      extra="inline-tooltip-create-campanias"
      position="right"
      style={{ marginLeft: '5px', display: 'inline-flex', alignItems: 'center' }}
    />
  </span>
</div>
              <span
            className={
              "p-buttonset p-jc-center " 
             
            }
          >
            <NeoButtonMain
               label={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  Regular
                  {/*}
                  <NeoTooltip_icon
                    content="La rotación regular permite que tus anuncios sean visibles según configuraciones de audiencia."
                    extra="inline-tooltip-campanias"
                    position="left"
                  />
                  {*/}
                </div>
              }
              onClick={async () => {
                setSelectedDistributed("regular");
                await updateMedia({ isDistributed: false });
              }}
              extra="p-button-primary android-ios-switch-button"
              alternative={
                selectedDistributed !== "regular" ? "outlined" : undefined
              }
            />
            <NeoButtonMain
               label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Distribuida
                    {/*}
                  <NeoTooltip_icon
                      content="Esta configuración garantiza que los anuncios se muestren de manera equitativa, maximizando el alcance y asegurando una exposición uniforme."
                      extra="inline-tooltip-campanias"
                      position="right"
                    />
                    {*/}
                </div>
              }
              onClick={async () => {
                setSelectedDistributed("distribuida");
                await updateMedia({ isDistributed: true });
              }}
              extra="android-ios-switch-button"
              alternative={
                selectedDistributed !== "distribuida" ? "outlined" : undefined
              }
            />
          </span>
          </div>
          </>
              )}



        <div className="p-col-12 pb-0" style={{marginTop: '35px'}}>
          <div className="p-d-flex gap-12 p-ai-center">
            <h2 className="fs-22 bold text-primary p-m-0 p-p-0">
              Asignación de sucursales
            </h2>
            </div>
          </div>
            <NeoColumn col="12" extra="p-p-0">
            <div className="p-d-flex p-ai-center pl-8 mb-4" style={{paddingTop: '30px', fontSize: '17px'}}>
                {!defaultAdId && (
                  <>
                    Todas las sucursales asignadas
                    <NeoTooltip_icon
                     content="La campaña default se aplicará en todas las sucursales. Si necesitas una campaña para una específica, crea una campaña regular."
                     extra="inline-tooltip"
                      position="right"
                    />
                  </>
                )}
             
             {defaultAdId && (
                <MediaStoreSwapper
                  media={media}
                  isUpdateMode={isUpdateMode}
                  onUpdateMediaStores={updateMedia}
                  onFetchMediaStoresError={handleFetchMediaStoresError}
                />
              )}
          </div>
          </NeoColumn>
      </NeoCard>

     

      

      <div className="p-d-flex p-jc-end pb-10 gap-16">
        {isUpdateMode && (
          <>
            <NeoButtonMain
              alternative="outlined"
              extra="action-button bg-white"
              onClick={() => history.push(Routes.campanias.home)}
            >
              Cancelar
            </NeoButtonMain>
            <UpdateAdButton
              showSpinner={isLoading}
              onUpdateAd={submitAd}
            ></UpdateAdButton>
          </>
        )}

        {!isUpdateMode && (
           <>

            <NeoButtonMain  alternative="outlined"
              extra="action-button bg-white" onClick={() => history.push(Routes.campanias.home)}>
           
             Cancelar
           </NeoButtonMain>
           <NeoButtonMain onClick={submitCampaign} disabled={isLoading}>
             {isLoading && <i className="pi pi-spin pi-spinner mr-5"></i>}
             Crear campaña
           </NeoButtonMain>
 
           
         </>
          
        )}
      </div>

      <AdContentHelpModal
        open={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      />

     
    </>
  );
}
