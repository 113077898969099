import React from "react";
import BaseTooltip from "../../sisu/overlay/BaseTooltip.sisu";


const NeoTooltip_icon_target = (props) => {

  const {
    extra,
    icon,
    text,
    position,
    target,
    ...otherProps
  } = props;

  return (
    <>
      <BaseTooltip target={`.${target}`} {...otherProps} />
      <div className={`tooltip-icon p-field p-col p-col-fixed ${extra}`}>
        <i
          className={`${icon} ${target}`}
          data-pr-tooltip={text}
          data-pr-position={position}
        />
      </div>
    </>
  );
};

NeoTooltip_icon_target.defaultProps = {
  extra: '',
  icon: 'pi pi-question-circle',
  text: '',
  position: 'left',
  target: 'custom-target-icon'
};

export default NeoTooltip_icon_target;
