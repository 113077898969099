import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";

/**
 * @param {{
 *  value: any,
 *  onChange: (value: any) => void,
 * }} param0 
 * @returns 
 */
export default function InputDateSelect({ value, onChange, ...props }) {
    const dateTypeOptions = [
        { label: 'Mes', value: 'month' },
        { label: 'Rango', value: 'range' },
    ];

    const monthOptions = [
        { label: 'Enero', value: 1 },
        { label: 'Febrero', value: 2 },
        { label: 'Marzo', value: 3 },
        { label: 'Abril', value: 4 },
        { label: 'Mayo', value: 5 },
        { label: 'Junio', value: 6 },
        { label: 'Julio', value: 7 },
        { label: 'Agosto', value: 8 },
        { label: 'Septiembre', value: 9 },
        { label: 'Octubre', value: 10 },
        { label: 'Noviembre', value: 11 },
        { label: 'Diciembre', value: 12 },
    ];

    function handleDateTypeChange(event) {
        const value = event.value;

        onChange({
            value: {
                type: value,
                month: null,
            }
        });
    }

    function getDateTypeValue() {
        if (!value) return null;

        return value.type;
    }

    function getMonthValue() {
        if (!value) return null;

        return value.month;
    }

    function getRangeValue() {
        if (!value) return [];

        if (Array.isArray(value.range)) return value.range;

        return [];
    }

    function getMonthSelector() {
        return (
            <NeoDropdown 
                placeholder="Selecciona mes" 
                extra="p-0 m-0" 
                filter 
                options={monthOptions} 
                value={getMonthValue()}
                onChange={handleMonthChange}
            />
        )
    }

    function getRangeSelector() {
        return <div className="p-d-flex gap-5">
            <NeoCalendar 
                placeholder="Rango de fechas" 
                format="dd/mm/yy HH:mm"
                selectionMode="range"
                showTime={true}
                value={getRangeValue().map(date => new Date(date))}
                onChange={handleRangeChange}
                extra="p-0 m-0" 
            />
        </div>
    }

    function handleMonthChange(event) {
        const value = event.value;

        onChange({
            value: {
                type: getDateTypeValue(),
                month: value,
            }
        });
    }

    function handleRangeChange(event) {
        const value = event.value;

        onChange({
            value: {
                type: 'range',
                range: [value[0], value[1]]
            }
        });
    }
        
    return (
        <div className="p-d-flex gap-5">
            <div style={{ width: '120px' }}>
                <NeoDropdown 
                    placeholder="Selecciona tipo de fecha" 
                    extra="p-0 m-0" 
                    options={dateTypeOptions} 
                    value={getDateTypeValue()} 
                    onChange={handleDateTypeChange}
                />
            </div>
            <div className="input-icon-left" style={{ flex: 1 }}>
                <i className="icon-left pi pi-calendar"></i>
                {
                    getDateTypeValue() === 'range' ? getRangeSelector() : getMonthSelector()
                }
            </div>
        </div>
    )
}