import MediaStoreList from "./MediaStoreList.component";
import ChevronDoubleRightIcon from "../../../design/assets/img/wfi/icons/icon-chevron-double-right.svg";
import ChevronDoubleLeftIcon from "../../../design/assets/img/wfi/icons/icon-chevron-double-left.svg";
import ChevronRightIcon from "../../../design/assets/img/wfi/icons/icon-chevron-right.svg";
import ChevronLeftIcon from "../../../design/assets/img/wfi/icons/icon-chevron-left.svg";
import { useEffect, useRef, useState } from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import { getMediaStore } from "../../../service/Media.service";
import * as MediaTypes from "../../../models/media.model";

/**
 *
 * @param {{
 *  media: MediaTypes.MediaModel,
 *  onUpdateMediaStores: (stores: Object[]) => void,
 *  onFetchMediaStoresError: (error: Error) => void
 * }} param0
 * @returns
 */
export default function MediaStoreSwapper({
  media,
  isUpdateMode,
  onUpdateMediaStores,
  onFetchMediaStoresError,
}) {
  const ogStores = useRef([]);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const currentSelectedStoresAreFromAvailableSection = !media.stores.find(
    (store) => store.id === (selectedStores[0] ?? -1)
  );

  const fetchStores = async () => {
    let response = await getMediaStore(media.id, undefined);

    if (!response.success) {
      onFetchMediaStoresError(response.error);
    } else {
      const stores = response.result;
      const assignedStores = stores.filter((store) => store.checked);
      const availableStores = stores.filter((store) => !store.checked);

      ogStores.current = response.result;
      setStores(availableStores);
      onUpdateMediaStores({ stores: assignedStores });
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      // Create mode
      if (!isUpdateMode && !media.id) {
        fetchStores();
      }

      /**
       * Explanation: The media object is passed as a prop, probably with some default values.
       * If the default values does not include and id and we call onUpdateMediaStores, even tho
       * we are just updating the stores, the media object will be updated with the defaults values
       * since this component and the parent component initialize at the same time. We do this to
       * avoid an infinite loop of updates.
       */
      if (isUpdateMode && media.id) {
        fetchStores();
      }
    }

    return () => {
      mount = false;
    };
  }, [media.id]);

  const handleSelectStore = (storeId) => {
    let currentSelectedStores = selectedStores;

    const storeIsAlreadySelected = selectedStores.includes(storeId);

    if (!storeIsAlreadySelected) {
      const selectedStoreIsFromAvailableSection = Boolean(
        !media.stores.find((store) => store.id === storeId)
      );

      // avoids selecting stores from both sections (Available and Assigned)
      if (
        currentSelectedStoresAreFromAvailableSection !==
        selectedStoreIsFromAvailableSection
      ) {
        currentSelectedStores = [];
      }

      setSelectedStores([...currentSelectedStores, storeId]);
    } else {
      setSelectedStores(
        currentSelectedStores.filter(
          (selectedStoreId) => selectedStoreId !== storeId
        )
      );
    }
  };

  const assignStoreToMedia = (assignationsIds) => {
    const assignations = stores.filter((store) =>
      assignationsIds.includes(store.id)
    );

    const newAvailableStores = stores.filter(
      (store) =>
        !assignations.find((assignation) => assignation.id === store.id)
    );

    setStores(newAvailableStores);
    setSelectedStores([]);
    onUpdateMediaStores({ stores: [...media.stores, ...assignations] });
  };

  const removeStoreFromMedia = (removalsIds) => {
    const removals = media.stores.filter((store) =>
      removalsIds.includes(store.id)
    );

    const newMediaStores = media.stores.filter(
      (store) => !removals.find((removal) => removal.id === store.id)
    );

    setStores([...stores, ...removals]);
    setSelectedStores([]);
    onUpdateMediaStores({ stores: newMediaStores });
  };

  const handleSearchStores = (searchTerm) => {
    if (!searchTerm) {
      setStores(ogStores.current);
      return;
    }

    setStores(
      stores.filter(
        (store) =>
          store.storeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          store.storeLocation.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  return (
    <div className="p-col-12 p-p-0 banner-assign-stores-container">
      <MediaStoreList
        stores={stores}
        selectedStores={selectedStores}
        onSelectedStore={handleSelectStore}
        onSearch={handleSearchStores}
        onEmptyText="No hay sucursales para asignar"
        headerTitle="Sucursales disponibles"
      />
      <div className="banner-assign-stores-buttons-section">
        <NeoButtonMain
          extra="p-button-icon-only reassign-button p-button-primary"
          onClick={() => assignStoreToMedia(stores.map((store) => store.id))}
          disabled={stores.length === 0}
          title="Asignar todas las sucursales"
        >
          <img
            src={ChevronDoubleRightIcon}
            alt="Icono mover todo hacia derecha"
          />
        </NeoButtonMain>
        <NeoButtonMain
          extra="p-button-icon-only reassign-button p-button-primary"
          onClick={() => assignStoreToMedia(selectedStores)}
          disabled={
            selectedStores.length === 0 ||
            !currentSelectedStoresAreFromAvailableSection
          }
          title="Asignar sucursales seleccionadas"
        >
          <img src={ChevronRightIcon} alt="Icono mover hacia derecha" />
        </NeoButtonMain>

        <NeoButtonMain
          extra="p-button-icon-only reassign-button p-button-primary"
          onClick={() => removeStoreFromMedia(selectedStores)}
          disabled={
            selectedStores.length === 0 ||
            currentSelectedStoresAreFromAvailableSection
          }
          title="Remover sucursales seleccionadas"
        >
          <img src={ChevronLeftIcon} alt="Icono mover hacia izquierda" />
        </NeoButtonMain>
        <NeoButtonMain
          extra="p-button-icon-only reassign-button p-button-primary"
          onClick={() =>
            removeStoreFromMedia(media.stores.map((store) => store.id))
          }
          disabled={media.stores.length === 0}
          title="Remover todas las sucursales"
        >
          <img
            src={ChevronDoubleLeftIcon}
            alt="Icono mover todo hacia izquierda"
          />
        </NeoButtonMain>
      </div>
      <MediaStoreList
        stores={media.stores}
        selectedStores={selectedStores}
        onSelectedStore={handleSelectStore}
        onSearch={handleSearchStores}
        onEmptyText="No hay sucursales asignadas"
        headerTitle="Sucursales asignadas"
      />
    </div>
  );
}
