import { CONST } from "consts/consts";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import useToastContext from "hooks/useToastContext.hook";
import { useState } from "react";
import { downloadAudienceContacts } from "service/Audience.service";

/**
 * @param {{
 *  audienceId: number
 * }} param0 
 * @returns 
 */
export default function DownloadAudienceButton({ audienceId }) {
    const [isLoading, setLoading] = useState(false);
    const toast = useToastContext();

    async function handleDownloadAudience() {
        setLoading(true);

        await downloadAudienceContacts(audienceId, (error) => {
            toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.FAIL_TO_DOWNLOAD.HEADLINE,
                CONST.TOAST_MESSAGES.FAIL_TO_DOWNLOAD.DETAILS
            );
        });

        setLoading(false);
    }

    return (
        <NeoButtonMain
            onClick={handleDownloadAudience}
            disabled={isLoading}
            extra="action-button"
        >
            <i className="pi pi-download mr-5"></i>
            Descargar
            {isLoading && <i className="pi pi-spin pi-spinner ml-5"></i>}
        </NeoButtonMain>
    );
}