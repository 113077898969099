import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import Icono from "../../design/assets/img/wfi/icons/icon-ad.svg";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import * as AudienceRuleTypes from "../../models/audience-rule.model";
import useToastContext from "hooks/useToastContext.hook";
import { CONST } from "../../consts/consts";
import { getAudienceById, updateAudience } from "service/Audience.service";
import ManageAudience from "./components/ManageAudience.component";
import { useEffect, useState } from "react";
import NeoSpinner from "design/design_components/neo/overlay/NeoSpinner.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import DisableAudienceButton from "./components/DisableAudienceButton.component";
import DownloadAudienceButton from "./components/DownloadAudienceButton.component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

/**
 * @type {AudienceRuleTypes.UpdateAudienceModel}
 */
const defaultAudience = null;

export default function EditAudience() {
    const [audience, setAudience] = useState(defaultAudience);
    const { id: audienceId } = useParams();
    const toast = useToastContext();
    const history = useHistory();

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            fetchAudience();
        }

        return () => {
            isMounted = false;
        }
    }, []);

    async function fetchAudience() {
        const response = await getAudienceById(Number(audienceId));

        if (!response.success) {
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
                CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );
        }

        setAudience(response.payload);
    }

    /**
     * @param {Omit<AudienceRuleTypes.AudienceModel, 'ID'>} audience
     * @returns {Promise<void>}
     */
    async function handleUpdateAudience(audience) {
        const response = await updateAudience({ ...audience, ID: Number(audienceId) });

        if (!response.success) {
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
                CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
            );
        }

        toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );

        history.push('/audiencias');
    }

    if (audience === null) {
        return <NeoSpinner />;
    }

    return (
        <>
            <div className="p-d-flex gap-7 mb-16">
                <div>
                    <NeoTitleSecond title="Edición de audiencia" icon={Icono} goBack={true} extra="mb-0" />
                </div>

                <div className="p-d-flex p-ai-center">
                    <span className={"badge badge-regular fs-14 pl-8 pr-8 pt-4 pb-4 " + (audience.IS_ENABLED ? 'badge-success' : 'badge-gray')}>
                        {audience.IS_ENABLED ? 'Habilitado' : 'Deshabilitado'}
                    </span>
                </div>
                
                <div className="p-d-flex gap-10 ml-auto p-ai-center">
                    <DisableAudienceButton 
                        audienceId={Number(audience.ID)} 
                        isEnabled={audience.IS_ENABLED} 
                        onStatusUpdated={(isEnabled) => setAudience({ ...audience, IS_ENABLED: isEnabled })}
                    />
                    <DownloadAudienceButton audienceId={Number(audience.ID)} />
                </div>
            </div>

            <ManageAudience
                audienceInfo={audience}
                onSaveAudience={handleUpdateAudience}
                saveButtonText="Guardar cambios"
            />
        </>
    );
}