import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import ImageIcon from "../../../design/assets/img/wfi/icons/icon-image.svg";
import SlideShowIcon from "../../../design/assets/img/wfi/icons/icon-slideshow.svg";
import CropIcon from "../../../design/assets/img/wfi/icons/icon-crop.svg";
import DesktopBannerMockup from "../../../design/assets/img/wfi/desktop-banner-mockup.png";
import MobileBannerMockup from "../../../design/assets/img/wfi/mobile-banner-mockup.png";
import MobileBannerTemplate from "../../../design/assets/img/wfi/mobile-banner-template.png";
import DesktopBannerTemplate from "../../../design/assets/img/wfi/desktop-banner-template.png";
import JSZip from "jszip";
import { useState } from "react";

export default function AdContentHelpModal({ open, onClose = () => {} }) {
  const [isDownloading, setIsDownloading] = useState(false);

  /**
   * Bundles the two templates in a zip file and downloads it
   */
  function downloadTemplates() {
    const zip = new JSZip();
    setIsDownloading(true);

    const mobileBanner = fetch(MobileBannerTemplate)
      .then((res) => res.arrayBuffer())
      .then((buffer) => zip.file("mobile-banner-template.png", buffer));

    const desktopBanner = fetch(DesktopBannerTemplate)
      .then((res) => res.arrayBuffer())
      .then((buffer) => zip.file("desktop-banner-template.png", buffer));

    Promise.all([mobileBanner, desktopBanner]).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        // downloads the zip file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "plantillas-de-banners.zip";
        link.click();
        setIsDownloading(false);
      });
    });
  }

  return (
    <NeoDialog
      header="Ayuda sobre el contenido del anuncio"
      custom="custom-modal-header ad-content-help-modal"
      visible={open}
      closable={true}
      dismissableMask={true}
      onHide={onClose}
      resizable={false}
    >
      <div className="p-d-flex p-flex-column gap-7">
        <h3 className="subtitle-text text-primary p-m-0">
          Requisitos técnicos
        </h3>

        <div className="p-grid gap-7 text-black">
          <div className="p-d-flex p-ai-center gap-7 p-col pb-0 pr-0">
            <img src={ImageIcon} alt="image-icon" />
            <span className="fs-14 ">JPG, JPEG, PNG. Máximo 500KB</span>
          </div>
          <div className="p-d-flex p-ai-center gap-7 p-col pl-0 pb-0">
            <img src={SlideShowIcon} alt="image-icon" />
            <span className="fs-14 ">GIF (máx. 4 segundos) Máximo 1MB</span>
          </div>
        </div>
      </div>

      <div className="divider mt-17 mb-17"></div>

      <div className="p-d-flex p-flex-column gap-7">
        <h3 className="subtitle-text text-primary p-m-0">Tamaños</h3>

        <div className="banner-size-container border-bottom">
          <div className="device-container mr-auto">
            <div className="p-d-flex p-ai-center gap-7">
              <span className="fs-14 bold">Dispositivos móviles</span>
              <img src={CropIcon} alt="image-icon" />
              <span className="fs-14">480 x 600px</span>
            </div>

            <img src={MobileBannerMockup} alt="image-icon" />
          </div>

          <div className="vertical-divider"></div>

          <div className="device-container ml-auto">
            <div className="p-d-flex p-ai-center gap-7">
              <span className="fs-14 bold">Computadoras de escritorio</span>
              <img src={CropIcon} alt="image-icon" />
              <span className="fs-14">1080 x 600px</span>
            </div>

            <img src={DesktopBannerMockup} alt="image-icon" />
          </div>
        </div>
      </div>

      <div className="divider mt-17 mb-17"></div>

      <div className="p-d-flex">
        <NeoButtonMain
          extra="ml-auto"
          onClick={downloadTemplates}
          disabled={isDownloading}
        >
          {isDownloading && <i className="pi pi-spin pi-spinner mr-5"></i>}
          {!isDownloading && <i className="pi pi-download mr-5"></i>}
          Descargar plantillas
        </NeoButtonMain>
      </div>
    </NeoDialog>
  );
}
