import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import { useRulePropertiesResolver } from "views/audiences";
import * as AudienceRuleTypes from "../../../../models/audience-rule.model";
import * as PrimeReactTypes from "../../../../models/prime-react.model";
import NeoInputNumber from "design/design_components/neo/form/NeoInputNumber.base";
import InputRange from "./InputRange.component";
import InputDateSelect from "./InputDateSelect.component";
import InputSelect from "./InputSelect.component";

/**
 * @typedef {(props: Object) => JSX.Element} RuleComponentFn
 */

/**
 * @param {{
 *  rule: AudienceRuleTypes.AudienceRuleModel,
 *  onValueChange: (value: any[]) => void,
 *  [x: string]: any
 * }} props 
 */
export default function RuleValueInputResolver({ rule, onValueChange, ...props }) {
    let { criteria, dataType, isRange, isSelect, isMultiple, inputSuffix, inputProperties } = useRulePropertiesResolver(rule);
    dataType = dataType ?? 'string';


    /**
     * @param {PrimeReactTypes.OnChangeEvent} event 
     */
    function handleNonRangeInputChange(event) {
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        if (event.originalEvent) {
            event.originalEvent.stopPropagation();
        }

        const value = event.value === undefined ? event.target.value : event.value;
        
        onValueChange(value);
    }

    /**
     * @param {any[]} value 
     */
    function handleRangeInputChange(value) {
        onValueChange(value);
    }

    /** @type {Record<string, RuleComponentFn>} */
    const dataTypeResolver = {
        string: (custom = {})  => <NeoInputText placeholder="Ingrese un valor" suffix={inputSuffix} {...inputProperties} extra="p-input-icon-left" {...props} {...custom} />,
        date: (custom = {}) => <InputDateSelect suffix={inputSuffix} {...inputProperties} {...props} {...custom} />,
        number: (custom = {}) => <NeoInputNumber placeholder="Ingrese un valor" {...inputProperties} suffix={inputSuffix} {...props} {...custom} />,
        percentage: (custom = {}) => <NeoInputNumber placeholder="Ingrese un valor" suffix={inputSuffix} {...inputProperties} {...props} {...custom} />,
    }

    const componentFn = dataTypeResolver[dataType] ?? dataTypeResolver['string']; 

    if (isSelect) {
        return (
            <InputSelect onValueChange={handleNonRangeInputChange} rule={rule} criteria={criteria} multiple={isMultiple} />
        )
    }

    if (isRange) {
        return (
            <InputRange onValueChange={handleRangeInputChange} rule={rule} componentFn={componentFn} dataType={dataType} />
        )
    }

    return componentFn({ 
        onChange: handleNonRangeInputChange, 
        value: (dataType === 'string' && rule.VALUE === null) 
            ? ''
            : rule.VALUE 
    });
}