import { RuleValidationContext } from "views/audiences/contexts/RuleValidatorContext.context";
import RuleCriteriaSelect from "./RuleCriteriaSelect.component";
import RuleOperatorSelect from "./RuleOperatorSelect.component";
import RuleValueInputResolver from "./RuleValueInputResolver.component";
import { useContext, useEffect, useState } from "react";
import RuleError from "./RuleError.component";
import { validateRule, validateCriteria, validateOperator, validateValue } from "views/audiences/validator";
import * as AudienceRuleTypes from "../../../../models/audience-rule.model";

/**
 * @type {{ criteria?: string, operator?: string, value?: string }} 
 */
const initialErrors = {
    criteria: null,
    operator: null,
    value: null,
}

/**
 * @param {{
 *  rule: AudienceRuleTypes.AudienceRuleModel
 *  onUpdateRule: (rule: AudienceRuleTypes.AudienceRuleModel) => void
 * }} param0 
 */
export default function SingleRule({ rule, onUpdateRule }) {
    const { registerValidator, removeValidator } = useContext(RuleValidationContext);
    const [errors, setErrors] = useState(initialErrors);

    useEffect(() => {
        registerValidator(rule, (config) => validate(config));

        return () => {
            removeValidator(rule);
        }
    }, [registerValidator, rule]);

    /**
     * @param {{
     *  silent: boolean
     * }} config
     * @returns 
     */
    function validate({ silent = false }) {
        const { isValid, errors } = validateRule(rule);
        
        if (! silent) {
            setErrors(errors);
        }

        return isValid; 
    }
    
    /**
     * @param {'criteria' | 'operator' | 'value'} field
     * @param {any} value
     */
    function validateOnly(field, value) {
        const result = {
            criteria: validateCriteria,
            operator: validateOperator,
            value: validateValue,
        };

        const { isValid, message } = result[field](value);
        
        setErrors((prev) => ({
            ...prev,
            [field]: isValid ? null : message,
        }));
    }

    return (
        <div className="p-d-flex gap-14">
            <div className="p-d-flex p-flex-column gap-3" style={{ width: '250px' }}>
                <RuleCriteriaSelect
                    value={rule.CRITERIA_ID}
                    filter
                    onChange={(e) => {
                        onUpdateRule({
                            ...rule,
                            CRITERIA_ID: e.value,
                            VALUE: null,
                        });

                        validateOnly('criteria', e.value);
                    }}
                    extra="p-0 m-0"
                />
                <RuleError error={errors.criteria} />
            </div>
            <div className="p-d-flex p-flex-column gap-3" style={{ width: '177px' }}>
                <RuleOperatorSelect
                    rule={rule}
                    value={rule.OPERATOR_ID}
                    onChange={(e) => {
                        onUpdateRule({
                            ...rule,
                            OPERATOR_ID: e.value,
                            VALUE: null,
                        });

                        validateOnly('operator', e.value);
                    }}
                    extra="p-0 m-0"
                />
                <RuleError error={errors.operator} />
            </div>
            <div className="p-d-flex p-flex-column gap-3" style={{ flex: 1 }}>
                <RuleValueInputResolver
                    rule={rule}
                    onValueChange={(value) => {
                        onUpdateRule({
                            ...rule,
                            VALUE: value,
                        });

                        validateOnly('value', value);
                    }}
                    extra="p-0 m-0"
                />
                <RuleError error={errors.value} />
            </div>
        </div>
    )
}