import { auth } from "../config/firebase.config";
import { CONST } from "../consts/consts";
import backendRequestUtil from "../utils/backendRequest.util";

const getStoreList = async (userId, options = { networkStatus: true }) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    // userId
  };

  const { networkStatus } = options
  const url = new URL(`${CONST.API_STORE_ROUTE}/getStoreList`)

  if(networkStatus !== null && networkStatus !== undefined) {
    url.searchParams.set("networkStatus", networkStatus)
  }

  try {
    result = await fetch(
      url.toString(),
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getDeparmentList = async (userId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    // userId
  };

  const url = new URL(`${CONST.API_STORE_ROUTE}/getStoreDeparments`)

  try {
    result = await fetch(
      url.toString(),
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getStoreDetail = async (storeId, userId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    // userId
  };

  try {
    result = await fetch(
      `${CONST.API_STORE_ROUTE}/getStoreDetail/${storeId}`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getStoreConfig = async (storeId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(
      `${CONST.API_STORE_ROUTE}/getStoreConfig/${storeId}`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const putStoreConfig = async (storeId, newChanges) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(newChanges),
  };

  try {
    result = await fetch(
      `${CONST.API_STORE_ROUTE}/putStoreConfig/${storeId}`,
      requestOptions
    );
    if (!result.ok) {
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const postStores = async (stores) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(stores),
  };

  try {
    result = await fetch(
      `${CONST.API_STORE_ROUTE}/postNewStores`,
      requestOptions
    );
    if (!result.ok) {
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

export const getStoreHeatmap = async (storeId, floorId, fromDate = undefined, toDate = undefined) => {
  const params = {storeId, floorId, fromDate, toDate }
  const { success, body, error } = await backendRequestUtil.get("/api/v2/store/heatmap", { params})
  return success ? { success, payload: body } : { success, error }
}

export const getStoreHeatmapDuration = async (storeId, fromDate = undefined, toDate = undefined) => {
  const params = {storeId, fromDate, toDate }
  const { success, body, error } = await backendRequestUtil.get("/api/v2/store/heatmap/duration", { params})
  return success ? { success, payload: body } : { success, error }
}

export const getStoreTrajectory = async (storeId, floor, fromDate = undefined, toDate = undefined) => {
  const params = {storeId, floor, fromDate, toDate }
  const { success, body, error } = await backendRequestUtil.get("/api/v2/store/trajectory", { params})
  return success ? { success, payload: body } : { success, error }
}

export const getStoreFloors = async (storeId) => {
  const params = { storeId }
  const { success, body, error } = await backendRequestUtil.get("/api/v2/store/floors", { params })
  return success ? { success, payload: body } : { success, error }
}

export {
  getStoreList,
  getStoreDetail,
  getStoreConfig,
  putStoreConfig,
  postStores,
  getDeparmentList,
};
