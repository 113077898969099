import BaseMessage from "../../../design/design_components/sisu/messages/BaseMessage.sisu";
import WarningIcon from "../../../design/assets/img/wfi/icons/icon-warning.svg";
import XIcon from "../../../design/assets/img/wfi/icons/icon-close.svg";
import { useRef } from "react";

/**
 *
 * @param {{
 *  error: {
 *      summary?: string,
 *      details?: string,
 *  },
 *  closable: boolean,
 *  onClose: () => void
 * }}
 * @returns
 */
export default function AdFileErrorMessage({ error, closable, onClose }) {
  let displayError = Boolean(error?.summary || error?.details);
  const ref = useRef(null);

  /* if (closeAfter) {
    setTimeout(() => {
      if (displayError) {
        console.log("closing...");
        onClose();
      }
    }, closeAfter);
  } */

  return displayError ? (
    <BaseMessage
      severity="error"
      custom="form-error-alert"
      ref={ref}
      content={
        <>
          <div className="p-d-flex p-ai-center gap-10 p-0 w-full">
            <img src={WarningIcon} alt="warning-icon" />
            <div className="p-d-flex p-flex-column fs-14">
              {error.summary && <span className="bold">{error.summary}</span>}
              {error.details && <span>{error.details}</span>}
            </div>

            {closable && (
              <>
                <div
                  className="text-danger cursor-pointer p-ml-auto p-d-flex p-ai-center"
                  onClick={() => {
                    console.log("closing...");
                    onClose();
                  }}
                >
                  <img src={XIcon} alt="close-icon" />
                </div>
              </>
            )}
          </div>
        </>
      }
    />
  ) : null;
}
