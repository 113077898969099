export const TYPE = {
  VIDEO: {
    ID: 1,
  },
  IMAGE: {
    ID: 2,
  },
  CUSTOM: {
    ID: 3,
  }
};

export const STATUS = Object.freeze({
  ACTIVE: { name: 'Activo' },
  FINISHED: { name: 'Finalizado' },
  PAUSED: { name: 'Pausado' },
  INCOMPLETE: { name: 'Incompleto' },
  SCHEDULED: { name: 'Programado' },
});