import * as AudienceRuleTypes from "../../../../models/audience-rule.model";
import * as PrimeReactTypes from "../../../../models/prime-react.model";
import { useCriteriaOptions } from "views/audiences";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import NeoMultiSelect from "design/design_components/neo/form/NeoMultiSelect.base";
import { groupBy } from "utils/commons.util";

/**
 * @param {{
 *  onValueChange: (event: PrimeReactTypes.OnChangeEvent) => void,
 *  rule: AudienceRuleTypes.AudienceRuleModel,
 *  criteria: AudienceRuleTypes.AudienceRuleCriteriaModel,
 *  multiple?: boolean
 * }} param0 
 */
export default function InputSelect({ onValueChange, rule, criteria, multiple, ...props }) {
    let options = [];
    const groupProps = { optionValue: 'id', optionLabel: 'name' };
    const { options: criteriaOptions } = useCriteriaOptions(criteria);
    const isGrouped = criteriaOptions.length > 0 && criteriaOptions[0].groupField;

    if (isGrouped) {
        const groupedOptions = groupBy(criteriaOptions, option => option.groupField);

        options = Object.entries(groupedOptions).map(([groupField, criteria]) => ({
            groupField: groupField,
            code: groupField,
            items: criteria.map(criterion => ({
                id: criterion.id,
                name: criterion.name,
            }))
        }));

        groupProps['optionGroupLabel'] = 'groupField'; 
        groupProps['optionGroupChildren'] = 'items';
    } else {
        options = criteriaOptions;
    }

    if (! multiple) {
        return (
            <NeoDropdown 
                placeholder="Selecciona un valor"
                emptyMessage="No hay valores disponibles"
                filter
                options={options} 
                onChange={onValueChange}
                value={options.length ? rule.VALUE : null}
                extra="p-0 m-0"
                {...groupProps}
                {...props}
            />
        )
    }

    return (
        <NeoMultiSelect
            placeholder="Selecciona los valores"
            emptyFilterMessage="No hay valores disponibles"
            filter
            display="chip" 
            extra="p-0 m-0"
            options={options} 
            onChange={onValueChange}
            value={options.length ? rule.VALUE : null}
            showSelectAll={options.length > 1}
            {...groupProps}
        />
    );
}