import { useState } from "react";
import { deleteAudience } from "service/Audience.service";
import { CONST } from "../../../consts/consts";
import useToastContext from "hooks/useToastContext.hook";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";

/**
 * @param {{
 *  open: boolean,
 *  onClose: () => void,
 *  audienceId: number,
 *  onDeletedAudience?: () => void,
 * }} param0 
 * @returns 
*/
export default function DeleteAudienceModal({ open, onClose, audienceId, onDeletedAudience = () => { } }) {
    const toast = useToastContext();
    const [isLoading, setLoading] = useState(false);

    async function handleDeleteAudience() {
        setLoading(true);
        
        const response = await deleteAudience(audienceId);

        if (!response.success) {
            setLoading(false);
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
                CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
            );
        }

        onDeletedAudience();
        setLoading(false);

        toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );
    }

    return (
        <NeoDialog
            header="Eliminación de audiencia"
            custom="custom-modal-header"
            visible={open}
            closable={true}
            onHide={onClose}
            resizable={false}
        >
            <div className="p-d-flex p-flex-column gap-17">
                <p className="m-0 fs-14 text-black">
                    Esta acción es irreversible y afectará todos los anuncios que usan esta audiencia. ¿Deseas continuar?
                </p>

                <div className="p-d-flex p-jc-end gap-8">
                    <NeoButtonMain alternative="outlined" onClick={onClose}>
                        Cancelar
                    </NeoButtonMain>
                    <NeoButtonMain onClick={handleDeleteAudience} disabled={isLoading} >
                        Aceptar
                        {isLoading && <i className="pi pi-spin pi-spinner ml-5"></i>}
                    </NeoButtonMain>
                </div>
            </div>
        </NeoDialog>
    )
}