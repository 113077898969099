import { useRef, useState } from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoInnerSubtitle from "../../../design/design_components/neo/title/NeoInnerSubtitle.base";
import NeoInputFile from "../../../design/design_components/neo/form/NeoInputFile.base";
import * as MediaTypes from "../../../models/media.model";
import AdFileErrorMessage from "./AdFileErrorMessage.component";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoTooltip_icon from "../../../design/design_components/neo/overlay/NeoTooltip_icon.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import {
  parseErrors,
  parseFileError,
  validateClickUrl,
  validateDesktopFile,
  validateMobileFile,
} from "../../../validation/media/create-media.validation";

/**
 *
 * @param {{
 *  media: MediaTypes.MediaModel,
 *  errors: Array<Object>,
 *  viewOnly: boolean,
 *  onUpdateFileIos: (file: File?) => void,
 *  onUpdateFileAndroid: (file: File?) => void,
 *  onUpdateFileDesktop: (file: File?) => void,
 *  onUpdateClickUrl: (clickUrl: string) => void,
 * }}
 * @returns
 */
export default function AdContentManager({
  media,
  errors = {},
  viewOnly = false,
  onUpdateErrors = () => {},
  onUpdateFileIos = () => {},
  onUpdateFileAndroid = () => {},
  onUpdateFileDesktop = () => {},
  onUpdateClickUrl = () => {},
}) {
  const androidImageRef = useRef();
  const iosImageRef = useRef();
  const desktopImageRef = useRef();

  const [androidImageLoading, setAndroidImageLoading] = useState(false);
  const [iosImageLoading, setIosImageLoading] = useState(false);
  const [desktopImageLoading, setDesktopImageLoading] = useState(false);
  const [selectedMediaMobileDevice, setSelectedMediaMobileDevice] =
    useState("ios");

  const iosImageUploaded = media.fileIos || media.fileIosUrl;
  const androidImageUploaded =
    media.fileAndroid || media.fileAndroidUrl || media.fileIosUrl;
  const desktopImageUploaded = media.fileDesktop || media.fileDesktopUrl;

  if (media?.fileIosUrl && !media.fileIos && iosImageRef.current) {
    iosImageRef.current.src = media.fileIosUrl;
  }

  /**
   * If no android url is provided, but an ios url is provided, then use the ios url as the android url
   * only to the preview image, not to the actual file. That way we can let the user know that the image
   * is the same for both devices but they can upload a different one if they want to.
   */
  if (
    (media?.fileAndroidUrl || media?.fileIosUrl) &&
    !media.fileAndroid &&
    androidImageRef.current
  ) {
    androidImageRef.current.src = media.fileAndroidUrl ?? media.fileIosUrl;
  }

  if (media?.fileDesktopUrl && !media.fileDesktop && desktopImageRef.current) {
    desktopImageRef.current.src = media.fileDesktopUrl;
  }

  const resolveImageEmptyTemplate = (
    file,
    fileImageRef,
    fileUrl = "",
    fileImageLoading = false
  ) => {
    return file || fileUrl ? (
      <div
        className="banner-image-preview-container"
        style={{ zIndex: fileImageLoading ? "99" : "auto" }}
      >
        <span>
          {fileImageLoading ? <i className="pi pi-spin pi-spinner"></i> : null}
        </span>
        <img ref={fileImageRef} alt="" />
      </div>
    ) : (
      <p>O arrástralo aquí</p>
    );
  };

  const handleIosImageUpload = (event) => {
    const file = event.files[0];

    if (file) {
      setIosImageLoading(true);

      validateMobileFile(file)
        .then(async (error) => {
          if (error.length) {
            const newError = parseFileError(error);
            console.log(newError);

            setIosImageLoading(false);

            onUpdateErrors({
              ...errors,
              fileIos: newError,
            });

            return;
          }

          onUpdateErrors({
            ...errors,
            fileIos: undefined,
          });

          try {
            onUpdateFileIos(file);
            await updatePreview(file, iosImageRef);
          } catch (error) {
            console.log(error, "error on update preview");
          } finally {
            setIosImageLoading(false);
          }
        })
        .catch((error) => {
          setIosImageLoading(false);
          console.log(error, "error on validate file");
        });
    }

    event.options.clear();
  };

  const handleAndroidImageUpload = (event) => {
    const file = event.files[0];

    if (file) {
      setAndroidImageLoading(true);

      validateMobileFile(file)
        .then(async (error) => {
          if (error.length) {
            const newError = parseFileError(error);
            console.log(newError);

            setAndroidImageLoading(false);

            onUpdateErrors({
              ...errors,
              fileAndroid: newError,
            });

            return;
          }

          onUpdateErrors({
            ...errors,
            fileAndroid: undefined,
          });

          try {
            onUpdateFileAndroid(file);
            await updatePreview(file, androidImageRef);
          } catch (error) {
            console.log(error, "error on uupdate preview");
          } finally {
            setAndroidImageLoading(false);
          }
        })
        .catch((error) => {
          setAndroidImageLoading(false);
          console.log(error, "error on validate file");
        });
    }

    event.options.clear();
  };

  const handleDesktopImageUpload = (event) => {
    const file = event.files[0];

    if (file) {
      setDesktopImageLoading(true);

      validateDesktopFile(file)
        .then(async (error) => {
          if (error.length) {
            const newError = parseFileError(error);
            console.log(newError);

            setDesktopImageLoading(false);

            onUpdateErrors({
              ...errors,
              fileDesktop: newError,
            });

            return;
          }

          onUpdateErrors({
            ...errors,
            fileDesktop: undefined,
          });

          try {
            onUpdateFileDesktop(file);
            await updatePreview(file, desktopImageRef);
          } catch (error) {
            console.log(error, "error on uupdate preview");
          } finally {
            setDesktopImageLoading(false);
          }
        })
        .catch((error) => {
          setDesktopImageLoading(false);
          console.log(error, "error on validate file");
        });
    }

    event.options.clear();
  };

  async function updatePreview(file, ref) {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function () {
          ref.current.src = reader.result;
          resolve();
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleClickUrlBlur = (event) => {
    const error = parseErrors({
      clickUrl: validateClickUrl(event.target.value),
    });

    onUpdateErrors({
      ...errors,
      clickUrl: error.clickUrl,
    });
  };

  return (
    <div className={viewOnly ? "view-only-mode w-full" : "w-full"}>
      <div className="ad-content-manager pb-0 p-d-flex p-flex-column p-flex-lg-row">
        <div>
          <NeoInnerSubtitle
            extra={"p-p-0 p-m-0 text-black " + (viewOnly ? "fs-16" : "")}
          >
            Dispositivos móviles
          </NeoInnerSubtitle>
          <div
            className={
              "screen-viewer mobile-screen banner-container banner-container-mobile " +
              (selectedMediaMobileDevice !== "ios" ? "hide" : "")
            }
          >
            <h3 className="text-primary fs-16 bold p-m-0">LOGO</h3>
            <div className="p-d-flex p-flex-column banner-card banner-card-mobile">
              <div>
                <p className="banner-welcome-text">
                  Hola{" "}
                  <span className="banner-template-text">{"{Nombre}"}</span>
                </p>
                <p className="banner-online-text">Ya estás en línea</p>
              </div>

              <NeoInputFile
                disabled={viewOnly}
                extra={
                  "banner-input-file mobile" +
                  (iosImageUploaded ? " file-uploaded" : "") +
                  (viewOnly ? "" : " mask-on-hover")
                }
                auto
                customUpload
                accept=".png,.jpg,.jpeg,.gif"
                chooseLabel={
                  iosImageUploaded ? "Cambiar imagen" : "Cargar archivo"
                }
                emptyTemplate={resolveImageEmptyTemplate(
                  media.fileIos,
                  iosImageRef,
                  media.fileIosUrl,
                  iosImageLoading
                )}
                uploadHandler={handleIosImageUpload}
              />
            </div>

            <div
              className={
                "mobile-banner-error-container " +
                (selectedMediaMobileDevice !== "ios" || viewOnly
                  ? "p-d-none"
                  : "")
              }
            >
              <AdFileErrorMessage
                error={errors.fileIos}
                closable
                onClose={() =>
                  onUpdateErrors({ ...errors, fileIos: undefined })
                }
              />
            </div>
          </div>

          <div
            className={
              "screen-viewer mobile-screen banner-container banner-container-mobile " +
              (selectedMediaMobileDevice !== "android" ? "hide" : "")
            }
          >
            <h3 className="text-primary fs-16 bold p-m-0">LOGO</h3>
            <div className="p-d-flex p-flex-column banner-card banner-card-mobile">
              <div>
                <p className="banner-welcome-text">
                  Hola{" "}
                  <span className="banner-template-text">{"{Nombre}"}</span>
                </p>
                <p className="banner-online-text">Ya estás en línea</p>
              </div>

              <NeoInputFile
                disabled={viewOnly}
                extra={
                  "banner-input-file mobile" +
                  (androidImageUploaded ? " file-uploaded" : "") +
                  (viewOnly ? "" : " mask-on-hover")
                }
                auto
                customUpload
                accept=".png,.jpg,.jpeg,.gif"
                chooseLabel={
                  androidImageUploaded ? "Cambiar imagen" : "Cargar archivo"
                }
                emptyTemplate={resolveImageEmptyTemplate(
                  media.fileAndroid,
                  androidImageRef,
                  media.fileAndroidUrl
                    ? media.fileAndroidUrl
                    : media.fileIosUrl
                    ? media.fileIosUrl
                    : "",
                  androidImageLoading
                )}
                uploadHandler={handleAndroidImageUpload}
              />
            </div>

            <div
              className={
                "mobile-banner-error-container " +
                (selectedMediaMobileDevice !== "android" || viewOnly
                  ? "p-d-none"
                  : "")
              }
            >
              <AdFileErrorMessage
                error={errors.fileAndroid}
                closable
                onClose={() =>
                  onUpdateErrors({ ...errors, fileAndroid: undefined })
                }
              />
            </div>
          </div>

          <span
            className={
              "p-buttonset p-jc-center " +
              (viewOnly && !media.fileAndroidUrl ? "p-d-none" : "p-d-flex")
            }
          >
            <NeoButtonMain
              label="iOS"
              onClick={() => setSelectedMediaMobileDevice("ios")}
              extra="p-button-primary android-ios-switch-button"
              alternative={
                selectedMediaMobileDevice !== "ios" ? "outlined" : undefined
              }
            />
            <NeoButtonMain
              label="Android"
              onClick={() => setSelectedMediaMobileDevice("android")}
              extra="android-ios-switch-button"
              alternative={
                selectedMediaMobileDevice !== "android" ? "outlined" : undefined
              }
            />
          </span>
        </div>

        <div className="vertical-divider"></div>

        <div>
          <NeoInnerSubtitle
            extra={"p-p-0 p-m-0 text-black " + (viewOnly ? "fs-16" : "")}
          >
            Computadoras de escritorio
          </NeoInnerSubtitle>
          <div className="screen-viewer desktop-screen banner-container banner-container-desktop">
            <h3 className="text-primary fs-16 bold p-m-0 banner-logo">LOGO</h3>
            <div className="p-d-flex p-flex-column banner-card banner-card-desktop">
              <div>
                <p className="banner-welcome-text">
                  Hola{" "}
                  <span className="banner-template-text">{"{Nombre}"}</span>
                </p>
                <p className="banner-online-text">Ya estás en línea</p>
              </div>

              <NeoInputFile
                disabled={viewOnly}
                extra={
                  "banner-input-file desktop" +
                  (desktopImageUploaded ? " file-uploaded" : "") +
                  (viewOnly ? "" : " mask-on-hover")
                }
                auto
                customUpload
                accept=".png,.jpg,.jpeg,.gif"
                chooseLabel={
                  desktopImageUploaded ? "Cambiar imagen" : "Cargar archivo"
                }
                emptyTemplate={resolveImageEmptyTemplate(
                  media.fileDesktop,
                  desktopImageRef,
                  media.fileDesktopUrl,
                  desktopImageLoading
                )}
                uploadHandler={handleDesktopImageUpload}
              />
            </div>

            <div
              className={
                "desktop-banner-error-container " + (viewOnly ? "p-d-none" : "")
              }
            >
              <AdFileErrorMessage
                error={errors.fileDesktop}
                closable
                onClose={() =>
                  onUpdateErrors({ ...errors, fileDesktop: undefined })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="divider mt-16 mb-16"></div>

      <NeoColumn
        col="12"
        extra={
          "p-d-flex p-flex-column pl-0 pr-0 pb-0 " +
          (viewOnly && media.fileAndroidUrl ? "pt-16" : "pt-0")
        }
      >
        <div className="p-d-flex p-ai-center">
          <label className="input-label p-mr-1">Enlace del anuncio</label>
          <NeoTooltip_icon
            text="Destino al que se dirigirá el cliente al hacer clic en el anuncio (no aplica para Android)."
            extra="inline-tooltip"
            position="bottom"
          />
        </div>
        <NeoInputText
          extra="p-p-0"
          value={media.clickUrl}
          placeholder="http://www.ejemplo.com/"
          onChange={(event) =>
            onUpdateClickUrl({ clickUrl: event.target.value })
          }
          onBlur={handleClickUrlBlur}
          maxLength={255}
          error={errors.clickUrl}
          disabled={viewOnly}
        />
      </NeoColumn>
    </div>
  );
}
