import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import { useState } from "react";
import { updateAudienceStatus } from "service/Audience.service";
import useToastContext from "hooks/useToastContext.hook";
import { CONST } from "../../../consts/consts";

/**
 * @param {{
 *  audienceId: number,
 *  isEnabled: boolean,
 *  onStatusUpdated?: (isEnabled: boolean) => void,
 * }} param0 
 * @returns 
 */
export default function DisableAudienceButton({ audienceId, isEnabled, onStatusUpdated = () => {} }) {
    const toast = useToastContext();
    const [isLoading, setLoading] = useState(false);

    async function handleDisableAudience() {
        setLoading(true);

        const newStatus = !isEnabled;
        const response = await updateAudienceStatus(audienceId, newStatus);

        if (!response.success) {
            setLoading(false);
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.FAIL_TO_UPDATE.HEADLINE,
                CONST.TOAST_MESSAGES.FAIL_TO_UPDATE.DETAILS
            );
        }

        onStatusUpdated(newStatus);
        setLoading(false);

        toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );
    }

    return (
        <NeoButtonMain
            onClick={handleDisableAudience}
            alternative="outlined"
            disabled={isLoading}
            extra="action-button"
        >
            {isEnabled ? 'Deshabilitar' : 'Habilitar'}
            {isLoading && <i className="pi pi-spin pi-spinner ml-5"></i>}
        </NeoButtonMain>
    );
}