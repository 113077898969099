import { useState } from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";

export default function UpdateAdButton({ showSpinner, onUpdateAd }) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <NeoButtonMain
        extra="action-button"
        onClick={() => setOpenConfirmDialog(true)}
        disabled={showSpinner}
      >
        {showSpinner && <i className="pi pi-spin pi-spinner mr-5"></i>}
        Guardar cambios
      </NeoButtonMain>

      <NeoDialog
        header="Guardar cambios"
        custom="custom-modal-header ad-content-help-modal"
        visible={openConfirmDialog}
        closable={true}
        dismissableMask={true}
        onHide={() => setOpenConfirmDialog(false)}
        resizable={false}
      >
        <p className="text-black mt-0 mb-17 fs-14">
          ¿Deseas continuar? esto actualizará las configuraciones actuales.
        </p>
        <div className="p-d-flex p-jc-end gap-8">
          <NeoButtonMain
            alternative="outlined"
            onClick={() => setOpenConfirmDialog(false)}
          >
            Cancelar
          </NeoButtonMain>
          <NeoButtonMain
            onClick={() => {
              setOpenConfirmDialog(false);
              onUpdateAd();
            }}
          >
            Aceptar
          </NeoButtonMain>
        </div>
      </NeoDialog>
    </>
  );
}
