const { createContext } = require("react");
const AudienceRuleTypes = require("../../../models/audience-rule.model");

const context = {
    /** @type {AudienceRuleTypes.AudienceRuleModel[]} */
    rules: [],
    /**
     * @param {AudienceRuleTypes.AudienceRuleType} type
     * @returns {AudienceRuleTypes.AudienceRuleModel}
     */
    createRule: (type) => undefined,
    /** 
     * @param {AudienceRuleTypes.AudienceRuleModel} rule
     */
    addRule: (rule) => {}, 
    /** 
     * @param {AudienceRuleTypes.AudienceRuleModel} rule
     */
    removeRule: (rule) => {}, 
    /** 
     * @param {AudienceRuleTypes.AudienceRuleModel} oldRule
     * @param {AudienceRuleTypes.AudienceRuleModel} newRule
     */
    updateRule: (oldRule, newRule) => {},
}

const RulesContext = createContext(context);

/**
 * @typedef {typeof context} RulesContextType
 */
export { RulesContext };
