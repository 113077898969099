import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import MediaTable from "./components/MediaTable.component";
import Icono from "../../design/assets/img/wfi/icons/icon-ad.svg";

export default function MediaList() {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title="Anuncios" col="12" icon={Icono} />
      </NeoGridContainer>

      <MediaTable />
    </>
  );
}
