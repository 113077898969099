import * as MediaTypes from "../../models/media.model";
import { CONST } from "../../consts/consts";
import Routes from "../../routers/Routes.router.json";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";
import Icono from "../../design/assets/img/wfi/icons/icon-ad.svg";
import PenIcono from "../../design/assets/img/wfi/icons/icon-pen.svg";
import PauseAdButton from "./components/PauseAdButton.component";
import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import useToastContext from "../../hooks/useToastContext.hook";
import { getStatistcMedia } from "../../service/Media.service";
import NeoTooltip_icon from "../../design/design_components/neo/overlay/NeoTooltip_icon.base";
import NeoChart from "../../design/design_components/neo/chart/NeoChart.base";
import AdContentManager from "./components/AdContentManager.component";
import AdStoresTable from "./components/AdStoresTable.component";
import { calculateAdPerformance } from "utils/ad.utils";

/** @type {MediaTypes.MediaDetailModel} */
const adDefaults = {};

export default function AdDetails() {
  const toast = useToastContext();
  const history = useHistory();
  const { adId } = useParams();
  const [loading, setLoading] = useState(false);

  const [ad, setAd] = useState(adDefaults);

  useEffect(() => {
    let mount = true;
    if (mount) {
      setLoading(true);
      getStatistics();
    }

    return () => {
      mount = false;
    };
  }, []);

  const getStatistics = async () => {
    setLoading(true);
    const response = await getStatistcMedia(adId);
    const ad = response.result[0];

    updateAd({
      id: ad.ID,
      name: ad.NAME,
      status: ad.STATUS,
      isDefault: ad.IS_DEFAULT,
      startDate: ad.START_DATE,
      endDate: ad.END_DATE,
      clickUrl: ad.CLICK_URL,
      fileDesktopUrl: ad.URL,
      fileAndroidUrl: ad.URL_ANDROID,
      fileIosUrl: ad.URL_IOS,
      fileType: ad.FILE_TYPE_ID,
      stores: ad.ALL_TIME_STORES,
      assignedStores: ad.CURRENT_STORES,
      impressions: ad.MEDIA_TRACKER_RENDERED ? ad.MEDIA_TRACKER_RENDERED : 0,
      clics: ad.MEDIA_TRACKER_CLICKS ? ad.MEDIA_TRACKER_CLICKS : 0,
      osImpressionDistribution: ad.OS_IMPRESSION_DISTRIBUTION
        ? parseOsImpressionDistribution(ad.OS_IMPRESSION_DISTRIBUTION)
        : [],
    });

    setLoading(false);
  };

  /**
   * Updates the ad object with the new properties.
   * Just a shorthand for the setAd function.
   * @param {Partial<MediaTypes.MediaDetailModel>} newAdProperties
   */
  const updateAd = async (newAdProperties) => {
    setAd({ ...ad, ...newAdProperties });
  };

  /**
   * @param {{ status: string}} param0
   */
  const handleUpdatedAd = async ({ status }) => {
    updateAd({ status });

    toast.setMessage(
      CONST.SEVERITY.SUCCESS,
      CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
      CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
    );
  };

  const handleErrorUpdateAd = () => {
    toast.setMessage(
      CONST.SEVERITY.ERROR,
      CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
      CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
    );
  };

  const onEditButtonClick = () => {
    const url = Routes.media.modifyMedia.replace(":mediaId", ad.id);
    history.push(url);
  };

  const parseDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : "N/A";
  };

  /**
   * @param {{ osName: string, impressions: number }[]} osImpressionDistribution
   * @returns
   */
  const parseOsImpressionDistribution = (osImpressionDistribution) => {
    const colors = [
      { os: "ios", color: "#ea4335" },
      { os: "android", color: "#2196f3" },
      { os: "windows", color: "#246209" },
      { os: "mac os", color: "#194893" },
      { os: "linux", color: "#f0f0f0" },
    ];

    let defaultColors = [
      "#3B82F6",
      "#22C55E",
      "#EAB308",
      "#06B6D4",
      "#FF3D32",
      "#EC4899",
      "#6366F1",
      "#14B8A6",
      "#F97316",
      "#64748B",
      "#A855F7",
    ];

    const getColoyByOsName = (osName) =>
      colors.find((color) => color.os === osName?.toLowerCase())?.color;

    const getRandomColor = () => defaultColors.pop();

    return osImpressionDistribution.map((os) => ({
      ...os,
      color: getColoyByOsName(os.osName) ?? getRandomColor(),
    }));
  };

  const resolveOsImpressionDistribution = () => {
    if (ad.osImpressionDistribution.length === 0) {
      return (
        <div className="empty ad-os-impression-distribution-container">
          <span className="text-black">Sin resultados</span>
        </div>
      );
    }

    return (
      <div className="p-d-flex p-ai-center gap-21 ad-os-impression-distribution-container">
        <NeoChart
          extra="ad-os-impression-distribution-chart"
          data={ad.osImpressionDistribution}
          formatNumber={true}
          dataKey="impressions"
          dataName="osName"
          colors={ad.osImpressionDistribution.map((os) => os.color)}
          hideLabel={true}
        />
        <div className="ad-os-impression-distribution-list w-1/2">
          {ad.osImpressionDistribution.map((os, index) => (
            <span key={os.osName} className={"os-item color-" + os.color}>
              {os.osName}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          col="8"
          title="Detalle del anuncio"
          subtitle={ad.name}
          icon={Icono}
          goBack={true}
        />

        <NeoColumn col="4" extra="p-d-flex p-jc-end p-ai-center gap-4">
          {!ad.isDefault && (
            <PauseAdButton
              adId={ad.id}
              currentStatus={ad.status}
              onUpdateStatusError={handleErrorUpdateAd}
              onUpdateStatusSuccess={handleUpdatedAd}
            />
          )}
          {/* <NeoButtonMain onClick={onEditButtonClick}>
            <img src={PenIcono} className="mr-5" alt="Editar anuncio" />
            Editar anuncio
          </NeoButtonMain> */}
        </NeoColumn>
      </NeoGridContainer>

      <div className="p-d-flex gap-16 w-full">
        <div className="p-d-flex p-flex-column gap-16">
          <NeoCard custom="custom-padding-card ad-general-info-details-card m-0">
            <div className="p-col p-d-flex p-flex-column gap-24 pb-0">
              <h2 className="fs-22 bold text-primary p-m-0 p-p-0">
                Información general
              </h2>

              <div className="p-d-flex p-flex-column gap-14">
                <div className="p-d-flex p-flex-column gap-4">
                  <span className="text-black fs-14">Nombre</span>
                  <span className="p-d-inline-flex p-ai-center gap-4 text-black fs-20 bold">
                    {ad.name}
                    {Boolean(ad.isDefault) && (
                      <>
                        <span className="badge badge-info fs-11 bold">
                          Default
                        </span>
                      </>
                    )}
                  </span>
                </div>
                <div className="p-d-flex p-flex-column gap-4">
                  <span className="p-d-inline-flex p-ai-center gap-4 text-black fs-14">
                    Periodo de vigencia
                    {Boolean(!ad.isDefault) && (
                      <NeoTooltip_icon
                        text="La hora mostrada corresponde a un día completo."
                        extra="inline-tooltip"
                        position="bottom"
                      />
                    )}
                  </span>
                  <span className="p-d-inline-flex p-ai-center gap-4 text-black fs-20 bold">
                    {ad.isDefault
                      ? "N/A"
                      : `${parseDate(ad.startDate)} - ${parseDate(ad.endDate)}`}
                  </span>
                </div>
              </div>
            </div>
          </NeoCard>
          <NeoCard custom="custom-padding-card ad-results-details-card">
            <div className="p-col p-d-flex p-flex-column gap-32 pb-0">
              <h2 className="fs-22 bold text-primary p-m-0 p-p-0">
                Resultados
              </h2>

              <div className="p-d-flex p-jc-between gap-14">
                <div className="p-d-flex p-flex-column gap-4">
                  <span className="text-black fs-14">Impresiones</span>
                  <span className="p-d-inline-flex p-ai-center gap-4 text-black fs-20 bold">
                    {ad.impressions}
                  </span>
                </div>
                <div className="p-d-flex p-flex-column gap-4">
                  <div className="p-d-flex gap-4 p-ai-center">
                    <span className="text-black fs-14">Clics totales</span>
                    <NeoTooltip_icon
                      text="Detección de clics no disponible en dispositivos Android."
                      extra="inline-tooltip"
                      position="bottom"
                    />
                  </div>
                  <span className="p-d-inline-flex p-ai-center gap-4 text-black fs-20 bold">
                    {ad.clics}
                  </span>
                </div>
                <div className="p-d-flex p-flex-column gap-4">
                  <div className="p-d-flex gap-4 p-ai-center">
                    <span className="text-black fs-14">Rendimiento</span>
                    <NeoTooltip_icon
                      text="El % de rendimiento (o CTR) es la proporción de clics por impresiones en un anuncio."
                      extra="inline-tooltip"
                      position="bottom"
                    />
                  </div>
                  <span className="p-d-inline-flex p-ai-center gap-4 text-black fs-20 bold">
                    {calculateAdPerformance(
                      Number(ad.impressions),
                      Number(ad.clics)
                    )}
                    %
                  </span>
                </div>
              </div>

              <div className="p-d-flex p-flex-column gap-7 ">
                <h2 className="p-col fs-22 bold text-primary p-m-0 p-p-0">
                  Impresiones por dispositivo
                </h2>

                {ad.osImpressionDistribution &&
                  resolveOsImpressionDistribution()}
              </div>
            </div>
          </NeoCard>
        </div>

        <NeoCard custom="custom-padding-card ad-content-viewer-card m-0">
          <div className="p-col p-d-flex p-flex-column gap-16 pb-0">
            <h2 className="p-d-inline-flex p-ai-center gap-4 fs-22 bold text-primary p-m-0 p-p-0">
              Contenido multimedia
              <NeoTooltip_icon
                text="Versiones del anuncio adaptadas a distintas vistas. Esto puede variar dependiendo del tamaño de la pantalla."
                extra="inline-tooltip"
                position="bottom"
              />
            </h2>

            <AdContentManager media={ad} viewOnly={true} />
          </div>
        </NeoCard>
      </div>

      <NeoCard custom="custom-padding-card ad-content-viewer-card m-0 mt-16">
        <div className="p-col p-d-flex p-flex-column gap-32 pb-0">
          <h2 className="p-d-inline-flex p-ai-center gap-4 fs-22 bold text-primary p-m-0 p-p-0">
            {ad.isDefault
              ? "Resultados por sucursales"
              : "Resultados de sucursales"}
          </h2>

          <AdStoresTable
            adStores={ad.stores}
            adAssignedStores={ad.assignedStores}
            loading={loading}
            groupByAssigned={!ad.isDefault}
          />
        </div>
      </NeoCard>

      <br />
      <br />
    </>
  );
}
