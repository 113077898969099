export const CONST = {
  RESPONSE_BASIC_STRUCTURE: { success: false, message: "Hubo un error" },

  MESSAGES: {
    SUCCESS: "Petición exitosa",
    ERROR: "Hubo un error en la petición",
  },

  TOAST_MESSAGES: {
    DATA_DOES_NOT_LOAD: {
      HEADLINE: "Ups...",
      DETAILS: "Hubo un problema al cargar los datos.",
    },
    FAIL_TO_UPLOAD: {
      HEADLINE: "Ups... Hubo un error",
      DETAILS: "No se pudo actualizar tus datos.",
    },
    SUCCESS_TO_UPDATE: {
      HEADLINE: "¡Acción éxitosa!",
      DETAILS: "Se han actualizado tus datos correctamente.",
    },
    FAIL_TO_CREATE: {
      HEADLINE: "Ups... Hubo un error",
      DETAILS: "No se pudo crear el registro.",
    },
    FAIL_TO_DOWNLOAD: {
      HEADLINE: "Ups... Hubo un error",
      DETAILS: "No se pudo descargar el archivo.",
    }
  },

  SEVERITY: {
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info",
    WARN: "warn",
  },

  NO_VALUE: "Sin Registro",

  EMAIL_VALIDATIONS: {
    valid: {
      value: "válido",
      label: "Válido",
      description: "Este correo está validado",
    },
    risky: {
      value: "riesgoso",
      label: "Riesgoso",
      description: "Este correo no está validado del todo",
    },
    business: {
      value: "empresarial",
      label: "Empresarial",
      description: "Este correo es de una empresa",
    },
    invalid: {
      value: "inválido",
      label: "Inválido",
      description: "Este es un correo invalido",
    },
    unknown: {
      value: "desconocido",
      label: "Desconocido",
      description: "Se desconoce si es un correo válido",
    },
    noValid: {
      value: "no validado",
      label: "No Validado",
      description: "Este correo aún no se ha validado",
    },
  },

  NETWORK_STATUS: {
    danger: { value: true, label: "Atención requerida" },
    ok: { value: false, label: "En línea" },
  },

  COMBO_DEALS: [
    { apCount: 1, validationCount: 1000, emailCount: 1000, pricePerAp: 800 },
    { apCount: 3, validationCount: 3000, emailCount: 3000, pricePerAp: 700 },
    { apCount: 5, validationCount: 5000, emailCount: 5000, pricePerAp: 600 },
  ],

  CONTACT_STATUS: {
    ACTIVE: { label: "Normal", ID: 1 },
    BLOCK: { label: "Bloqueado", ID: 2 },
  },

  API_DEALS_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/deal-api`,
  API_CONTACTS_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/contact-api`,
  API_STORE_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/store-api`,
  API_SPLASH_PAGES_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/splashPages-api`,
  API_SETTINGS_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/settings-api`,
  API_DASHBOARD_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/dashboard-api`,
  API_USER_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/user-api`,
  API_MAILING_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/email-api`,
  API_REVIEW_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/review-api`,
  API_MEDIA_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/media-api`,
  
  API: {
    AUDIENCE: {
      ALL: `/api/v2/audience/all`,
      GET_CONTACT_RULE_CRITERIA: `/api/v2/audience/contact-rule-criteria`,
      GET_CONTACT_RULE_OPERATORS: `/api/v2/audience/contact-rule-operators`,
      GET_CONTACT_RULE_DATA_OPTIONS: `/api/v2/audience/get-data-options`,
      CREATE_NEW_AUDIENCE: `/api/v2/audience/create-new-audience`,
      UPDATE_AUDIENCE: `/api/v2/audience/update-audience`,
      GET_AUDIENCE: `/api/v2/audience/get-audience`,
      UPDATE_AUDIENCE_STATUS: `/api/v2/audience/update-audience-status`,
      DELETE_AUDIENCE: `/api/v2/audience/delete-audience`,
      TEST_AUDIENCE: `/api/v2/audience/test-audience`,
      GET_AUDIENCE_CONTACTS: `/api/v2/audience/get-audience-contact-counter`,
      DOWNLOAD_CONTACTS: `/api/v2/audience/download-contacts`,
    }
  },
  API_LOOKER_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/looker-api`,
  API_CAMPAIGN_ROUTE: `${process.env.REACT_APP_API_BASE_URL}/campaign-api`,

  TEST_CONSTS: {
    URL_LOGIN_TEST: "https://sistema.neoadmin.sisu-tech.com/login",
    URL_LOGOUT_TEST: "https://sistema.neoadmin.sisu-tech.com/logout",
    PROJECT_NAME: "NeoWifi",
  },
  STAGING_CONSTS: {
    URL_LOGIN_STAGING: "https://sandbox.neoadmin.neored.com/login",
    URL_LOGOUT_STAGING: "https://sandbox.neoadmin.neored.com/logout",
    PROJECT_NAME: "NeoWifi",
  },
  PROD_CONSTS: {
    URL_LOGIN_PROD: "https://neoadmin.neored.com/login",
    URL_LOGOUT_PROD: "https://neoadmin.neored.com/logout",
    PROJECT_NAME: "NeoWifi",
  },
};
