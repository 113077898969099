import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import Icono from "../../design/assets/img/wfi/icons/icon-ad.svg";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import * as AudienceRuleTypes from "../../models/audience-rule.model";
import useToastContext from "hooks/useToastContext.hook";
import { CONST } from "../../consts/consts";
import { createNewAudience } from "service/Audience.service";
import ManageAudience from "./components/ManageAudience.component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function CreateAudience() {
    const audience = { NAME: '', DESCRIPTION: '', RULES: [] };
    const toast = useToastContext();
    const history = useHistory();

    /**
     * @param {AudienceRuleTypes.CreateAudienceModel} audience
     * @returns {Promise<void>}
     */
    async function handleCreateAudience(audience) {
        const response = await createNewAudience(audience);

        if (!response.success) {
            return toast.setMessage(
                CONST.SEVERITY.ERROR,
                CONST.TOAST_MESSAGES.FAIL_TO_CREATE.HEADLINE,
                CONST.TOAST_MESSAGES.FAIL_TO_CREATE.DETAILS
            );
        }

        toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );

        history.push('/audiencias');
    }

    return (
        <>
            <NeoGridContainer>
                <NeoTitleSecond title="Creación de audiencia" col="12" icon={Icono} goBack={true} />
            </NeoGridContainer>

            <ManageAudience
                audienceInfo={audience}
                onSaveAudience={handleCreateAudience}
                saveButtonText="Crear audiencia"
            />
        </>
    );
}