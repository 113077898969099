import React, { useState } from "react";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
//import NewMediaButton from "./components/NewMediaButton.component";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
//import MediaTable from "./components/MediaTable.component";
import Icono from "../../design/assets/img/wfi/icons/icon-multimedia.png";
import CampaniaTable from "./components/CampaniaTable.component";
import { useHistory } from 'react-router-dom';
import './style.scss';


export default function CampaniaList() {
  const [mediaCollection,setMediaCollection] = useState([]); 
  const history = useHistory();

  const handleCreateCampaign = () => {
    history.push('/campanias/nueva-campania');
  };

  return (
    <>
      <NeoGridContainer>
      <div className="custom-title-wrapper">
        <NeoTitleMain title="Campañas" col="12" icon={Icono} />
      </div>
        {mediaCollection.length > 0 && (
          <NeoButtonSection
            align="right"
            col="12"
            md="6"
            extra="p-col-align-center p-flex-sm-row p-flex-column"
          >
            <NeoButtonMain
              label="Crear campaña"
              icon="pi pi-plus"
              onClick={handleCreateCampaign}
            />
          </NeoButtonSection>
        )}
      
      </NeoGridContainer>
      <CampaniaTable  mediaCollection={mediaCollection} 
        setMediaCollection={setMediaCollection}  />
    </>
  );
}
