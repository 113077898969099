import { useState } from "react";
import { updateAdStatus } from "../../../service/Media.service";
import { updateCampaignStatus } from "../../../service/Campaign.service";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";

/**
 *
 * @param {{
 *  adId: number,
 *  currentStatus: string,
 *  onUpdateStatusError: () => void,
 *  onUpdateStatusSuccess: (status: { status: string }) => void
 * }} param0
 * @returns
 */
export default function PauseCampaignButton({
  campaignId,
  currentStatus,
  onUpdateStatusError,
  onUpdateStatusSuccess,
}) {
  const [isPausingCampaign, setIsPausingCampaign] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const handlePauseCampaign = async () => {
    const status = currentStatus === 2 ? 1 : 2;
    setIsPausingCampaign(true);
    const response = await updateCampaignStatus(campaignId, status);

    if (!response.success) {
      onUpdateStatusError();
      setDialogVisible(false);
    } else {
      onUpdateStatusSuccess({ status });
      setDialogVisible(false);
    }

    setIsPausingCampaign(false);
  };

  const handleConfirm = async () => {
    setDialogVisible(true);
  };

  const handleCancel = async () => {
    setDialogVisible(false);
  };

  return (

    /*<NeoButtonMain
              label="Pausar campaña"
              icon="pi pi-pause"
              alternative="outlined"
    />*/
    <>
    <NeoButtonMain
      alternative="outlined"
      extra="action-button"
      label={currentStatus === 2 ? "Activar" : "Pausar"}
      icon={currentStatus === 2 ? "pi pi-play custom-play-icon" : "pi pi-pause custom-pause-icon"}
      onClick={handleConfirm}
    >
    {isPausingCampaign ? <i className="pi pi-spin pi-spinner mr-5"></i> : ""}
        </NeoButtonMain>

        <NeoDialog
         header={currentStatus === 2 ? "Activar campaña" : "Pausar campaña"}
        custom="custom-modal-header"
        visible={dialogVisible}
        closable={false}
        dismissableMask={true}
        onHide={handleCancel} // Cierra el diálogo al hacer clic fuera de él
        resizable={false}
        >
        <div className="p-d-flex p-flex-column gap-17">
          <p className="m-0 fs-14 text-black">
          {currentStatus === 2
              ? "Si reactivas esta campaña, se volverán a mostrar sus anuncios a tus clientes. ¿Estás seguro de que deseas reactivarla?"
              : "Si realizas esta acción, la campaña y sus anuncios dejarán de mostrarse. ¿Deseas pausarla de todos modos?"}
         
          </p>

          

          <div className="p-d-flex p-jc-end gap-8">
            <NeoButtonMain alternative="outlined" onClick={handleCancel}>
              Cancelar
            </NeoButtonMain>
            <NeoButtonMain onClick={handlePauseCampaign}>
              Continuar
            </NeoButtonMain>
          </div>
        </div>
        </NeoDialog>
  
</>     
      
  );
}
